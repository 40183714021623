import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { setReminder, setUserOnboarding } from "actions";
import isEmpty from "helpers/isEmpty";
import {
  AddLogo,
  RemindLater,
  FlexBetween,
  Flex,
  Triangle,
  Text,
  InfoContainer,
  Container,
} from "./styled";

const mapStateToProps = (state) => ({
  showPrompt:
    !state.personalisation.reminders.personalisationDashboard &&
    !state.personalisation.isPersonalisationComplete &&
    !isEmpty(state.personalisation.companyLogoUrl),
});

const UploadLogoPrompt = connect(mapStateToProps, {
  setReminder,
  setUserOnboarding,
})(({ userId, showPrompt, setReminder, setUserOnboarding }) => {
  const [display, toggleDisplay] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/profile") {
      const dialogHasAppeared = localStorage.getItem(
        `personalisation-profile-${userId}`
      );

      const promptHasAppeared = sessionStorage.getItem(
        `personalisation-prompt-${userId}`
      );

      if (showPrompt && !promptHasAppeared && dialogHasAppeared) {
        toggleDisplay(true);
      }
    }
  }, [location.pathname, showPrompt, userId]);

  return (
    <Container $display={display ? display : undefined}>
      <Flex>
        <InfoContainer>
          <Text>
            Update your profile details to personalise your client
            communications.
          </Text>
        </InfoContainer>
      </Flex>
      <FlexBetween>
        <RemindLater
          onClick={() => {
            sessionStorage.setItem(`personalisation-prompt-${userId}`, "true");
            toggleDisplay(false);
          }}
        >
          Remind Later
        </RemindLater>
        <AddLogo
          onClick={() => {
            setUserOnboarding();
            toggleDisplay(false);
            setReminder(userId, "personalisationDashboard");
          }}
          to="/profile"
        >
          OK
        </AddLogo>
      </FlexBetween>
      <Triangle />
    </Container>
  );
});

export { UploadLogoPrompt };
