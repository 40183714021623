import { LogLevel, PublicClientApplication } from "@azure/msal-browser";

const tenant = process.env.REACT_APP_B2C_TENANT;
const signInPolicy = process.env.REACT_APP_SIGN_IN_POLICY;
const applicationID = process.env.REACT_APP_APPLICATION_ID;

const reactRedirectUri = process.env.REACT_APP_REDIRECT_URI;

// @ts-expect-error
const tenantSubdomain = tenant.split(".")[0];
const instance = `https://${tenantSubdomain}.b2clogin.com/tfp/`;

const signInAuthority = `${instance}${tenant}/${signInPolicy}`;

const refreshPolicy = "B2C_1A_Refresh";
const refreshAuthority = `${instance}${tenant}/${refreshPolicy}`;

// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msEdge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msEdge > 0;
const isFirefox = firefox > 0;

// // Msal Configurations
const signInConfig = {
  auth: {
    clientId: applicationID,
    authority: signInAuthority,
    knownAuthorities: [
      `${tenantSubdomain}.b2clogin.com`,
      signInAuthority,
      refreshAuthority,
    ],
    redirectUri: reactRedirectUri,
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: reactRedirectUri,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        if (process.env.REACT_APP_SHOW_MSAL === "true") {
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            default:
              return;
          }
        }
      },
      piiLoggingEnabled: false,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 60000,
    loadFrameTimeout: 60000,
    tokenRenewalOffsetSeconds: 120,
    allowRedirectInIframe: true,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
    secureCookies: true,
  },
};

let msalInstance: PublicClientApplication;
if (process.env.NODE_ENV === "test") {
  // @ts-expect-error
  msalInstance = { config: { auth: {} } };
} else {
  // @ts-expect-error
  msalInstance = new PublicClientApplication(signInConfig);
}

export const requestConfig = {
  scopes: [`https://${tenant}/api/user.read`, "offline_access"],
};

// Use this config for email invite links
export const tokenRequestConfig = {
  scopes: [`https://${tenant}/api/mail.read`],
};

export { msalInstance };
