import styled from "styled-components";

const Parent = styled.div`
  & span {
    height: 3px;
    display: block;
    border-radius: 5px;
    position: relative;
    background-color: ${(props) => props.theme.colors.primary};
  }
`;

const Child1 = styled.span`
  top: 0;
  transition: top 0.2s ease-in-out 0.2s;
`;

const Child2 = styled.span`
  top: 9px;
  transition: opacity 0s ease-in-out 0.2s;
`;

const Child3 = styled.span`
  bottom: 0;
  transition: top 0.2s ease-in-out 0.2s;
`;

const Container = styled.div`
  height: 40px;
  width: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.colors.primary};

  ${Parent} {
    ${(props) =>
      props.active &&
      `
        ${Child1} {
          top: 6px;
          transform: rotate(-45deg);
          transition: top 0.1s ease-in-out 0s, transform 0.1s ease-in-out 0.1s;
        }

        ${Child2} {
          opacity: 0;
          transition: opacity 0s ease-in-out 0.1s;
        }

        ${Child3} {
          bottom: 0;
          transform: rotate(45deg);
          transition: bottom 0.1s ease-in-out 0s, transform 0.1s ease-in-out 0.1s;
        }
      `}
  }
`;

const ActionText = styled.div`
  bottom: 0;
  position: absolute;
  font-size: 14px;
`;

function Hamburger({ isActive }) {
  return (
    <Container className="hamburger" active={isActive}>
      <div style={{ height: "18px", width: "24px" }}>
        <Parent>
          <Child1 />
          <Child2 />
          <Child3 />
        </Parent>
      </div>
      <ActionText>{isActive ? "Close" : "Menu"}</ActionText>
    </Container>
  );
}

export default Hamburger;
