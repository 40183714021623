import searchIcon from "assets/icons/custom-icons/icon-search.svg";
import axios from "axios";
import InputContainer from "components/common/inputs/InputContainer";
import { Input } from "components/common/inputs/styled";
import { ApiErrorItemLegacy } from "helpers/mappers";
import isEmpty from "helpers/isEmpty";
import useInputRef from "hooks/useInputRef";
import useIsMounted from "hooks/useIsMounted";
import PropTypes from "prop-types";
import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { ReduxState } from "redux-models/reduxStateTypes";
import styled from "styled-components/macro";
import OrgSearchDropdown from "./OrgSearchDropdown";

const InputIcon = styled.img`
  width: 20px;
  position: absolute;
  margin-top: 10px;
  margin-left: -38px;
  @media (min-width: ${(props) => props.theme.breakpoints.mediumScreen}) {
    width: 24px;
    margin-top: 14px;
    margin-left: -42px;
  }
`;

const StyledInput = styled(Input)`
  padding-right: 10px;
  -webkit-appearance: none;

  @media (min-width: ${(props) => props.theme.breakpoints.mediumScreen}) {
    padding-right: 28px;
  }

  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
    cursor: pointer;
    height: 18px;
    width: 18px;
    display: block;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC");
    background-repeat: no-repeat;
    background-size: 18px;
  }
`;

const initialState = {
  focus: false,
  sugIndex: 0,
  search: "",
  companies: [],
  curSource: null,
  error: null,
};

const TOGGLE_FOCUS = "TOGGLE_FOCUS";
const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";
const SET_SEARCH_AND_SOURCE = "SET_SEARCH_AND_SOURCE";
//
const SET_COMPANIES = "SET_COMPANIES";
const SET_COMPANIES_ERROR = "SET_COMPANIES_ERROR";

const ARROW_UP = "ARROW_UP";
const ARROW_DOWN = "ARROW_DOWN";

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case TOGGLE_FOCUS:
      return { ...state, focus: !state.focus, sugIndex: 0 };
    case SET_SEARCH_AND_SOURCE:
      return { ...state, ...action.payload, sugIndex: 0 };
    case SET_SEARCH_VALUE:
      return { ...state, search: action.payload, sugIndex: 0 };
    case SET_COMPANIES:
      return { ...state, companies: action.payload, sugIndex: 0, error: null };
    case SET_COMPANIES_ERROR:
      return { ...state, error: action.payload, sugIndex: 0 };
    case ARROW_DOWN:
      if (state.sugIndex < state.companies.length - 1) {
        return { ...state, sugIndex: state.sugIndex + 1 };
      }
      return state;
    case ARROW_UP:
      if (state.sugIndex > 0) {
        return { ...state, sugIndex: state.sugIndex - 1 };
      }
      return state;
    default:
      return state;
  }
};

const OrgSearch = ({
  placeholder,
  name,
  type,
  error,
  firstError,
  text,
  value,
  setCompany,
  change,
  disabled,
}: {
  placeholder?: string;
  name: string;
  type?: string;
  error?: any;
  firstError?: ApiErrorItemLegacy | null;
  text: string;
  value?: any;
  setCompany: (company: any) => any;
  change: (name: string, value: string) => any;
  disabled?: any;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { focus, sugIndex, search, companies } = state;

  const isMounted = useIsMounted();
  const inputRef = useInputRef(error, firstError);

  useEffect(() => {
    dispatch({
      type: SET_SEARCH_VALUE,
      payload: value,
    });
  }, [value]);

  const toggleFocus = () => {
    setTimeout(() => {
      if (isMounted.current) {
        dispatch({ type: TOGGLE_FOCUS });
      }
    }, 200);
  };

  function searchByABN(abnString: string) {
    axios
      .get(`${process.env.REACT_APP_URL_API}/v1/companies/abn/${abnString}`, {
        headers: { "x-api-key": process.env.REACT_APP_X_API_KEY },
      })
      .then((res) => dispatch({ type: SET_COMPANIES, payload: [res.data] }))
      .catch((err) =>
        dispatch({ type: SET_COMPANIES_ERROR, payload: err.response.data })
      );
  }

  const handleChange = (e: any) => {
    const { curSource } = state;

    curSource && curSource.cancel();

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    dispatch({
      type: SET_SEARCH_AND_SOURCE,
      payload: { curSource: source, search: e.target.value },
    });

    // check if it satisfies conditions of an ABN
    const abnString = e.target.value.replace(/ /g, "");
    if (!abnString.match(/[^0-9]/g) && abnString.length === 11) {
      searchByABN(abnString);
    } else if (e.target.value.length >= 3) {
      // only search after three values 29 158 849 471
      lookUpBusiness(e.target.value, source, dispatch);
    }

    change(name, e.target.value);
  };

  /**
   * @function
   * @param {string} searchTerm Business name
   * @param {*} source axios CancelToken.source()
   * @param {() => void} dispatch
   * @returns {void}
   */
  async function lookUpBusiness(
    searchTerm: any,
    source: any,
    dispatch: React.Dispatch<any>
  ) {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_API}/v1/companies/search?term=${searchTerm}`,
        {
          headers: { "x-api-key": process.env.REACT_APP_X_API_KEY },
          cancelToken: source.token,
        }
      );
      dispatch({ type: SET_COMPANIES, payload: res.data.companies });
    } catch (err: any) {
      if (!axios.isCancel(err)) {
        if (err?.response?.status > 299) {
          dispatch({ type: SET_COMPANIES_ERROR, payload: err.response.data });
        }
      }
    }
  }

  const handleSubmit = (search: any) => {
    if (isEmpty(search)) return;
    setCompany(search);

    // search the abn
    // set the company abn, date, state & postcode
    axios
      .get(`${process.env.REACT_APP_URL_API}/v1/companies/abn/${search.abn}`, {
        headers: { "x-api-key": process.env.REACT_APP_X_API_KEY },
      })
      .then((res) => setCompany({ ...search, ...res.data }))
      .catch((err) => console.log(err));
  };

  const handleClick = (searchResult: any) => {
    // set the state locally and in the Formik form
    dispatch({ type: SET_SEARCH_VALUE, payload: searchResult.name });
    handleSubmit(searchResult);
  };

  const handleKeyDown = (e: any) => {
    const { sugIndex, companies } = state;
    // check key codes for moving up and down the suggestions
    // arrow down
    if (e.keyCode === 40) {
      dispatch({ type: ARROW_DOWN });
      // arrow up
    } else if (e.keyCode === 38) {
      dispatch({ type: ARROW_UP });
      // enter
    } else if (e.keyCode === 13) {
      if (!isEmpty(companies)) {
        dispatch({
          type: SET_SEARCH_VALUE,
          payload: companies[sugIndex].name,
        });
        handleSubmit(companies[sugIndex]);
      }
    }
  };

  return (
    <InputContainer error={error} name={name} text={text}>
      <StyledInput
        placeholder={placeholder}
        value={search ?? ""}
        name={name}
        id={name}
        type={type}
        onChange={handleChange}
        onFocus={toggleFocus}
        onBlur={toggleFocus}
        onKeyDown={handleKeyDown}
        autoComplete="off"
        ref={inputRef}
        disabled={disabled}
      />
      {isEmpty(search) && <InputIcon src={searchIcon} />}
      <OrgSearchDropdown
        companies={companies}
        focus={focus}
        sugIndex={sugIndex}
        handleClick={handleClick}
        error={state.error}
      />
    </InputContainer>
  );
};

OrgSearch.propTypes = {
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  error: PropTypes.string,
  text: PropTypes.string.isRequired,
  setCompany: PropTypes.func.isRequired,
};

const mapStateToProps = (state: ReduxState) => ({
  firstError: state.company.firstError,
});

const OrgNameSearch = connect(mapStateToProps)(OrgSearch);

export { OrgNameSearch, OrgSearch };
