import isEmpty from "helpers/isEmpty";
import {
  DATE_COLUMN,
  EMAIL_COLUMN,
  NAME_COLUMN,
  ROLE_COLUMN,
  STATE_COLUMN,
  VERIFIED_COLUMN,
} from "utils/constants";

export const defineUserColumns = (items: any[]) => {
  let columns: Array<string | null> = [null, null, null, null, null, null];

  items.forEach((item) => {
    if (!isEmpty(item.firstName)) {
      columns[0] = NAME_COLUMN;
    }
    if (!isEmpty(item.email)) {
      columns[1] = EMAIL_COLUMN;
    }
    if (!isEmpty(item.created)) {
      columns[2] = DATE_COLUMN;
    }
    if (!isEmpty(item.role)) {
      columns[3] = ROLE_COLUMN;
    }
    if (!isEmpty(item.address)) {
      columns[4] = STATE_COLUMN;
    }
    if (!isEmpty(item.status)) {
      columns[5] = VERIFIED_COLUMN;
    }
  });

  return columns.filter((c) => c != null);
};
