import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

const Title = styled.p`
  font-size: 1.2rem;
`;

const Text = styled.p`
  font-size: 1rem;
`;

const UploadSpecs = ({ fileSize, acceptedFileTypes }) => {
  return (
    <>
      <Title>Files must be under {fileSize}</Title>
      <Title>Accepted file types:</Title>
      {acceptedFileTypes.map((file, index) => {
        return <Text key={`upload-specs-${file}-index`}>{file}</Text>;
      })}
    </>
  );
};

UploadSpecs.propTypes = {
  fileSize: PropTypes.string.isRequired,
  acceptedFileTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export { UploadSpecs };
