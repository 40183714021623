export const publicNavOptions = [
  {
    text: "Solutions",
    items: [
      {
        name: "Business Finance",
        link: "https://www.scotpac.com.au/our-solutions/business-finance/",
      },
      {
        name: "Trade Finance",
        link: "https://www.scotpac.com.au/our-solutions/trade-finance/",
      },
      {
        name: "Invoice Finance",
        link: "https://www.scotpac.com.au/our-solutions/invoice-finance/",
      },
      {
        name: "Asset Finance",
        link: "https://www.scotpac.com.au/our-solutions/asset-finance/",
      },
      {
        name: "Business Loans",
        link: "https://www.scotpac.com.au/business-loans/",
      },
      // {
      //   name: "Cash Connector",
      //   link: "https://www.scotpac.com.au/our-solutions/cash-connector/",
      // },
      //
      // {
      //   name: "Equipment Finance",
      //   link: "https://www.scotpac.com.au/our-solutions/equipment-finance/",
      // },
      //
      // {
      //   name: "Tradeline",
      //   link: "https://www.scotpac.com.au/our-solutions/tradeline/",
      // },
      // {
      //   name: "Import Finance",
      //   link: "https://www.scotpac.com.au/our-solutions/import-finance/",
      // },
      // {
      //   name: "Export Finance",
      //   link: "https://www.scotpac.com.au/our-solutions/export-finance/",
      // },
      // {
      //   name: "Invoice Finance",
      //   link: "https://www.scotpac.com.au/our-solutions/asset-finance/",
      // },
      // {
      //   name: "Debtor Finance",
      //   link: "https://www.scotpac.com.au/our-solutions/debtor-finance//",
      // },
      // {
      //   name: "Invoice Discounting",
      //   link: "https://www.scotpac.com.au/our-solutions/invoice-discounting/",
      // },
      // {
      //   name: "Debt Factoring",
      //   link: "https://www.scotpac.com.au/our-solutions/debt-factoring/",
      // },
      // {
      //   name: "Selective Invoice Finance",
      //   link:
      //     "https://www.scotpac.com.au/our-solutions/selective-invoice-financing/",
      // },
      // {
      //   name: "Progress Claim Finance",
      //   link:
      //     "https://www.scotpac.com.au/our-solutions/progress-claim-finance/",
      // },
      // {
      //   name: "Bad Debt Protection",
      //   link: "https://www.scotpac.com.au/our-solutions/bad-debt-protection/",
      // },
      // {
      //   name: "Supply Chain Finance",
      //   link: "https://www.scotpac.com.au/our-solutions/supply-chain-finance/",
      // },
    ],
    isPublic: true,
  },
  {
    text: "Partners",
    items: [
      { name: "Partners", link: "https://www.scotpac.com.au/our-partners/" },
      {
        name: "Brokers",
        link:
          "https://www.scotpac.com.au/our-partners/brokers-and-scottish-pacific/",
      },
      {
        name: "Accountants",
        link:
          "https://www.scotpac.com.au/our-partners/accountants-and-scottish-pacific/",
      },
    ],
    isPublic: true,
  },
  {
    text: "About Us",
    items: [
      { name: "About Us", link: "https://www.scotpac.com.au/about-us/" },
      { name: "News", link: "https://www.scotpac.com.au/news-articles/" },
      {
        name: "Client Stories",
        link: "https://www.scotpac.com.au/client-stories/",
      },
      {
        name: "In The Community",
        link: "https://www.scotpac.com.au/in-the-community/",
      },
    ],
    isPublic: true,
  },
  {
    text: "Contact Us",
    items: [
      { name: "Contact Us", link: "https://www.scotpac.com.au/contact-us/" },
    ],
    isPublic: true,
  },
  {
    text: "Login",
    items: [
      {
        name: "Cash Connector Login",
        link: "https://e3.scotpac.dancerace-apps.com/scotpac/e3intro",
      },
      {
        name: "Invoice Finance Login",
        link: "https://e3.scotpac.dancerace-apps.com/scotpac/e3intro",
      },
      {
        name: "eTrade Login",
        link: "https://portal.sptradeline.com/users/sign_in",
      },
      {
        name: "Partner Portal Login",
        link: "https://partner.scotpac.com.au/register/broker/information",
      },
      {
        name: "Property Finance Login",
        link: "https://scotpac.xchangeonline.com.au",
      },
    ],
    isPublic: true,
  },
];
