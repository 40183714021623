import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { medBtnStyles } from "components/common/buttons";

const LabelButton = styled.label`
  display: inline-block;
  ${medBtnStyles}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const FileUpload = ({
  name,
  accept,
  btnText,
  change,
}: {
  name: string | undefined;
  change: React.ChangeEventHandler<HTMLInputElement> | undefined;
  accept: string | undefined;
  btnText: React.ReactNode;
}) => {
  return (
    <ButtonContainer>
      <input
        accept={accept}
        type="file"
        name={name}
        hidden
        id={name}
        onChange={change}
      />
      <LabelButton
        $marginBottom="1rem"
        $marginTop="1.2rem"
        width={22}
        htmlFor={name}
        $outlinePrimary
      >
        {btnText}
      </LabelButton>
    </ButtonContainer>
  );
};

FileUpload.defaultProps = {
  btnText: "Browse Computer",
};

FileUpload.propTypes = {
  name: PropTypes.string,
  change: PropTypes.func.isRequired,
};

FileUpload.defaultProps = {
  accept: `text/plain,
  text/csv,
  application/pdf,
  application/msword,
  application/vnd.ms-excel,
  application/vnd.oasis.opendocument.text,
  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document`,
};

export default FileUpload;
