import styled from "styled-components/macro";

const PushDown = styled.div<{ active: boolean }>`
  position: relative;
  margin: 85px auto 0 auto;
  min-width: 320px;
  overflow: hidden;
  transition: all 0.2s cubic-bezier(0.37, 0, 0.63, 1);
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.largeScreen}) {
    margin: 70px auto 0 auto;
  }

  ${(props) =>
    props.active &&
    `
    position: fixed;
    transform: translate3d(calc(100vw - 70px),0,0);
  `}
`;

export { PushDown };
