import {
  CALL_API,
  GET_PROMOTIONS_REQUEST,
  GET_PROMOTIONS_SUCCESS,
  GET_PROMOTIONS_FAILURE,
  ADD_PROMOTION_REQUEST,
  ADD_PROMOTION_SUCCESS,
  ADD_PROMOTION_FAILURE,
  ADD_PROMOTION_IMAGE_REQUEST,
  ADD_PROMOTION_IMAGE_SUCCESS,
  ADD_PROMOTION_IMAGE_FAILURE,
  UPDATE_PROMOTION_REQUEST,
  UPDATE_PROMOTION_SUCCESS,
  UPDATE_PROMOTION_FAILURE,
  UPDATE_PROMOTION_ENABLE_REQUEST,
  UPDATE_PROMOTION_ENABLE_SUCCESS,
  UPDATE_PROMOTION_ENABLE_FAILURE,
  DELETE_PROMOTION_REQUEST,
  DELETE_PROMOTION_SUCCESS,
  DELETE_PROMOTION_FAILURE,
  EDIT_PROMOTION,
  CANCEL_PROMOTION,
} from "./types";
import isEmpty from "lodash/isEmpty";

/**
 *
 * @param {object} promotion
 */
export const editPromotion = (promotion) => async (dispatch) =>
  dispatch({
    type: EDIT_PROMOTION,
    payload: promotion,
  });

/**
 *
 * @desc clears the promotion
 */
export const cancelPromotion = () => async (dispatch) =>
  dispatch({
    type: CANCEL_PROMOTION,
  });

/**
 * @route /settings/promotions
 * @method GET
 * @desc get the promotions for a user
 * @param {number} page
 * @param {count} count
 * @param {boolean} isEnabled
 */
export const getPromotions = (page, count, isEnabled) => async (dispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/settings/promotions`,
      method: "GET",
      query: {
        page,
        count,
        isEnabled,
      },
      types: [
        GET_PROMOTIONS_REQUEST,
        GET_PROMOTIONS_SUCCESS,
        GET_PROMOTIONS_FAILURE,
      ],
    },
  });

/**
 * @route /settings/promotions
 * @method POST
 * @desc add a promotion
 * @param {string} name
 * @param {string} url
 * @param {boolean} isActiveBroker
 * @param {boolean} isInactiveBroker
 * @param {boolean} isEnabled
 * @param {number} order
 * @param {FormData} promotionImage
 */
export const addPromotion = ({
  name,
  url,
  isActiveBroker,
  isInactiveBroker,
  isEnabled,
  order,
  promotionImage,
}) => async (dispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/settings/promotions/images`,
      method: "POST",
      body: promotionImage,
      types: [
        ADD_PROMOTION_IMAGE_REQUEST,
        ADD_PROMOTION_IMAGE_SUCCESS,
        ADD_PROMOTION_IMAGE_FAILURE,
      ],
    },
  }).then((res) => {
    if (!isEmpty(res)) {
      dispatch({
        [CALL_API]: {
          endpoint: `/v1/settings/promotions`,
          method: "POST",
          body: {
            name,
            url,
            isActiveBroker,
            isInactiveBroker,
            isEnabled,
            order: order <= 11 ? order : 11,
            imageId: res.payload.id,
          },
          types: [
            ADD_PROMOTION_REQUEST,
            ADD_PROMOTION_SUCCESS,
            ADD_PROMOTION_FAILURE,
          ],
        },
      });
    }
  });

/**
 * @route /settings/promotions/{promotionId}
 * @method PUT
 * @desc update a promotion
 * @param {string} promotionId
 * @param {string} name
 * @param {string} url
 * @param {boolean} isActiveBroker
 * @param {boolean} isInactiveBroker
 * @param {boolean} isEnabled
 * @param {number} order
 * @param {string} imageId
 * @param {boolean} enable
 * @param {FormData} promotionImage
 */
export const updatePromotion = (
  {
    promotionId,
    name,
    url,
    isActiveBroker,
    isInactiveBroker,
    isEnabled,
    order,
    imageId,
    promotionImage,
  },
  enable = false
) => async (dispatch) => {
  if (promotionImage) {
    dispatch({
      [CALL_API]: {
        endpoint: `/v1/settings/promotions/images`,
        method: "POST",
        body: promotionImage,
        types: [
          ADD_PROMOTION_IMAGE_REQUEST,
          ADD_PROMOTION_IMAGE_SUCCESS,
          ADD_PROMOTION_IMAGE_FAILURE,
        ],
      },
    }).then((res) => {
      if (!isEmpty(res)) {
        dispatch({
          [CALL_API]: {
            endpoint: `/v1/settings/promotions/${promotionId}`,
            method: "PUT",
            body: {
              name,
              url,
              isActiveBroker,
              isInactiveBroker,
              isEnabled,
              order: order <= 11 ? order : 11,
              imageId: res.payload.id,
            },
            types: [
              UPDATE_PROMOTION_REQUEST,
              UPDATE_PROMOTION_SUCCESS,
              UPDATE_PROMOTION_FAILURE,
            ],
          },
        });
      }
    });
  } else {
    dispatch({
      [CALL_API]: {
        endpoint: `/v1/settings/promotions/${promotionId}`,
        method: "PUT",
        body: {
          name,
          url,
          isActiveBroker,
          isInactiveBroker,
          isEnabled,
          order: order <= 11 ? order : 11,
          imageId,
        },
        types: enable
          ? [
              UPDATE_PROMOTION_ENABLE_REQUEST,
              UPDATE_PROMOTION_ENABLE_SUCCESS,
              UPDATE_PROMOTION_ENABLE_FAILURE,
            ]
          : [
              UPDATE_PROMOTION_REQUEST,
              UPDATE_PROMOTION_SUCCESS,
              UPDATE_PROMOTION_FAILURE,
            ],
      },
    });
  }
};

/**
 * @route /settings/promotions/{promotionId}
 * @method DELETE
 * @desc update a promotion
 * @param {string} promotionId
 */
export const deletePromotion = ({ promotionId }) => async (dispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/settings/promotions/${promotionId}`,
      method: "DELETE",
      types: [
        DELETE_PROMOTION_REQUEST,
        DELETE_PROMOTION_SUCCESS,
        DELETE_PROMOTION_FAILURE,
      ],
    },
  });
