import React from "react";
import PropTypes from "prop-types";
import {
  CheckboxContainer,
  CheckboxText,
  Input,
  LabelInput,
} from "./Checkbox.styled";

function Checkbox({
  text,
  checked,
  change,
  name,
  noMargin,
}: {
  text?: any;
  checked?: any;
  change?: any;
  name?: any;
  noMargin?: any;
}) {
  return (
    <CheckboxContainer $noMargin={noMargin}>
      <Input
        type="checkbox"
        name={name}
        id={name}
        checked={checked}
        onChange={(e) => {
          change(e.target.checked);
        }}
      />
      <LabelInput checked={checked} htmlFor={name} />
      <CheckboxText>{text}</CheckboxText>
    </CheckboxContainer>
  );
}

Checkbox.defaultProps = {
  display: "flex",
};

Checkbox.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
};

export { Checkbox };
