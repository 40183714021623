import { BusinessFuel } from "redux-models/reduxStateTypes";
import {
  BF_CREATE_APPLICATION_FAILURE,
  BF_CREATE_APPLICATION_REQUEST,
  BF_CREATE_APPLICATION_SUCCESS,
  BF_CREATE_CUSTOMER_FAILURE,
  BF_CREATE_CUSTOMER_REQUEST,
  BF_CREATE_CUSTOMER_SUCCESS,
  BF_FINANCING_NEEDS_FAILURE,
  BF_FINANCING_NEEDS_REQUEST,
  BF_FINANCING_NEEDS_SUCCESS,
  BF_GET_APPLICATION_FAILURE,
  BF_GET_APPLICATION_REQUEST,
  BF_GET_APPLICATION_SUCCESS,
  BF_GET_BROKER_FAILURE,
  BF_GET_BROKER_REQUEST,
  BF_GET_BROKER_SUCCESS,
  BF_GET_CUSTOMER_DETAILS_FAILURE,
  BF_GET_CUSTOMER_DETAILS_REQUEST,
  BF_GET_CUSTOMER_DETAILS_SUCCESS,
  BF_GET_FILES_FAILURE,
  BF_GET_FILES_REQUEST,
  BF_GET_FILES_SUCCESS,
  BF_GET_RULES_SUMMARY_FAILURE,
  BF_GET_RULES_SUMMARY_REQUEST,
  BF_GET_RULES_SUMMARY_SUCCESS,
  BF_REMOVE_ITEM_AT_PATH,
  BF_RESET_STATE,
  BF_SELECT_BROKER,
  BF_SELECT_MANAGER,
  BF_SET_ITEM_AT_PATH,
  BF_SUBMIT_APPLICATION_FAILURE,
  BF_SUBMIT_APPLICATION_REQUEST,
  BF_SUBMIT_APPLICATION_SUCCESS,
  BF_UPDATE_CUSTOMER_ADDRESS_DETAILS,
  BF_UPDATE_CUSTOMER_FAILURE,
  BF_UPDATE_CUSTOMER_REQUEST,
  BF_UPDATE_CUSTOMER_SUCCESS,
  BF_UPDATE_OFFICE_HOLDERS_FAILURE,
  BF_UPDATE_OFFICE_HOLDERS_REQUEST,
  BF_UPDATE_OFFICE_HOLDERS_SUCCESS,
  BF_UPLOAD_FILE_FAILURE,
  BF_UPLOAD_FILE_REQUEST,
  BF_UPLOAD_FILE_SUCCESS,
} from "actions/types";
import {
  removeItemAtPathRedux,
  setItemAtPathRedux,
  updateCustomerAddressDetails,
} from "helpers/data-helpers/itemAtPath";
import { mapApiToReduxBfCustomer } from "helpers/mappers/mapApiToReduxBfCustomer";
import { loadingState, unloadingState } from "helpers/reducerHelpers";
import cloneDeep from "lodash/cloneDeep";
import {
  mapApiErrorToReduxState,
  mapApiToBfFinanceNeeds,
  Merge,
} from "helpers/mappers";
import { HttpErrorAction } from "utils/api";

const initialState: BusinessFuel = {
  error: null,
  recommendation: null,
  applicationId: null,
  loading: false,
  isSubmitted: false,
  submittedDate: null,
  brokerInfo: null,
  form: {
    manager: null,
    broker: null,
    customerName: undefined,
    authorizationCheck: false,
    structure: null,
    selectedCustomerId: null,
    documents: [],
    isSubmitted: false,
    clientDetails: undefined,
    businessDetails: undefined,
    financeNeeds: undefined,
  },
};

const businessFuel = (
  state: BusinessFuel = initialState,
  action: { type: string; payload: any } | HttpErrorAction
): BusinessFuel => {
  switch (action.type) {
    case BF_GET_APPLICATION_REQUEST:
      return loadingState(state, action.type);
    case BF_GET_APPLICATION_SUCCESS: {
      const res = action.payload;

      return {
        ...unloadingState(state, action.type),
        applicationId: res.id,
        isSubmitted: res.isSubmitted,
        submittedDate: res.submitted,
        brokerInfo: res.brokerInfo,

        form: {
          ...state.form,
          selectedCustomerId: res.customerId,
          manager: res.managerInfo,
          financeNeeds: mapApiToBfFinanceNeeds(res),
        },
      };
    }
    case BF_GET_APPLICATION_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case BF_GET_RULES_SUMMARY_REQUEST:
      return loadingState(state, action.type);
    case BF_GET_RULES_SUMMARY_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        bfRules: action.payload,
      };
    case BF_GET_RULES_SUMMARY_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case BF_GET_CUSTOMER_DETAILS_REQUEST:
      return loadingState(state, action.type);
    case BF_GET_CUSTOMER_DETAILS_SUCCESS: {
      const mappedResponse = mapApiToReduxBfCustomer({
        resp: action.payload,
        state: state.form,
        strategy: Merge.All,
      });
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          ...mappedResponse,
        },
      };
    }
    case BF_GET_CUSTOMER_DETAILS_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case BF_CREATE_CUSTOMER_REQUEST:
      return loadingState(state, action.type);
    case BF_CREATE_CUSTOMER_SUCCESS:
      const mappedResponse = mapApiToReduxBfCustomer({
        resp: action.payload,
        state: state.form,
        strategy: Merge.All,
      });
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          ...mappedResponse,
        },
      };
    case BF_CREATE_CUSTOMER_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case BF_UPDATE_CUSTOMER_REQUEST:
      return loadingState(state, action.type);
    case BF_UPDATE_CUSTOMER_SUCCESS: {
      const mappedResponse = mapApiToReduxBfCustomer({
        resp: action.payload,
        state: state.form,
        strategy: Merge.Business,
      });
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          ...mappedResponse,
        },
      };
    }
    case BF_UPDATE_CUSTOMER_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case BF_UPDATE_OFFICE_HOLDERS_REQUEST:
      return loadingState(state, action.type);
    case BF_UPDATE_OFFICE_HOLDERS_SUCCESS: {
      const mappedResponse = mapApiToReduxBfCustomer({
        resp: action.payload,
        state: state.form,
        strategy: Merge.Clients,
      });
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          ...mappedResponse,
        },
      };
    }
    case BF_UPDATE_OFFICE_HOLDERS_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case BF_CREATE_APPLICATION_REQUEST:
      return loadingState(state, action.type);
    case BF_CREATE_APPLICATION_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        applicationId: action.payload.id,
        form: {
          ...state.form,
          selectedCustomerId: action.payload.customerId,
        },
      };
    case BF_CREATE_APPLICATION_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case BF_GET_FILES_REQUEST:
      return loadingState(state, action.type);
    case BF_GET_FILES_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          documents: action.payload.documents,
        },
      };
    case BF_GET_FILES_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case BF_UPLOAD_FILE_REQUEST:
      return loadingState(state, action.type);
    case BF_UPLOAD_FILE_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          documents: [action.payload, ...state.form.documents],
        },
      };
    case BF_UPLOAD_FILE_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case BF_SUBMIT_APPLICATION_REQUEST:
      return loadingState(state, action.type);
    case BF_SUBMIT_APPLICATION_SUCCESS:
      const res = action.payload;

      return {
        ...unloadingState(state, action.type),
        isSubmitted: true,
        submittedDate: res.submitted,
        brokerInfo: res.brokerInfo,
      };
    case BF_SUBMIT_APPLICATION_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case BF_SET_ITEM_AT_PATH:
      return setItemAtPathRedux(state, action);
    case BF_REMOVE_ITEM_AT_PATH:
      return removeItemAtPathRedux(state, action);

    case BF_UPDATE_CUSTOMER_ADDRESS_DETAILS:
      return updateCustomerAddressDetails(state, action);

    case BF_RESET_STATE:
      return initialState;

    case BF_SELECT_MANAGER:
      return {
        ...state,
        form: {
          ...state.form,
          manager: action.payload,
        },
      };

    case BF_SELECT_BROKER:
      const newState = cloneDeep(initialState);
      newState.form.broker = action.payload;
      return newState;

    case BF_GET_BROKER_REQUEST:
      return loadingState(state, action.type);
    case BF_GET_BROKER_SUCCESS:
      const user = action.payload;

      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          broker: {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            companyName: user.company?.name,
            address: user.company?.address,
            role: user.role,
            status: user.status,
          },
        },
      };
    case BF_GET_BROKER_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case BF_FINANCING_NEEDS_REQUEST:
      return loadingState(state, action.type);
    case BF_FINANCING_NEEDS_SUCCESS: {
      return {
        ...unloadingState(state, action.type),
        form: {
          ...state.form,
          financeNeeds: mapApiToBfFinanceNeeds(action.payload),
        },
      };
    }
    case BF_FINANCING_NEEDS_FAILURE:
      return mapApiErrorToReduxState(action, state);

    default:
      return state;
  }
};

export default businessFuel;
