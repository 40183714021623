import { ApiErrorItemLegacy } from "helpers/mappers";
import useInputRef from "hooks/useInputRef";
import PropTypes from "prop-types";
import { memo } from "react";
import { connect } from "react-redux";
import { ReduxState } from "redux-models/reduxStateTypes";
import InputContainer from "./InputContainer";
import { Input } from "./styled";

const AuthenticationInput = memo(
  ({
    placeholder,
    value,
    name,
    type,
    change,
    error,
    firstError,
    text,
    disabled,
    required,
  }: {
    placeholder: any;
    value: any;
    name: any;
    type: any;
    change: any;
    error: any;
    firstError?: ApiErrorItemLegacy | null;
    text: any;
    disabled?: any;
    required?: any;
  }) => {
    const inputRef = useInputRef(error, firstError);

    return (
      <InputContainer text={text} name={name} error={error}>
        <Input
          placeholder={placeholder}
          value={value}
          name={name}
          id={name}
          type={type}
          onChange={change}
          disabled={disabled}
          ref={inputRef}
          required={required}
        />
      </InputContainer>
    );
  }
);

// @ts-expect-error
AuthenticationInput.defaultProps = {
  disabled: false,
  value: "",
};

// @ts-expect-error
AuthenticationInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  error: PropTypes.string,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

const mapStateToProps = (state: ReduxState) => ({
  firstError: state.company.firstError,
});

const AuthInput = connect(mapStateToProps)(AuthenticationInput);

export { AuthInput };
