import { useCallback, useRef } from "react";

export function useFunctionRef<FUNC extends (..._: any[]) => any>(
  func: FUNC
): FUNC {
  const ref = useRef<FUNC>(func);
  ref.current = func;

  // only a single instance of this callback is created
  const wrappedFunction = useCallback((...args: Parameters<FUNC>) => {
    return ref.current(...args);
  }, []);

  return wrappedFunction as FUNC;
}
