import {
  CLEAR_ASSET_FINANCE_ERROR,
  GET_CUSTOMERS_FAILURE,
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
} from "actions/types";
import { mapApiErrorToReduxState } from "helpers/mappers";
import { addPage, loadingState, unloadingState } from "helpers/reducerHelpers";
import { CustomerState } from "redux-models/reduxStateTypes";

const initialState: CustomerState = {
  loading: false,
  error: null,
  recommendation: null,
  items: [],
  pageNumber: 0,
  pageSize: 10,
  totalPages: 0,
};

const form = (state = initialState, action: any): CustomerState => {
  switch (action.type) {
    case GET_CUSTOMERS_REQUEST:
      return loadingState(state, action.type);
    case GET_CUSTOMERS_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        ...addPage(state, action.payload),
        error: initialState.error,
      };
    case GET_CUSTOMERS_FAILURE:
      return mapApiErrorToReduxState(action, state);
    case CLEAR_ASSET_FINANCE_ERROR:
      return {
        ...state,
        error: initialState.error,
      };
    default:
      return state;
  }
};

export default form;
