import { inviteBroker as inviteBrokerAction } from "actions";
import { INVITE_BROKER_FAILURE } from "actions/types";
import { BigButton } from "components/common/buttons";
import { OverallErrors } from "components/common/error/OverallErrors";
import { GeneralInput, Select } from "components/common/inputs";
import { InviteModelFormData } from "components/layout/invite-modal/types";
import { useFormikContext } from "formik";
import { FormToApiFieldMap, useFormErrors } from "hooks/useErrors";
import { useFunctionRef } from "hooks/useFuncRef";
import isEmpty from "lodash/isEmpty";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "redux-models/reduxStateTypes";
import styled from "styled-components/macro";
import {
  ADMIN_VALUE,
  BROKER_VALUE,
  COMPANY_VALUE,
  MANAGER_VALUE,
  STAFF_VALUE,
  roleDropdownArray,
} from "utils/constants";
import BrokerCompanySelectPagination from "./BrokerCompany";
import { Info, Title } from "./styled";

const InputsContainer = styled.div`
  margin: 3rem 0;
  text-align: left;
`;

const inviteFormErrorFieldMap: FormToApiFieldMap<InviteModelFormData> = {
  inviteCompany: ["companyId"],
  inviteEmail: ["email"],
  inviteFirstName: ["firstName"],
  inviteLastName: ["lastName"],
  inviteRole: ["role"],
};

const InviteFormInputs = () => {
  const dispatch = useDispatch();
  const {
    values,
    setFieldValue,
    resetForm,
    handleChange,
    handleBlur,
    errors,
    setFieldError,
  } = useFormikContext<InviteModelFormData>();

  const userRole = useSelector(
    (state: ReduxState) => state.auth.account.idTokenClaims.role
  );

  console.log(userRole);

  useFormErrors({
    setFieldError,
    fieldMap: inviteFormErrorFieldMap,
  });

  const inviteBroker = useFunctionRef(
    (
      firstName: string,
      lastName: string,
      email: string,
      role: string | undefined,
      companyId: string
    ) =>
      inviteBrokerAction(firstName, lastName, email, role, companyId)(dispatch)
  );

  const {
    inviteFirstName,
    inviteLastName,
    inviteEmail,
    inviteRole,
    inviteCompany,
  } = values;

  useEffect(() => {
    if (inviteRole !== "company" && inviteCompany) {
      setFieldValue("inviteCompany", "");
    }
  }, [inviteCompany, inviteRole, setFieldValue]);

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, [resetForm]);

  const selectedCompany = roleDropdownArray.find((r) => {
    return r.value === inviteRole;
  });

  return (
    <>
      <Title>Invite User</Title>
      <Info>
        We will send an invitation with a link to register for the Partner
        Portal
      </Info>

      <InputsContainer>
        <GeneralInput
          placeholder="First Name"
          value={inviteFirstName}
          name="inviteFirstName"
          type="text"
          change={handleChange}
          text=""
          error={errors?.inviteFirstName}
        />
        <GeneralInput
          placeholder="Last Name"
          value={inviteLastName}
          name="inviteLastName"
          type="text"
          change={handleChange}
          text=""
          error={errors?.inviteLastName}
        />
        <GeneralInput
          placeholder="Email Address"
          value={inviteEmail}
          name="inviteEmail"
          type="text"
          change={handleChange}
          text=""
          error={errors?.inviteEmail}
        />
        {userRole === "admin" && (
          <Select
            placeholder="Role"
            name="inviteRole"
            change={(name, value) => {
              // @ts-expect-error
              setFieldValue(name, !isEmpty(value) ? value[0].value : "");
            }}
            value={!isEmpty(selectedCompany) ? [selectedCompany] : []}
            options={roleDropdownArray.filter((r) =>
              userRole === "admin"
                ? [
                    BROKER_VALUE,
                    MANAGER_VALUE,
                    ADMIN_VALUE,
                    COMPANY_VALUE,
                    STAFF_VALUE,
                  ].includes(r.value)
                : [BROKER_VALUE].includes(r.value)
            )}
            labelField="name"
            valueField="value"
            error={errors.inviteRole}
            text=""
          />
        )}
        {inviteRole === "company" && (
          <BrokerCompanySelectPagination
            formikValue={inviteCompany}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            name="inviteCompany"
            placeholder="Select a company"
            error={errors.inviteCompany}
          />
        )}
      </InputsContainer>
      <OverallErrors
        filterActions={[INVITE_BROKER_FAILURE]}
        style={{ marginTop: "2rem", marginBottom: "2rem" }}
      />

      <BigButton
        $coral
        onClick={() => {
          inviteBroker(
            inviteFirstName.trim(),
            inviteLastName.trim(),
            inviteEmail.trim(),
            inviteRole,
            // @ts-expect-error
            !isEmpty(inviteCompany) ? inviteCompany[0].id : ""
          );
        }}
      >
        Invite {inviteRole}
      </BigButton>
    </>
  );
};

export default InviteFormInputs;
