import React from "react";
import styled from "styled-components/macro";
import { Loader } from "./Loader";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: ${(props) => props.theme.zIndex.overlay};
`;

function LoaderOverlay() {
  return (
    <React.Fragment>
      <Overlay>
        <Loader />
      </Overlay>
    </React.Fragment>
  );
}

export { LoaderOverlay };
