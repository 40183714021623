import React, { FC } from "react";
import styled from "styled-components/macro";

const Promotion = styled.div`
  width: 100%;
  z-index: 100;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  max-height: 27rem;
  object-fit: contain;
`;

const UnstyledLink = styled.a`
  text-decoration: none;
  display: block;
`;

type PromotionCardProps = {
  image: { url: string };
  url: string;
};

const PromotionCard: FC<PromotionCardProps> = ({ image, url }) => {
  const renderBody = () => {
    if (url) {
      if (
        process.env.REACT_APP_REDIRECT_URI &&
        url.includes(process.env.REACT_APP_REDIRECT_URI)
      ) {
        return (
          <UnstyledLink href={url}>
            <Image src={image.url} />
          </UnstyledLink>
        );
      } else {
        return (
          <UnstyledLink href={url} target="_blank" rel="noreferrer">
            <Image src={image.url} />
          </UnstyledLink>
        );
      }
    } else {
      return <Image src={image.url} />;
    }
  };

  return <Promotion>{renderBody()}</Promotion>;
};

export default PromotionCard;
