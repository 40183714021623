import { BfFinanceNeeds } from "redux-models/reduxStateTypes";

export const mapApiToBfFinanceNeeds = (payload: any): BfFinanceNeeds => {
  if (!payload.blBackground) {
    return {
      financingAmount: undefined,
      financingNeed: undefined,
      averageMonthlySales: undefined,
      bankStatementBrokerFlowId: undefined,
    };
  }

  return {
    financingAmount: payload.financingAmount,
    financingNeed: payload.blBackground.financingNeed,
    averageMonthlySales: payload.blBackground.averageMonthlySales,
    bankStatementBrokerFlowId: payload.blBackground.bankStatementBrokerFlowId,
  };
};
