import { css } from "styled-components";

export type SpacingProps = {
  $marginRight?: string | number;
  $marginLeft?: string | number;
  $marginTop?: string | number;
  $marginBottom?: string | number;
};

const marginSpacing = css<SpacingProps>`
  margin-right: ${({ $marginRight }) => $marginRight};
  margin-left: ${({ $marginLeft }) => $marginLeft};
  margin-top: ${({ $marginTop }) => $marginTop};
  margin-bottom: ${({ $marginBottom }) => $marginBottom};
`;

export { marginSpacing };
