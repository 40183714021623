import {
  CLEAR_COMPANY_DATA,
  COMPANY_PREFILL_FAILURE,
  COMPANY_PREFILL_REQUEST,
  COMPANY_PREFILL_SUCCESS,
  CREATE_COMPANY_FAILURE,
  CREATE_COMPANY_REQUEST,
  CREATE_COMPANY_SUCCESS,
  GET_ALL_BROKER_COMPANIES_FAILURE,
  GET_ALL_BROKER_COMPANIES_REQUEST,
  GET_ALL_BROKER_COMPANIES_SUCCESS,
  GET_ALL_COMPANIES_FAILURE,
  GET_ALL_COMPANIES_REQUEST,
  GET_ALL_COMPANIES_SUCCESS,
} from "actions/types";
import { defineCompanyColumns } from "helpers/data-helpers";
import { mapApiErrorToStateLegacy } from "helpers/mappers";
import { companyLoadingColumns } from "utils/constants";
import { addPage, loadingStateLegacy } from "helpers/reducerHelpers";
import { Paging } from "redux-models/reduxStateTypes";

type Company = {
  id: string;
};

const initialState: any & Paging<Company> = {
  company: {},
  allCompanies: [],
  items: [],
  loading: false,
  source: null,
  success: null,
  error: null,
  firstError: null,
  message: null,
  pageNumber: 0,
  pageSize: 10,
  searchTerm: "",
  totalPages: 0,
  columns: companyLoadingColumns,
};

const company = (state = initialState, action: any) => {
  switch (action.type) {
    case CREATE_COMPANY_REQUEST:
      state.source && state.source.cancel();
      return {
        ...state,
        loading: true,
        firstError: null,
        source: action.payload,
      };

    case CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        company: action.payload,
        firstError: null,
      };
    case CREATE_COMPANY_FAILURE:
      return mapApiErrorToStateLegacy(action, state);
    case COMPANY_PREFILL_REQUEST:
      return loadingStateLegacy(state);
    case COMPANY_PREFILL_SUCCESS:
      return {
        ...state,
        company: action.payload,
        loading: false,
      };
    case COMPANY_PREFILL_FAILURE:
      return { ...state, error: { prefill: action.payload }, loading: false };
    case GET_ALL_COMPANIES_REQUEST:
      return { ...state, loading: true };
    case GET_ALL_COMPANIES_SUCCESS:
      return {
        ...state,
        allCompanies: action.payload.items,
        columns: defineCompanyColumns(action.payload.items),
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalPages: action.payload.totalPages,
        loading: false,
      };
    case GET_ALL_COMPANIES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case GET_ALL_BROKER_COMPANIES_REQUEST:
      return { ...state, loading: true };
    case GET_ALL_BROKER_COMPANIES_SUCCESS:
      const updated = addPage(state, action.payload);
      return {
        ...state,
        ...updated,
        columns: defineCompanyColumns(updated.items),
      };
    case GET_ALL_BROKER_COMPANIES_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case CLEAR_COMPANY_DATA:
      return initialState;

    default:
      return state;
  }
};

export default company;
