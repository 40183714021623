export function filterDuplicates<T>(
  item: T,
  index: number,
  array: T[]
): boolean {
  if (index === 0) {
    return true;
  }

  const indexOfItem = array.lastIndexOf(item, index - 1);

  return indexOfItem === -1;
}
