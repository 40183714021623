import isEmpty from "helpers/isEmpty";
import {
  AF_APP_NAME_COLUMN,
  AF_APP_DATE_CREATED_COLUMN,
  AF_APP_FINANCING_AMOUNT,
  AF_APP_DOC_TYPE_COLUMN,
  AF_APP_IS_SUBMITTED,
  AF_APP_BROKER_COLUMN,
} from "utils/constants";

/**
 * @description Takes an array of Asset Finance objects and returns a heading for
 * each expected column heading that is not blank.
 * @param {object[]} items - Array of pending Asset Finance objects.
 * @returns {string[]} - An array of column headings for use in a table display.
 */
export const defineAfApplicationColumns = (
  items: Array<{
    customerName: any;
    created: any;
    financingAmount: any;
    isFullDoc: any;
    brokerName: any;
    isSubmitted: any;
  }>
): string[] => {
  let columns: Array<string | null> = [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ];
  items.forEach((item) => {
    if (!isEmpty(item.customerName)) {
      columns[0] = AF_APP_NAME_COLUMN;
    }
    if (!isEmpty(item.created)) {
      columns[1] = AF_APP_DATE_CREATED_COLUMN;
    }
    if (!isEmpty(item.financingAmount)) {
      columns[2] = AF_APP_FINANCING_AMOUNT;
    }
    if (!isEmpty(item.isFullDoc)) {
      columns[3] = AF_APP_DOC_TYPE_COLUMN;
    }
    if (!isEmpty(item.brokerName)) {
      columns[4] = AF_APP_BROKER_COLUMN;
    }
    if (!isEmpty(item.isSubmitted)) {
      columns[5] = AF_APP_IS_SUBMITTED;
    }
  });

  // @ts-expect-error
  return columns.filter((col) => col !== null);
};
