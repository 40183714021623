import isEmpty from "helpers/isEmpty";

export const getFirstName = (name: string) => name.split(" ")[0];

export const cutOff = (word: string | null, length: number) => {
  if (isEmpty(word)) return "";
  if (isEmpty(length)) return word;
  if (word && word?.length < length) {
    return word;
  } else if (word?.length) {
    return word.slice(0, length - 3) + "...";
  } else {
    return "";
  }
};

export const abnFormat = (abn: string) =>
  abn.slice(0, 2) +
  " " +
  abn.slice(2, 5) +
  " " +
  abn.slice(5, 8) +
  " " +
  abn.slice(8);

/**
 *
 * @param {array} copyVariations
 * @param {string} condition
 * @returns {string}
 */
export const alternateText = (copyVariations: any[], condition: string) => {
  const copy = copyVariations.find(
    (variation) => variation.condition === condition
  );
  if (copy) {
    return copy.text;
  }
  return copyVariations[0].text;
};

export function toTitleCase(
  input: string,
  insertSpaces: boolean = false
): string {
  input = input.replace(/-/g, " ");

  let titleCased = input.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
  });

  if (insertSpaces) {
    titleCased = titleCased.replace(/([a-z])([A-Z])/g, "$1 $2");
  }

  return titleCased;
}
