import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { isStaff } from "helpers/role";
import { ReduxState } from "redux-models/reduxStateTypes";

const WelcomeBackContainer = styled.div`
  height: 27rem;
`;

const Text = styled.span<{ $isStaff: boolean }>`
  display: inline-block;
  font-size: 4.5rem;
  font-family: ${({ theme }) => theme.fontStyles.headings};
  color: ${({ theme, $isStaff }) =>
    $isStaff ? theme.colors.colorWhite : theme.colors.colorBlack};
  margin: 9rem 2rem 2rem 2rem;
`;

const WelcomeBack = () => {
  const { firstName, role } = useSelector(
    (state: ReduxState) => state.auth.account.idTokenClaims
  );
  return (
    <WelcomeBackContainer>
      <Text $isStaff={isStaff(role)}>Welcome Back {firstName}</Text>
    </WelcomeBackContainer>
  );
};

export default WelcomeBack;
