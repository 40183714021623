import info from "assets/icons/custom-icons/info-hover.svg";
import styled from "styled-components/macro";
import { ComponentProps } from "utils/component-props";

const Icon = styled.img<{ $marginLeft: number | string }>`
  height: 1.5rem;
  width: 1.5rem;
  margin-left: ${({ $marginLeft }) => $marginLeft};
  cursor: pointer;
`;

type IconProps = ComponentProps<typeof Icon>;

const InfoIcon = ({
  id,
  setShowTip,
  ...rest
}: {
  id: string;
  setShowTip: (id: string) => any;
} & IconProps) => {
  return <Icon src={info} id={id} {...rest} onClick={() => setShowTip(id)} />;
};

export { InfoIcon };
