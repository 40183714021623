const BROKER_VALUE = "broker";
const ADMIN_VALUE = "admin";
const MANAGER_VALUE = "manager";
const COMPANY_VALUE = "company";
const STAFF_VALUE = "staff";

const buildRoleDropdownArray = () => {
  return [
    { name: "Broker", value: BROKER_VALUE },
    { name: "Admin", value: ADMIN_VALUE },
    { name: "Manager", value: MANAGER_VALUE },
    { name: "Company", value: COMPANY_VALUE },
    { name: "Staff", value: STAFF_VALUE },
  ];
};

const clientArray = [BROKER_VALUE];

const scotpacArray = [ADMIN_VALUE, MANAGER_VALUE];

const roleDropdownArray = buildRoleDropdownArray();

const NAME_COLUMN = "Name";
const EMAIL_COLUMN = "Email";
const DATE_COLUMN = "Date";
const ROLE_COLUMN = "Role";
const STATE_COLUMN = "State";
const VERIFIED_COLUMN = "Verified";

const userColumns = [
  NAME_COLUMN,
  EMAIL_COLUMN,
  DATE_COLUMN,
  ROLE_COLUMN,
  STATE_COLUMN,
  VERIFIED_COLUMN,
];

export {
  roleDropdownArray,
  clientArray,
  scotpacArray,
  NAME_COLUMN,
  EMAIL_COLUMN,
  DATE_COLUMN,
  ROLE_COLUMN,
  STATE_COLUMN,
  VERIFIED_COLUMN,
  BROKER_VALUE,
  ADMIN_VALUE,
  MANAGER_VALUE,
  COMPANY_VALUE,
  userColumns,
  STAFF_VALUE,
};
