import { MedButton } from "components/common/buttons";
import { TextArea } from "components/common/inputs";
import { Error, OverallErrorContainer } from "components/common/inputs/styled";
import isEmpty from "helpers/isEmpty";
import PropTypes from "prop-types";
import { ChangeEventHandler } from "react";
import styled from "styled-components/macro";
import Modal from "./Modal";

const Title = styled.h3`
  font-size: 4rem;
  font-weight: 600;
  font-family: ${(props) => props.theme.fontStyles.headings};
  margin-bottom: 2rem;
`;

const Info = styled.p`
  font-size: 2rem;
  margin: 2rem auto;
  max-width: 70rem;
`;

const CenterContainer = styled.div<{ width?: any }>`
  max-width: ${(props) => props.width};
  margin: 4rem auto;
`;

const WarningModal = ({
  isOpen,
  closeModal,
  title,
  info,
  errorText,
  acceptAction,
  acceptText,
  declineAction,
  declineText,
  hasComment,
  comment,
  handleCommentChange,
  width,
}: {
  isOpen?: any;
  closeModal?: any;
  title?: any;
  info?: any;
  errorText?: any;
  acceptAction?: any;
  acceptText?: any;
  declineAction?: any;
  declineText?: any;
  hasComment?: any;
  comment?: any;
  handleCommentChange?: ChangeEventHandler<HTMLTextAreaElement>;
  width?: any;
}) => {
  return (
    <Modal isOpen={isOpen} closeModal={closeModal} modalType="warning">
      <CenterContainer width={width}>
        {title && <Title>{title}</Title>}
        <Info>{info}</Info>
        {!isEmpty(errorText) ? (
          <OverallErrorContainer
            style={{
              marginTop: "2rem",
              marginBottom: "2rem",
              textAlign: "center",
            }}
          >
            <Error>{errorText}</Error>
          </OverallErrorContainer>
        ) : null}
        {hasComment && (
          <TextArea
            placeholder="Comment"
            value={comment}
            name="comment"
            change={handleCommentChange}
            error={null}
            text="Comment"
          />
        )}
        <MedButton
          width={20}
          $marginRight="2rem"
          onClick={acceptAction}
          $primary
        >
          {acceptText}
        </MedButton>
        <MedButton width={20} onClick={declineAction} $outlineLightGrayBlue>
          {declineText}
        </MedButton>
      </CenterContainer>
    </Modal>
  );
};

WarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
};

export { WarningModal };
