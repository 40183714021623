import styled from "styled-components/macro";

export const Overlay = styled.div<{ active: boolean }>`
  ${(props) =>
    props.active &&
    `
    transition: all 0.2s cubic-bezier(0.37, 0, 0.63, 1);
    position: fixed;
    z-index: 5;
    right: 0;
    left: 0;
    bottom: 0;
    top: 70px;
    background: rgba(0, 0, 0, 0.6);
  `}
`;
