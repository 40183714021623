import { HttpResponseAction } from "utils/api";

export type FastDocRecommendationReduxState = {
  fastDocRecommendations: FastDocRecommendation[];
};

export type FastDocRecommendation = {
  field: string;
  message: string;
};

export const getFastDocRecommendationStateFromResponse = (
  action?: HttpResponseAction
): Partial<FastDocRecommendationReduxState> => {
  // if response does not provide fast doc errors, ignore
  if (action?.payload?.state === undefined) {
    return {};
  }

  return {
    fastDocRecommendations: action.payload.state.recommendations ?? [],
  };
};

export const isFastDocRecommendation = (
  action?: HttpResponseAction
): boolean => {
  return action?.payload?.state?.isValid === false;
};
