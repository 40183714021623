import { Dispatch } from "@reduxjs/toolkit";
import { ApiDispatch } from "actions/apiDispatch";
import {
  BULK_INVITE_FAILURE,
  BULK_INVITE_REQUEST,
  BULK_INVITE_SUCCESS,
  CALL_API,
  CLEAR_USER_DATA,
  CLEAR_USER_ERROR,
  CLEAR_USER_SUCCESS,
  FAIL_USER_FAILURE,
  FAIL_USER_REQUEST,
  FAIL_USER_SUCCESS,
  GET_BDMS_FAILURE,
  GET_BDMS_REQUEST,
  GET_BDMS_SUCCESS,
  GET_USERS_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  INVITE_BROKER_FAILURE,
  INVITE_BROKER_REQUEST,
  INVITE_BROKER_SUCCESS,
  REFER_CLIENT_FAILURE,
  REFER_CLIENT_REQUEST,
  REFER_CLIENT_SUCCESS,
  SET_USER_ONBOARDING,
  SUSPEND_USER_FAILURE,
  SUSPEND_USER_REQUEST,
  SUSPEND_USER_SUCCESS,
  UPDATE_COMPANY_FAILURE,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPLOAD_COMPANY_IMAGE_FAILURE,
  UPLOAD_COMPANY_IMAGE_REQUEST,
  UPLOAD_COMPANY_IMAGE_SUCCESS,
  UPLOAD_USER_IMAGE_FAILURE,
  UPLOAD_USER_IMAGE_REQUEST,
  UPLOAD_USER_IMAGE_SUCCESS,
  VERIFY_USER_FAILURE,
  VERIFY_USER_REQUEST,
  VERIFY_USER_SUCCESS,
  ADD_INVITE_BROKER_REQUEST,
  ADD_INVITE_BROKER_SUCCESS,
  ADD_INVITE_BROKER_FAILURE,
  CLEAR_INVITED_BROKER,
} from "actions/types";
import isEmpty from "lodash/isEmpty";

export const clearUserData = () => async (dispatch: Dispatch) =>
  dispatch({ type: CLEAR_USER_DATA });

export const clearUserError = () => async (dispatch: Dispatch) =>
  dispatch({ type: CLEAR_USER_ERROR });

export const clearUserSuccess = () => async (dispatch: Dispatch) =>
  dispatch({ type: CLEAR_USER_SUCCESS });

export const setUserOnboarding = () => async (dispatch: Dispatch) =>
  dispatch({
    type: SET_USER_ONBOARDING,
  });

export const clearCreatedBroker = () => async (dispatch: Dispatch) =>
  dispatch({ type: CLEAR_INVITED_BROKER });

/**
 * @route /users/{userId}/clients
 * @method POST
 * @desc refer a client
 * @param {string} userId
 * @param {object} formData
 * @param {string} formData.firstName
 * @param {string} formData.lastName
 * @param {string} formData.companyName
 * @param {string} formData.email
 * @param {string} formData.mobile
 * @param {string} formData.industry
 * @param {string} formData.comment
 */
export const referClient = (userId: string, formData: any) => async (
  dispatch: ApiDispatch
) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/clients`,
      method: "POST",
      body: {
        ...formData,
      },
      types: [REFER_CLIENT_REQUEST, REFER_CLIENT_SUCCESS, REFER_CLIENT_FAILURE],
    },
  });

/**
 * @route /users/invite
 * @method POST
 * @desc invite a broker
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} email
 * @param {string} role
 * @param {string} companyId
 */
export const inviteBroker = (
  firstName: string,
  lastName: string,
  email: string,
  role: string | undefined,
  companyId: string
) => async (dispatch: ApiDispatch) => {
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/invite`,
      method: "POST",
      body: {
        firstName,
        lastName,
        email,
        role,
        companyId,
      },
      types: [
        INVITE_BROKER_REQUEST,
        INVITE_BROKER_SUCCESS,
        INVITE_BROKER_FAILURE,
      ],
    },
  });
};

/**
 * @route /users/:userId/bulkInvite
 * @method POST
 * @desc invite many brokers with a csv
 * @param {string} userId
 * @param {FormData} formData
 */
export const bulkInviteBroker = (userId: string, formData: any) => async (
  dispatch: ApiDispatch
) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/bulkInvite`,
      method: "POST",
      body: formData,
      types: [BULK_INVITE_REQUEST, BULK_INVITE_SUCCESS, BULK_INVITE_FAILURE],
    },
  });

/**
 * @route /bdms
 * @method GET
 * @desc gets all the bdms
 */
export const getBdms = () => async (dispatch: ApiDispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/bdms`,
      method: "GET",
      types: [GET_BDMS_REQUEST, GET_BDMS_SUCCESS, GET_BDMS_FAILURE],
    },
  });

/**
 * @route /users/{userId}
 * @method GET
 * @desc get the information of a single user
 * @param {string} userId
 */
export const getUser = (userId: string) => async (dispatch: ApiDispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}`,
      method: "GET",
      types: [GET_USER_REQUEST, GET_USER_SUCCESS, GET_USER_FAILURE],
    },
  });

export const getUsers = ({
  itemsPerPage,
  page,
  isVerified,
  term,
  role,
  isFailed,
  isSuspended,
  cid,
  includeCompany = false,
}: {
  itemsPerPage: number | undefined;
  page: number | string | undefined;
  isVerified: boolean | string | undefined;
  term: string[] | string | undefined;
  role: string | string[] | undefined;
  isFailed: boolean | string | undefined;
  isSuspended: boolean | string | undefined;
  cid?: string;
  includeCompany?: boolean;
}) => async (dispatch: ApiDispatch) => {
  return dispatch({
    [CALL_API]: {
      cancellable: true,
      endpoint: `/v1/users`,
      method: "GET",
      query: {
        count: itemsPerPage,
        page: page,
        isVerified: isVerified,
        isSuspended: isSuspended,
        isFailed: isFailed,
        term,
        role,
        cid,
        includeCompany: includeCompany,
      },
      types: [GET_USERS_REQUEST, GET_USERS_SUCCESS, GET_USERS_FAILURE],
    },
  });
};

/**
 * @route /users/{userId}/verify
 * @method POST
 * @desc verify a single user
 * @param {string} userId
 * @param {string} comment
 */
export const verifyUser = (userId: string, comment: string) => async (
  dispatch: ApiDispatch
) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/verify`,
      method: "POST",
      body: { comment },
      types: [VERIFY_USER_REQUEST, VERIFY_USER_SUCCESS, VERIFY_USER_FAILURE],
    },
  });

/**
 * @route /users/{userId}/fail
 * @method POST
 * @desc verify a single user
 * @param {string} userId
 * @param {string} comment
 */
export const failUser = (userId: string, comment: string) => async (
  dispatch: ApiDispatch
) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/fail`,
      method: "POST",
      body: { comment },
      types: [FAIL_USER_REQUEST, FAIL_USER_SUCCESS, FAIL_USER_FAILURE],
    },
  });

/**
 * @route /users/{userId}/suspend
 * @method POST
 * @desc suspend a single user
 * @param {string} userId
 * @param {string} comment
 */
export const suspendUser = (userId: string, comment: string) => async (
  dispatch: ApiDispatch
) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/suspend`,
      method: "POST",
      body: { comment },
      types: [SUSPEND_USER_REQUEST, SUSPEND_USER_SUCCESS, SUSPEND_USER_FAILURE],
    },
  });

/**
 * @route /users/{userId}
 * @method PATCH
 * @desc suspend a single user
 * @param {object} userDetails
 * @param {string} userId
 * @param {string} userDetails.role
 */
export const updateUserRole = ({
  userId,
  role,
}: {
  userId: any;
  role: any;
}) => async (dispatch: ApiDispatch) => {
  return dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}`,
      method: "PATCH",
      body: { role },
      types: [UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE],
    },
  });
};

/**
 * @route /users/{userId}/company
 * @method PATCH
 * @desc suspend a single user
 * @param {string} userId
 * @param {object} companyDetails
 * @param {string} companyDetails.name
 * @param {string} companyDetails.contactNumber
 * @param {object} companyDetails.address
 * @param {string} companyDetails.aggregator
 * @param {string} companyDetails.address.line1
 * @param {string} companyDetails.address.line2
 * @param {string} companyDetails.address.line3
 * @param {string} companyDetails.address.suburb
 * @param {string} companyDetails.address.state
 * @param {string} companyDetails.address.postcode
 * @param {string} companyDetails.logoId
 * @param {string} companyDetails.about
 * @param {string} companyDetails.timezone
 */
export const updateCompany = (userId: string, companyDetails: any) => async (
  dispatch: ApiDispatch
) => {
  const body = { ...companyDetails };
  Object.keys(body).forEach(
    (key) => body[key] === undefined && delete body[key]
  );
  return dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/company`,
      method: "PATCH",
      body: body,
      types: [
        UPDATE_COMPANY_REQUEST,
        UPDATE_COMPANY_SUCCESS,
        UPDATE_COMPANY_FAILURE,
      ],
    },
  });
};

/**
 * @route /logos/companies/{companyId}
 * @method POST
 * @desc upload an image to a company
 * @param {string} companyId
 * @param {FormData} formData
 */
export const uploadCompanyLogo = (companyId: string, formData: any) => async (
  dispatch: ApiDispatch
) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/logos/companies/${companyId}`,
      method: "POST",
      body: formData,
      types: [
        UPLOAD_COMPANY_IMAGE_REQUEST,
        UPLOAD_COMPANY_IMAGE_SUCCESS,
        UPLOAD_COMPANY_IMAGE_FAILURE,
      ],
      cancellable: true,
    },
  });

/**
 * @route /logos/companies/{companyId}
 * @method POST
 * @desc upload an image to a company
 * @param {string} companyId
 * @param {string} userId
 * @param {FormData} formData
 * @param {object} companyDetails
 * @param {string} companyDetails.name
 * @param {string} companyDetails.contactNumber
 * @param {object} companyDetails.address
 * @param {string} companyDetails.aggregator
 * @param {string} companyDetails.address.line1
 * @param {string} companyDetails.address.line2
 * @param {string} companyDetails.address.line3
 * @param {string} companyDetails.address.suburb
 * @param {string} companyDetails.address.state
 * @param {string} companyDetails.address.postcode
 * @param {string} companyDetails.logoId
 * @param {string} companyDetails.about
 * @param {string} companyDetails.timezone
 */
export const uploadLogoAndCompany = (
  companyId: string,
  userId: string,
  formData: any,
  companyDetails: any
) => async (dispatch: ApiDispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/logos/companies/${companyId}`,
      method: "POST",
      body: formData,
      types: [
        UPLOAD_COMPANY_IMAGE_REQUEST,
        UPLOAD_COMPANY_IMAGE_SUCCESS,
        UPLOAD_COMPANY_IMAGE_FAILURE,
      ],
      cancellable: true,
    },
  }).then((res) => {
    if (!isEmpty(res)) {
      const body = {
        ...companyDetails,
        logoId: res.payload.id,
      };
      Object.keys(body).forEach(
        (key) => body[key] === undefined && delete body[key]
      );
      return dispatch({
        [CALL_API]: {
          endpoint: `/v1/users/${userId}/company`,
          method: "PATCH",
          body: body,
          types: [
            UPDATE_COMPANY_REQUEST,
            UPDATE_COMPANY_SUCCESS,
            UPDATE_COMPANY_FAILURE,
          ],
        },
      });
    }
  });

/**
 * @route /users/{userId}
 * @method PATCH
 * @desc suspend a single user
 * @param {object} userDetails
 * @param {string} userId
 * @param {FormData} profileImage
 * @param {FormData} signature
 * @param {string} userDetails.firstName
 * @param {string} userDetails.lastName
 * @param {string} userDetails.mobile
 * @param {string} userDetails.role
 * @param {object} profileDetails
 * @param {boolean} profileDetails.notifications
 * @param {string} profileDetails.title
 * @param {string} profileDetails.websiteUrl
 * @param {string} profileDetails.blogUrl
 * @param {string} profileDetails.linkedInUrl
 * @param {string} profileDetails.twitterUrl
 * @param {string} profileDetails.facebookUrl
 * @param {string} profileDetails.youTubeUrl
 */
export const uploadImagesAndUpdateUserDetails = (
  userId: string,
  userDetails: any,
  profileDetails: any,
  profileImage: any,
  signature?: any
) => async (dispatch: ApiDispatch) => {
  let profileImageResponse;
  let signatureResponse;

  if (profileImage) {
    profileImageResponse = await dispatch({
      [CALL_API]: {
        endpoint: `/v1/users/${userId}/images/profile`,
        method: "POST",
        body: profileImage,
        types: [
          UPLOAD_USER_IMAGE_REQUEST,
          UPLOAD_USER_IMAGE_SUCCESS,
          UPLOAD_USER_IMAGE_FAILURE,
        ],
      },
    });
  }

  if (signature) {
    signatureResponse = await dispatch({
      [CALL_API]: {
        endpoint: `/v1/users/${userId}/images/signature`,
        method: "POST",
        body: signature,
        types: [
          UPLOAD_USER_IMAGE_REQUEST,
          UPLOAD_USER_IMAGE_SUCCESS,
          UPLOAD_USER_IMAGE_FAILURE,
        ],
      },
    });
  }

  if (
    (!signature || signatureResponse) &&
    (!profileImage || profileImageResponse)
  ) {
    const body = {
      ...userDetails,
      profile: {
        ...profileDetails,
        signatureImageId: signatureResponse?.payload?.id,
        profilePhotoId: profileImageResponse?.payload?.id,
      },
    };
    Object.keys(body).forEach((key) => isEmpty(body[key]) && delete body[key]);

    return dispatch({
      [CALL_API]: {
        endpoint: `/v1/users/${userId}`,
        method: "PATCH",
        body: body,
        types: [UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE],
      },
    });
  }
};

/**
 * @route /broker
 * @method POST
 * @desc Add and invite a broker
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} email
 * @param {string} userId
 * @param {string} role
 * @param {string} companyName
 * @param {string} abn
 * @param {string} registrationDate
 * @param {string} contactNumber
 * @param {string} aggregator
 * @param {object} address
 * @param {string} address.line1
 * @param {string} address.line2
 * @param {string} address.line3
 * @param {string} address.suburb
 * @param {string} address.state
 * @param {string} address.postcode
 */
export const addInviteBroker = (addInviteBroker: any) => async (
  dispatch: ApiDispatch
) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/broker`,
      method: "POST",
      body: addInviteBroker,
      types: [
        ADD_INVITE_BROKER_REQUEST,
        ADD_INVITE_BROKER_SUCCESS,
        ADD_INVITE_BROKER_FAILURE,
      ],
    },
  });
