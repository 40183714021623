import React, { FC } from "react";
import {
  NavigateFunction,
  Params,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

export type WithRouteProps = {
  router: {
    location: Location;
    navigage: NavigateFunction;
    params: Readonly<Params<string>>;
  };
};

export function withRouter<T extends WithRouteProps>(
  Component: React.ComponentType<T>
): FC<Omit<T, "router">> {
  function ComponentWithRouterProp(props: Omit<T, "router">) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    // @ts-expect-error
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}
