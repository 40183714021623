import * as React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

interface IButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  text?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  width: number;
  $coral?: boolean;
  $marginLeft?: string;
  $marginRight?: string;
  $marginTop?: string;
  $marginBottom?: string;
  $outlinePrimary?: boolean;
  $primary?: boolean;
  $outlineLightGrayBlue?: boolean;
  $radius?: string;
}

interface ILink {
  disabled?: boolean;
  $outline?: boolean;
}

interface ILinkProps extends ILink, IButtonProps {}

const medBtnStyles = css<IButtonProps>`
  display: inline-block;
  width: ${({ width }) => `${width}rem`};
  height: 4rem;
  border-radius: ${({ $radius }) => ($radius ? $radius : "20px")};
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSizes.nav};
  text-decoration: none;
  text-align: center;
  line-height: 4rem;
  outline: none;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.colorLightGrayBlue};
    color: ${(props) => props.theme.colors.colorWhite};
    border: none;

    &:hover {
      background-color: ${(props) => props.theme.colors.colorLightGrayBlue};
      color: ${(props) => props.theme.colors.colorWhite};
      cursor: auto;
    }
  }

  margin-right: ${({ $marginRight }) => $marginRight};
  margin-left: ${({ $marginLeft }) => $marginLeft};
  margin-top: ${({ $marginTop }) => $marginTop};
  margin-bottom: ${({ $marginBottom }) => $marginBottom};

  ${({ $primary }) =>
    $primary &&
    css`
      background-color: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.colorWhite};
      border: none;

      &:hover {
        background-color: ${(props) => props.theme.colors.primaryActive};
      }
    `}

  ${({ $outlinePrimary }) =>
    $outlinePrimary &&
    css`
      background-color: inherit;
      color: ${(props) => props.theme.colors.primary};
      border: 2px solid ${(props) => props.theme.colors.primary};

      &:hover {
        background-color: ${(props) => props.theme.colors.buttonHover};
      }
    `}

  ${({ $coral }) =>
    $coral &&
    css`
      background-color: ${(props) => props.theme.colors.secondaryCoral};
      color: ${(props) => props.theme.colors.colorWhite};
      border: none;

      &:hover {
        background-color: ${(props) => props.theme.colors.coralActive};
      }
    `}

  ${({ $outlineLightGrayBlue }) =>
    $outlineLightGrayBlue &&
    css`
      background-color: inherit;
      border: 2px solid ${(props) => props.theme.colors.colorLightGrayBlue};
      color: ${(props) => props.theme.colors.colorLightGrayBlue};

      &:hover {
        background-color: ${(props) => props.theme.colors.buttonHover};
      }
    `}
`;

const linkStyles = css<ILink>`
  ${({ disabled, $outline }) => {
    if (disabled && $outline) {
      return css`
        pointer-events: none;
        background-color: inherit;
        color: ${(props) => props.theme.colors.colorLightGrayBlue};
        border: 2px solid ${(props) => props.theme.colors.colorLightGrayBlue};

        &:hover {
          background-color: ${(props) => props.theme.colors.colorLightGrayBlue};
          color: ${(props) => props.theme.colors.colorWhite};
          cursor: auto;
        }
      `;
    }
    return (
      disabled &&
      css`
        pointer-events: none;
        background-color: ${(props) => props.theme.colors.colorLightGrayBlue};
        color: ${(props) => props.theme.colors.colorWhite};

        &:hover {
          background-color: ${(props) => props.theme.colors.colorLightGrayBlue};
          color: ${(props) => props.theme.colors.colorWhite};
          cursor: auto;
        }
      `
    );
  }}
`;

const MedLinkButton = styled(Link)<ILinkProps>`
  ${medBtnStyles}
  ${linkStyles}
`;

const MedAButton = styled.a<ILinkProps>`
  ${medBtnStyles}
  ${linkStyles}
`;

const MedButton = styled.button<IButtonProps>`
  ${medBtnStyles}
`;

export { MedLinkButton, MedButton, MedAButton, medBtnStyles };
