import qs from "query-string";
import axios from "axios";
import { CALL_API } from "actions/types";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { FullUnion } from "utils/fullUnion";

export type SuccessAction = {
  type: string;
  payload: any;
};

export type ErrorOrRecommendationActionWithData = {
  type: string;

  // Payload is the response data
  payload: Record<string, any>;
  statusCode: number;
};

export type ErrorOrRecommendationActionWithoutData = {
  type: string;
  payload: {
    statusCode: number;
    statusText: string;
    message: string;
  };
};

export type CancelledRequestErrorAction = {
  type: string;
  payload: { message?: string };
  isCancelledRequest: true;
};

export type HttpErrorAction = FullUnion<
  FullUnion<
    ErrorOrRecommendationActionWithData,
    ErrorOrRecommendationActionWithoutData
  >,
  CancelledRequestErrorAction
>;

export type HttpResponseAction = FullUnion<HttpErrorAction, SuccessAction>;

const api = (store: { dispatch: Dispatch }) => (next: (_: any) => any) => (
  action: any
) => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === "undefined") {
    return next(action);
  }
  // TODO: fix cancelable typo
  // Get the data of the call
  const {
    endpoint,
    method,
    body,
    query,
    headers = {},
    onUploadProgress,
    types,
    cancellable = false,
  } = callAPI;

  // Define the x-api-key
  headers["x-api-key"] = process.env.REACT_APP_X_API_KEY;

  // Define the final action
  const actionWith = (data: any) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  // set cancel token
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  // Send the request to the next middleware
  const [requestType, successType, failureType] = types;

  if (cancellable) {
    next(actionWith({ type: requestType, payload: source }));
  } else {
    next(actionWith({ type: requestType }));
  }

  let handleUploadProgress: undefined | ((event: any) => void);
  if (onUploadProgress) {
    handleUploadProgress = (progressEvent: any) => {
      store.dispatch(onUploadProgress(progressEvent));
    };
  } else {
    handleUploadProgress = undefined;
  }

  let queryString = "";
  if (query) {
    queryString = `?${qs.stringify(query)}`;
  }

  const builtUrl = `${process.env.REACT_APP_URL_API}${endpoint}${queryString}`;

  return axios({
    method,
    url: builtUrl,
    headers,
    onUploadProgress: handleUploadProgress,
    data: body,
    cancelToken: source.token,
  })
    .then(createFSAConverter(successType, failureType, store.dispatch, body))
    .catch((err) => createErrorResponse(err, store, failureType));
};

const createErrorResponse = (
  err: { response: { data: any; status: any; statusText: any } },
  store: { dispatch: any },
  failureType: any
) => {
  if (!axios.isCancel(err)) {
    if (err.response) {
      if (err.response.data) {
        const errorAction: ErrorOrRecommendationActionWithData = {
          payload: err.response.data,
          statusCode: err.response.status,
          type: failureType,
        };
        store.dispatch(errorAction);
      } else {
        const errorAction: ErrorOrRecommendationActionWithoutData = {
          payload: {
            statusCode: err.response.status,
            statusText: err.response.statusText,
            message: err.response.statusText,
          },
          type: failureType,
        };
        store.dispatch(errorAction);
      }
    }
  } else {
    store.dispatch({
      payload: err,
      type: failureType,
      isCancelledRequest: true,
    });
  }
};

const createFSAConverter = (
  successType: any,
  failureType: any,
  dispatch: Dispatch<AnyAction>,
  body: any
) => async (response: {
  statusText: string | number;
  status: number;
  headers: { [x: string]: any };
  data: any;
}) => {
  const contentType = response.headers["content-type"];
  const emptyCodes = [204, 205];

  // define the success type for the request
  const createSuccessType = (payload: any) =>
    dispatch({
      payload,
      type: successType,
    });

  // check if the request was successful and something was send back from api
  if (
    // @ts-expect-error
    emptyCodes.indexOf(response.statusText) === -1 &&
    contentType &&
    contentType.indexOf("json") !== -1
  ) {
    return createSuccessType(response.data);
  } else if (response.data) {
    return createSuccessType(response.data);
  }
  return createSuccessType(body);
};

export default api;
