import { ApiDispatch } from "actions/apiDispatch";
import {
  ACCEPT_TERMS_FAILURE,
  ACCEPT_TERMS_REQUEST,
  ACCEPT_TERMS_SUCCESS,
  CALL_API,
} from "actions/types";

/**
 * @route /users/{userId}/terms
 * @method POST
 * @desc accept terms
 * @param {string} userId
 */
export const acceptTerms = (userId: string) => async (dispatch: ApiDispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/terms`,
      method: "POST",
      types: [ACCEPT_TERMS_REQUEST, ACCEPT_TERMS_SUCCESS, ACCEPT_TERMS_FAILURE],
    },
  });
