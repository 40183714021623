import {
  IconSuccessUpload,
  LoadingIcon,
  UploadContainer,
  UploadImageContainer,
  UploadText,
  UploadTextContainer,
  UploadTextError,
} from "components/common/financing-forms/create-asset-finance.styles";
import successTick from "assets/icons/custom-icons/icon-success-tick.svg";

export interface IUploadedFile {
  error?: string;
  name: string;
  uploaded: boolean;
}

export const UploadedFile = ({ file }: { file: IUploadedFile }) => {
  return (
    <UploadContainer>
      {!file.error ? (
        <>
          <UploadTextContainer>
            <UploadText>{file.name}</UploadText>
          </UploadTextContainer>
          <UploadImageContainer>
            {file.uploaded ? (
              <IconSuccessUpload src={successTick} />
            ) : (
              <LoadingIcon />
            )}
          </UploadImageContainer>
        </>
      ) : (
        <UploadTextContainer>
          <UploadText>{file.name}</UploadText>
          <UploadTextError color={"red"}>{file.error}</UploadTextError>
        </UploadTextContainer>
      )}
    </UploadContainer>
  );
};
