import React from "react";
import {
  Name,
  Divider,
  Text,
  Center,
  Title,
  InfoContainer,
  Icon,
  Container,
} from "./ComingSoonOverlay.styled";

const ComingSoonOverlay = ({ name, show }) => {
  return (
    <Container show={show}>
      <Center>
        <Icon />
        <InfoContainer>
          <Title>Coming Soon</Title>
          <Text>We'll keep you up to date</Text>
        </InfoContainer>
        <Divider />
        <Name>{name}</Name>
      </Center>
    </Container>
  );
};

export { ComingSoonOverlay };
