import { AddressType } from "components/asset-finance/common/address-types";
import {
  Address,
  AssetFinanceBackground,
  AssetFinanceBackgroundResponse,
  AssetFinanceForm,
  ClientDetailsFormStructures,
  Association,
  Company,
  Contact,
  CoOp,
  Director,
  Foreign,
  Government,
  Guarantor,
  GuarantorType,
  Individual,
  Officer,
  Partnership,
  SoleTrader,
  Trust,
} from "redux-models/reduxStateTypes";
import { handleAddress } from "helpers/tableHelpers";

export enum CustomerStructure {
  private = "private",
  public = "public",
  foreign = "foreign",
  sole = "sole",
  partnership = "partnership",
  trust = "trust",
  assoc = "assoc",
  coop = "coop",
  gov = "gov",
}

type CustomerResponse = {
  id: string;
  structure: CustomerStructure;
  name: string;
  introducerId?: string;
  company?: CompanyResponse;
  soleTrader?: SoleTraderResponse;
  foreignCompany?: ForeignResponse;
  partnership?: PartnershipResponse;
  trust?: TrustResponse;
  government?: GovResponse;
  association?: AssocResponse;
  cooperative?: CoopResponse;
  guarantors?: GuarantorResponse[];
};

type CompanyResponse = {
  id: string;
  name: string;
  abn: string;
  acn: string;
  registration: string;
  gstRegistration?: string;
  employees?: string;
  industry: string;
  website?: string;
  contactNumber: string;
  addresses: AddressResponse[];
  directors?: DirectorResponse[];
};

type SoleTraderResponse = {
  id: string;
  name: string;
  abn: string;
  tradingName?: string;
  registration: string;
  gstRegistration?: string;
  industry: string;
  website?: string;
  individual?: IndividualResponse;
  address: AddressResponse;
};

type ForeignResponse = {
  id: string;
  name: string;
  arbn: string;
  isPrivate: boolean;
  localAgent?: string;
  country: string;
  addresses: AddressResponse[];
  directors: DirectorResponse[];
};

type PartnershipResponse = {
  id: string;
  name: string;
  businessName?: string;
  abn: string;
  registration: string;
  gstRegistration?: string;
  companies?: CompanyResponse[];
  individuals?: IndividualResponse[];
  // Not in scope: trusts: TrustResponse[];
  soleTraders?: SoleTraderResponse[];
};

type TrustResponse = {
  id: string;
  type: string;
  name: string;
  businessName?: string;
  abn: string;
  registration: string;
  gstRegistration?: string;
  companies?: CompanyResponse[];
  individuals?: IndividualResponse[];
};

type GovResponse = {
  id: string;
  abn: string;
  name: string;
  type: string;
  typeName: string;
  contact?: ContactResponse;
  address: AddressResponse;
};

type CoopResponse = {
  id: string;
  name: string;
  abn: string;
  orgNumber: string;
  officers?: OfficerResponse[];
  addresses: AddressResponse[];
};

type AssocResponse = {
  id: string;
  name: string;
  abn: string;
  isIncorporated: boolean;
  incNumber?: string;
  incBody?: string;
  individual?: IndividualResponse;
  officers?: OfficerResponse[];
  addresses: AddressResponse[];
};

type GuarantorResponse = {
  id: string;
  type: GuarantorType;
  name: string;
  abn?: string;
  acn?: string;
  trusteeName?: string;
  individual?: IndividualResponse;
  contact?: ContactResponse;
  address?: AddressResponse;
};

type AddressResponse = {
  id: string;
  type: AddressType;
  name: string;
  country: string;
  line1: string;
  line2?: string;
  suburb: string;
  postcode: string;
  state: string;
};

type DirectorResponse = {
  id: string;
  isDirector: boolean;
  isShareholder: boolean;
  isOwner: boolean;
  isPrimaryContact: boolean;
  individual: IndividualResponse;
};

type IndividualResponse = {
  id: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  phone: string;
  idType?: string;
  idNumber?: string;
  dateOfBirth: string;
  address: AddressResponse;
};

type ContactResponse = {
  id: string;
  fullName: string;
  email: string;
  phone: string;
  position: string;
};

type OfficerResponse = {
  id: string;
  type: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  dateOfBirth: string;
};

export const Merge = {
  Business: 1, // Preserves Client Details and Guarantors
  Clients: 2, // Preserves Business Details and Guarantors
  All: 3, // Overwrites Business Details, Client Details and Guarantors
} as const;

export type MergeFlag = typeof Merge[keyof typeof Merge];

export const mapApiToReduxCustomerDetails = ({
  resp,
  state,
  strategy = Merge.All,
}: {
  resp: CustomerResponse;
  state?: AssetFinanceForm;
  strategy?: MergeFlag;
}): Partial<AssetFinanceForm> => {
  // Could turn this into a loop, but would have similar code to set up the mapping of structure to parameters
  const structures: ClientDetailsFormStructures = {
    company: mergeMap({
      resp: resp.company,
      state: state?.company,
      strategy: strategy,
      mapBusiness: mapCompanyBusiness,
      mapClients: mapCompanyClients,
    }),
    foreign: mergeMap({
      resp: resp.foreignCompany,
      state: state?.foreign,
      strategy: strategy,
      mapBusiness: mapForeignBusiness,
      mapClients: mapForeignClients,
    }),
    sole: mergeMap({
      resp: resp.soleTrader,
      state: state?.sole,
      strategy: strategy,
      mapBusiness: mapSoleBusiness,
      mapClients: mapSoleClients,
    }),
    partnership: mergeMap({
      resp: resp.partnership,
      state: state?.partnership,
      strategy: strategy,
      mapBusiness: mapPartnershipBusiness,
      mapClients: mapPartnershipClients,
    }),
    trust: mergeMap({
      resp: resp.trust,
      state: state?.trust,
      strategy: strategy,
      mapBusiness: mapTrustBusiness,
      mapClients: mapTrustClients,
    }),
    gov: mergeMap({
      resp: resp.government,
      state: state?.gov,
      strategy: strategy,
      mapBusiness: mapGovBusiness,
      mapClients: mapGovClients,
    }),
    assoc: mergeMap({
      resp: resp.association,
      state: state?.assoc,
      strategy: strategy,
      mapBusiness: mapAssocBusiness,
      mapClients: mapAssocClients,
    }),
    coop: mergeMap({
      resp: resp.cooperative,
      state: state?.coop,
      strategy: strategy,
      mapBusiness: mapCoopBusiness,
      mapClients: mapCoopClients,
    }),
  };
  switch (strategy) {
    case Merge.Clients:
      return structures;
    case Merge.Business:
      return {
        structure: resp.structure,
        selectedCustomerId: resp.id,
        customerName: resp.name,
        ...structures,
      };
    case Merge.All:
      return {
        structure: resp.structure,
        selectedCustomerId: resp.id,
        customerName: resp.name,
        ...structures,
        guarantors: mapGuarantorsResponse(resp.guarantors),
      };
    default:
      return {};
  }
};

export const mapGuarantorsResponse = (
  resp?: GuarantorResponse[]
): Guarantor[] => {
  return resp?.map((g) => mapGuarantor(g)) ?? [];
};

const mergeMap = <S, R, K extends keyof S>({
  resp,
  state,
  strategy,
  mapBusiness,
  mapClients,
}: {
  resp?: R;
  state?: S;
  strategy: number;
  mapBusiness: (resp: R) => Omit<S, K>;
  mapClients: (resp: R) => Pick<S, K>;
}): Partial<S> => {
  let merged = state ?? {};
  if (!resp) return merged;
  if ((strategy & Merge.Business) === Merge.Business) {
    merged = {
      ...merged,
      ...mapBusiness(resp),
    };
  }
  if ((strategy & Merge.Clients) === Merge.Clients) {
    merged = {
      ...merged,
      ...mapClients(resp),
    };
  }
  return merged;
};

const mapCompanyBusiness = (
  resp: CompanyResponse
): Omit<Company, "directors"> => {
  const { directors, ...state } = {
    ...resp,
    gstRegistration: resp.gstRegistration || "",
    website: resp.website || "",
    addresses: resp.addresses.map((a) => mapAddress(a)),
    abnLookup: "", // FIXME: this should be local to the component
    tradingName: "", // TODO: Investigate why this is in frontend but not backend
  };
  return state;
};

const mapCompanyClients = (
  resp: CompanyResponse
): Pick<Company, "directors"> => {
  return {
    directors: resp.directors?.map((d) => mapDirector(d)) ?? [],
  };
};

const mapCompanyBoth = (resp: CompanyResponse): Company => {
  return {
    ...mapCompanyBusiness(resp),
    ...mapCompanyClients(resp),
  };
};

const mapForeignBusiness = (
  resp: ForeignResponse
): Omit<Foreign, "directors"> => {
  const { directors, ...state } = {
    ...resp,
    localAgent: resp.localAgent || "",
    addresses: resp.addresses.map((a) => mapAddress(a)),
  };
  return state;
};

const mapForeignClients = (
  resp: ForeignResponse
): Pick<Foreign, "directors"> => {
  return {
    directors: resp.directors.map((d) => mapDirector(d)),
  };
};

const mapSoleBusiness = (
  resp: SoleTraderResponse
): Omit<SoleTrader, "individual"> => {
  const { individual, ...state } = {
    ...resp,
    tradingName: resp.tradingName || "",
    gstRegistration: resp.gstRegistration || "",
    website: resp.website || "",
    addresses: [mapAddress(resp.address)],
  };
  return state;
};

const mapSoleClients = (
  resp: SoleTraderResponse
): Pick<SoleTrader, "individual"> => {
  return {
    individual: resp.individual ? mapIndividual(resp.individual) : undefined,
  };
};

const mapSoleBoth = (resp: SoleTraderResponse): SoleTrader => {
  return {
    ...mapSoleBusiness(resp),
    ...mapSoleClients(resp),
  };
};

const mapPartnershipBusiness = (
  resp: PartnershipResponse
): Omit<Partnership, "companies" | "individuals" | "soleTraders"> => {
  const { companies, individuals, soleTraders, ...state } = {
    ...resp,
    businessName: resp.businessName || "",
    gstRegistration: resp.gstRegistration || "",
    abnLookup: "", // FIXME: this should be local to the component
  };
  return state;
};

const mapPartnershipClients = (
  resp: PartnershipResponse
): Pick<Partnership, "companies" | "individuals" | "soleTraders"> => {
  return {
    companies: resp.companies?.map((c) => mapCompanyBoth(c)) ?? [],
    individuals: resp.individuals?.map((i) => mapIndividual(i)) ?? [],
    soleTraders: resp.soleTraders?.map((s) => mapSoleBoth(s)) ?? [],
  };
};

const mapTrustBusiness = (
  resp: TrustResponse
): Omit<Trust, "companies" | "individuals"> => {
  const { companies, individuals, ...state } = {
    ...resp,
    businessName: resp.businessName || "",
    gstRegistration: resp.gstRegistration || "",
    abnLookup: "", // FIXME: this should be local to the component
  };
  return state;
};

const mapTrustClients = (
  resp: TrustResponse
): Pick<Trust, "companies" | "individuals"> => {
  return {
    companies: resp.companies?.map((c) => mapCompanyBoth(c)) ?? [],
    individuals: resp.individuals?.map((i) => mapIndividual(i)) ?? [],
  };
};

const mapGovBusiness = (resp: GovResponse): Omit<Government, "contact"> => {
  const { contact, ...state } = {
    ...resp,
    addresses: [mapAddress(resp.address)],
  };
  return state;
};

const mapGovClients = (resp: GovResponse): Pick<Government, "contact"> => {
  return {
    contact: resp.contact ? mapContact(resp.contact) : undefined,
  };
};

const mapAssocBusiness = (
  resp: AssocResponse
): Omit<Association, "individual" | "officers"> => {
  const { individual, officers, ...state } = {
    ...resp,
    incNumber: resp.incNumber || "",
    incBody: resp.incBody || "",
    addresses: resp.addresses.map((a) => mapAddress(a)),
  };
  return state;
};

const mapAssocClients = (
  resp: AssocResponse
): Pick<Association, "individual" | "officers"> => {
  return {
    individual: resp.individual ? mapIndividual(resp.individual) : undefined,
    officers: resp.officers?.map((o) => mapOfficer(o)) ?? [],
  };
};

const mapCoopBusiness = (resp: CoopResponse): Partial<CoOp> => {
  const { officers, ...state } = {
    ...resp,
    addresses: resp.addresses.map((a) => mapAddress(a)),
  };
  return state;
};

const mapCoopClients = (resp: CoopResponse): Partial<CoOp> => {
  return {
    officers: resp.officers?.map((o) => mapOfficer(o)) ?? [],
  };
};

const mapIndividual = (resp: IndividualResponse): Individual => {
  return {
    ...resp,
    middleName: resp.middleName || "",
    idType: resp.idType || "",
    idNumber: resp.idNumber || "",
    address: mapAddress(resp.address),
  };
};

const mapDirector = (resp: DirectorResponse): Director => {
  return {
    ...resp,
    individual: mapIndividual(resp.individual),
  };
};

const mapContact = (resp: ContactResponse): Contact => {
  return {
    ...resp,
  };
};

const mapOfficer = (resp: OfficerResponse): Officer => {
  return {
    ...resp,
    middleName: resp.middleName || "",
  };
};

export const mapAddress = (resp: AddressResponse): Address => {
  const address: Address = {
    ...resp,
    line2: resp.line2 || "",
    line3: "",
    addressLookup: "",
  };
  return {
    ...address,
    // TODO: This should be generated when needed
    addressLookup: handleAddress(address),
  };
};

const mapGuarantor = (resp: GuarantorResponse): Guarantor => {
  return {
    ...resp,
    abn: resp.abn || "",
    acn: resp.acn || "",
    trusteeName: resp.trusteeName || "",
    individual: resp.individual ? mapIndividual(resp.individual) : undefined,
    contact: resp.contact ? mapContact(resp.contact) : undefined,
    address: resp.address ? mapAddress(resp.address) : undefined,
  };
};

export const mapBackgroundResponse = (
  resp: AssetFinanceBackgroundResponse
): AssetFinanceBackground | undefined => {
  if (
    !resp ||
    resp.id === "00000000-0000-0000-0000-000000000000" ||
    !resp.reason
  )
    return undefined;
  return {
    ...resp,
  };
};
