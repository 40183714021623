import { cloneDeep, get, set, unset } from "lodash";

export const updateCustomerAddressDetails = (
  state: any,
  action: { type: string; payload: any }
) => {
  const clonedForm = cloneDeep(state?.form);
  set(clonedForm, action.payload.path, action.payload.address);
  return {
    ...state,
    loading: false,
    form: clonedForm,
  };
};

export const setItemAtPathRedux = (
  state: any,
  action: { type: string; payload: any }
) => {
  const clonedForm = cloneDeep(state?.form);
  set(clonedForm, action.payload.path, action.payload.item);

  return {
    ...state,
    form: clonedForm,
  };
};

export const removeItemAtPathRedux = (
  state: any,
  action: { type: string; payload: any }
) => {
  const path: string = action.payload.path;
  const clonedForm = cloneDeep(state?.form);
  unset(clonedForm, path);

  // if it's an array, we need to filter out the empty slot
  const isArrayItem = path.endsWith("]");
  if (isArrayItem) {
    const pathToArray = path.substring(0, path.lastIndexOf("["));
    const array: Array<any> = get(clonedForm, pathToArray) || [];
    const filteredArray = array.filter((item) => Boolean(item));
    set(clonedForm, pathToArray, filteredArray);
  }

  return {
    ...state,
    form: clonedForm,
  };
};
