import React from "react";

import { Provider, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { ThemeProvider } from "styled-components";
import {
  EventType,
  BrowserAuthError,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";

// Types
import {
  AAD_ACQUIRED_TOKEN_SUCCESS,
  AAD_LOGIN_FAILED,
  AAD_LOGIN_SUCCESS,
  AAD_SET_ACCOUNT_SUCCESS,
} from "actions/types";

// pages
import Router from "routing/Router";

// utilities
import { clearSession, logSession } from "config/firebase";
import { ErrorBoundaryApp } from "components/common/error";

import { msalInstance, requestConfig } from "config/msal-instance";
import isEmpty from "helpers/isEmpty";
import CacheBuster from "components/common/utils/CacheBuster";
import { GlobalStyle, theme } from "theme";
import store from "redux/store";
import Chamaeleon from "utils/chamaeleon";
import axios from "axios";
import { ToastContainer } from "react-toastify";

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
  if (!isEmpty(accounts[0])) {
    store.dispatch({
      type: AAD_SET_ACCOUNT_SUCCESS,
      payload: { account: accounts[0] },
    });
  }
}

/**
 * @function postUserLoginEvent logs an event every time a user logs in successfully
 * @param userId string (guid) user id
 * returns {void}
 */
async function logUserLogin(userId: string, idToken: string) {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_URL_API}/v1/users/${userId}/login`,
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        },
      }
    );
  } catch (err: any) {
    // TODO: Send error logs to Datadog
    console.log(`ERROR SENDING LOG USER LOGIN REQUEST`);
    console.log(err);
  }
}

/**
 * @function
 * @param {object } event
 * returns {void}
 */
function msalEventHandler(event: any) {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    const { uniqueId, account, idToken } = event.payload;

    logSession(account);

    logUserLogin(uniqueId, idToken);

    const loginCount = localStorage.getItem(`${uniqueId}-loginCount`);

    if (loginCount === null) {
      localStorage.setItem(`${uniqueId}-loginCount`, "1");
    } else {
      localStorage.setItem(
        `${uniqueId}-loginCount`,
        `${Number(loginCount) + 1}`
      );
    }

    msalInstance.setActiveAccount(account);
    store.dispatch({
      type: AAD_LOGIN_SUCCESS,
      payload: { account: account },
    });
  } else if (
    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS &&
    event.payload.account
  ) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);

    store.dispatch({
      type: AAD_ACQUIRED_TOKEN_SUCCESS,
      payload: { account: account },
    });
  } else if (event.eventType === EventType.LOGIN_FAILURE) {
    const error = event.error;

    store.dispatch({
      type: AAD_LOGIN_FAILED,
      payload: error,
    });

    if (error.message?.includes("AADB2C90208")) {
      msalInstance
        .acquireTokenSilent({
          ...requestConfig,
          // @ts-expect-error
          account: msalInstance.getActiveAccount(),
        })
        .catch((error) => {
          if (
            error.name === "InteractionRequiredAuthError" ||
            error instanceof InteractionRequiredAuthError
          ) {
            return msalInstance.acquireTokenRedirect({
              ...requestConfig,
              loginHint: msalInstance.getActiveAccount()?.username,
            });
          } else {
            if (msalInstance.getActiveAccount()) {
              try {
                (async () => await msalInstance.logoutRedirect({}))();
              } catch (error) {}
            } else {
              window.location.reload();
            }
          }
        });
    }
  } else if (event.eventType === EventType.LOGOUT_SUCCESS) {
    clearSession();
  }
}

msalInstance.addEventCallback((event) => msalEventHandler(event));

function App() {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }
        return (
          <Provider store={store}>
            <ErrorBoundaryApp>
              <GlobalStyle />
              <Chamaeleon />
              <ThemeProvider theme={theme}>
                <BrowserRouter>
                  <Router />
                </BrowserRouter>
              </ThemeProvider>
              <ToastContainer theme="colored" autoClose={2000} />
            </ErrorBoundaryApp>
          </Provider>
        );
      }}
    </CacheBuster>
  );
}

export default App;
