import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.div`
  display: ${({ $display }) => ($display ? "block" : "none")};
  position: absolute;
  ${({ top, offsetY, offsetX }) => {
    const pushY = offsetY ? offsetY : 0;
    const pushX = offsetX ? offsetX : 0;
    if (top) {
      return css`
        bottom: calc(100% + ${pushY}rem);
        right: ${pushX}rem;
      `;
    } else {
      return css`
        bottom: -14rem;
        right: -1.5rem;
      `;
    }
  }}

  border-radius: 12px;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.colorWhite};
  padding: 2rem 2rem 1rem;
  width: 23rem;
`;

const Icon = styled.img`
  width: 4rem;
  margin-right: 2rem;

  @media (max-width: ${(props) => props.theme.breakpoints.largeScreen}) {
    font-size: 4rem;
  }
`;

const InfoContainer = styled.div``;

const Title = styled.p`
  font-size: 1.6rem;
  font-family: ${(props) => props.theme.fontStyles.headings};
  font-weight: 500;
  padding-bottom: 1rem;

  @media (max-width: ${(props) => props.theme.breakpoints.largeScreen}) {
    font-size: 1.8rem;
  }
`;

const Text = styled.p`
  font-size: 1.2rem;
  font-family: ${(props) => props.theme.fontStyles.subHeading};
  font-weight: 300;

  @media (max-width: ${(props) => props.theme.breakpoints.largeScreen}) {
    font-size: 1rem;
  }
`;

const Triangle = styled.div`
  position: absolute;
  width: 2rem;
  height: 2rem;

  ${({ top, right }) => {
    if (top) {
      return css`
        left: 3.2rem;
        bottom: -2rem;
        border-left: 1rem solid transparent;
        border-right: 1rem solid transparent;
        border-top: 2rem solid ${(props) => props.theme.colors.primary};
      `;
    } else if (right) {
      return css`
        right: -2rem;
        top: 1.5rem;
        border-top: 1rem solid transparent;
        border-left: 2rem solid ${(props) => props.theme.colors.primary};
        border-bottom: 1rem solid transparent;
      `;
    } else {
      return css`
        right: 3.2rem;
        top: -2rem;
        border-left: 1rem solid transparent;
        border-right: 1rem solid transparent;
        border-bottom: 2rem solid ${(props) => props.theme.colors.primary};
      `;
    }
  }}
`;

const Flex = styled.div`
  display: flex;
`;

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2.5rem;
`;

const RemindLater = styled.button`
  background-color: inherit;
  border: none;
  color: ${(props) => props.theme.colors.colorWhite};
  font-size: 1.2rem;
`;

const AddLogo = styled(Link)`
  color: ${(props) => props.theme.colors.colorWhite};
  text-decoration: none;
  font-size: 1.4rem;
`;

const Dismiss = styled.button`
  background-color: inherit;
  border: none;
  color: ${(props) => props.theme.colors.colorWhite};
  font-size: 1.6rem;
  margin-left: auto;
  font-weight: 500;
`;

const Info = styled.p`
  font-size: 1.6rem;
  font-family: ${(props) => props.theme.fontStyles.headings};
  font-weight: 500;
`;

export {
  AddLogo,
  RemindLater,
  FlexBetween,
  Flex,
  Triangle,
  Text,
  Title,
  InfoContainer,
  Icon,
  Container,
  Dismiss,
  Info,
};
