import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "reducers";
import thunk from "redux-thunk";
import api from "utils/api";

const middleware = [thunk, api];

// if (process.env.NODE_ENV === "development") {
//   // not sure why this is a problem
//   // @ts-expect-error
//   middleware.push(createLogger());
// }

const store = configureStore({
  reducer: rootReducer,
  middleware,
});

export default store;
