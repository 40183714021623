import styled from "styled-components/macro";

export const AuthContainer = styled.div`
  margin: -8.2rem auto 5rem auto;
  min-width: ${(props) => props.theme.breakpoints.minimumWidth};
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.mediumScreen}) {
    margin: -8.6rem auto 5rem auto;
  }
`;

export const FormContainer = styled.div`
  position: relative;
  width: 76.4rem;
  padding: 4.7rem 7.3rem 3.8rem;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0 0.4rem 1.5rem 0 rgba(0, 0, 0, 0.12);
  background-color: ${(props) => props.theme.colors.colorWhite};
  margin: 0 auto;
  max-width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.mediumScreen}) {
    padding: 4.1rem 3.5rem 4rem;
  }
`;
