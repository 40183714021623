import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { withRouter } from "components/common/utils/withRouter";
import { requestConfig } from "config/msal-instance";
import { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

/**
 * @prop { object } subjectTopics
 * @prop { object } user
 * @prop { object } auth
 * @desc Public view route redirects user into msal if they are authenticated
 */
const PublicViewRoute: FC = (props: any) => {
  const {
    component: Component,
    children,
    location,
    auth,
    redirectRoute,
    ...rest
  } = props;

  const [validTokens, setValidTokens] = useState(false);

  const { instance, inProgress, accounts } = useMsal();

  useEffect(() => {
    const accessTokenRequest = {
      ...requestConfig,
      account: accounts[0],
    };
    if (inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((response) => {
          setValidTokens(true);
        })
        .catch((error) => {
          setValidTokens(false);
        });
    }
  }, [accounts, inProgress, instance, setValidTokens]);

  const isAuthenticated = useIsAuthenticated();

  return isAuthenticated && validTokens ? (
    <Navigate to={`${redirectRoute}${location?.search ?? ""}`} />
  ) : (
    <Component {...props}>{children}</Component>
  );
};

const mapStateToProps = (state: any) => ({
  auth: state.auth.account,
});

export default withRouter(connect(mapStateToProps)(PublicViewRoute));
