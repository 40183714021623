import { Dispatch } from "@reduxjs/toolkit";
import { ApiDispatch } from "actions/apiDispatch";
import {
  ADD_NEW_CUSTOMER_FAILURE,
  ADD_NEW_CUSTOMER_REQUEST,
  ADD_NEW_CUSTOMER_SUCCESS,
  CALL_API,
  CREATE_APPLICATION_FAILURE,
  CREATE_APPLICATION_REQUEST,
  CREATE_APPLICATION_SUCCESS,
  GET_CUSTOMERS_FAILURE,
  GET_CUSTOMERS_REQUEST,
  GET_CUSTOMERS_SUCCESS,
  UPDATE_APPLICATION_FAILURE,
  UPDATE_APPLICATION_REQUEST,
  UPDATE_APPLICATION_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_OFFICE_HOLDERS_FAILURE,
  UPDATE_OFFICE_HOLDERS_REQUEST,
  UPDATE_OFFICE_HOLDERS_SUCCESS,
  UPDATE_SELECTED_BUSINESS_STRUCTURE,
} from "./types";

//CUSTOMERS   CUSTOMERS   CUSTOMERS   CUSTOMERS  CUSTOMERS   CUSTOMERS  CUSTOMERS   CUSTOMERS
/**
 * @route /users/{userId}/customers
 * @method POST
 * @desc create new customer by userId
 * @param {string} userId
 * @param {Object} formData
 * @param {string} formData.name
 * @param {Object} formData.address
 * @param {string} formData.address.country
 * @param {string} formData.address.line1
 * @param {string} formData.address.line2
 * @param {string} formData.address.name
 * @param {string} formData.address.postcode
 * @param {string} formData.address.state
 * @param {string} formData.address.suburb
 * @param {string} formData.trustName
 * @param {string} formData.trustAbn
 * @param {string} formData.tradingName
 * @param {string} formData.abn
 * @param {string} formData.acn
 * @param {string} formData.registration
 * @param {string} formData.gstRegistration
 * @param {Object} formData.primaryContact
 * @param {string} formData.primaryContact.email
 * @param {string} formData.primaryContact.firstName
 * @param {string} formData.primaryContact.lastName
 * @param {string} formData.primaryContact.mobile
 * @param {string} formData.primaryContact.role
 * @param {string} formData.structure
 * @param {string} activeStep
 */
export const createNewCustomer = ({
  formData,
  userId,
  activeStep,
}: {
  formData: any;
  userId: any;
  activeStep: any;
}) => async (dispatch: ApiDispatch) => {
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/customers`,
      method: "POST",
      body: formData,

      types: [
        ADD_NEW_CUSTOMER_REQUEST,
        ADD_NEW_CUSTOMER_SUCCESS,
        ADD_NEW_CUSTOMER_FAILURE,
      ],
    },
  });
};

/**
 * @route /users/{userId}/customers
 * @method GET
 * @desc gets customers of broker by userId
 * @param {string} userId
 * @param itemsPerPage count
 * @param page page number
 */
export const getCustomers = (
  userId: string,
  itemsPerPage: number,
  page: number
) => async (dispatch: ApiDispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/customers?count=${itemsPerPage}&page=${page}`,
      method: "GET",
      types: [
        GET_CUSTOMERS_REQUEST,
        GET_CUSTOMERS_SUCCESS,
        GET_CUSTOMERS_FAILURE,
      ],
    },
  });

/**
 * @route /users/{userId}/customers
 * @method GET
 * @desc gets customers of broker by userId
 * @param {string} userId
 * @param itemsPerPage count
 * @param page page number
 */
export const getBfCustomers = (
  userId: string,
  itemsPerPage: number,
  page: number
) => async (dispatch: ApiDispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/customers?generic=true&count=${itemsPerPage}&page=${page}`,
      method: "GET",
      types: [
        GET_CUSTOMERS_REQUEST,
        GET_CUSTOMERS_SUCCESS,
        GET_CUSTOMERS_FAILURE,
      ],
    },
  });

/**
 *
 * @param userId {string} guid
 * @param formData {object} Contains `structure`, `company`
 * @param authorizationCheck {bool}
 * @param quoteId {number | null} [o] - nullable
 * @param activeStep {number} - unused
 * @returns {(function(*): Promise<void>)|*}
 * @example Returns
 * {
 *   "name": "WHITE CITY PTY LTD",
 *   "tradingName": "WHITE CITY PTY LTD",
 *   "abn": "31061621832",
 *   "registration": "2000-04-11T14:00:00.000Z",
 *   "gstRegistration": "2000-06-30T14:00:00.000Z",
 *   "addresses": [
 *     {
 *       "country": "AU"
 *     }
 *   ],
 *   "directors": [
 *     {
 *       "individual": {
 *         "address": {
 *           "country": "AU"
 *         }
 *       },
 *       "isDirector": false,
 *       "isShareholder": false,
 *       "isOwner": false,
 *       "isPrimaryContact": false
 *     }
 *   ],
 *   "companies": [
 *     {
 *       "addresses": [
 *         {b
 *           "country": [
 *             {
 *               "name": "Australia",
 *               "value": "AU"
 *             }
 *           ]
 *         }
 *       ],
 *       "directors": [
 *         {
 *           "individual": {
 *             "address": {
 *               "country": [
 *                 {
 *                   "name": "Australia",
 *                   "value": "AU"
 *                 }
 *               ]
 *             }
 *           },
 *           "isDirector": false,
 *           "isShareholder": false,
 *           "isOwner": false,
 *           "isPrimaryContact": false
 *         }
 *       ]
 *     }
 *   ],
 *   "individual": {
 *     "address": {
 *       "country": [
 *         {
 *           "name": "Australia",
 *           "value": "AU"
 *         }
 *       ]
 *     }
 *   },
 *   "address": {
 *     "country": [
 *       {
 *         "name": "Australia",
 *         "value": "AU"
 *       }
 *     ]
 *   }
 * }
 */
export const createCustomerAndApplication = ({
  userId,
  formData,
  authorizationCheck,
  quoteId,
  activeStep,
}: {
  userId: any;
  formData: any;
  authorizationCheck: any;
  quoteId: any;
  activeStep: any;
}) => async (dispatch: ApiDispatch) => {
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/customers`,
      method: "POST",
      body: formData,
      types: [
        ADD_NEW_CUSTOMER_REQUEST,
        ADD_NEW_CUSTOMER_SUCCESS,
        ADD_NEW_CUSTOMER_FAILURE,
      ],
    },
  }).then((res) => {
    if (res?.type === ADD_NEW_CUSTOMER_SUCCESS) {
      dispatch({
        [CALL_API]: {
          endpoint: `/v1/users/${userId}/afapplications`,
          method: "POST",
          body: {
            isFullDoc: formData.isFullDoc,
            CustomerId: res.payload.id,
            authorizationCheck: authorizationCheck,
            quoteId: quoteId,
          },
          types: [
            CREATE_APPLICATION_REQUEST,
            CREATE_APPLICATION_SUCCESS,
            CREATE_APPLICATION_FAILURE,
          ],
        },
      });
    }
  });
};

export const updateCustomerAndApplication = ({
  userId,
  formData,
  activeStep,
  customerId,
  currentApplication,
  quoteId,
}: {
  userId: any;
  formData: any;
  activeStep: any;
  customerId: any;
  currentApplication: any;
  quoteId: any;
}) => async (dispatch: ApiDispatch) => {
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/customers/${customerId}`,
      method: "PUT",
      body: formData,
      types: [
        UPDATE_CUSTOMER_REQUEST,
        UPDATE_CUSTOMER_SUCCESS,
        UPDATE_CUSTOMER_FAILURE,
      ],
    },
  }).then((res) => {
    if (res?.type === UPDATE_CUSTOMER_SUCCESS && currentApplication === null) {
      dispatch({
        [CALL_API]: {
          endpoint: `/v1/users/${userId}/afapplications`,
          method: "POST",
          body: {
            isFullDoc: formData.isFullDoc,
            customerId: res.payload.id,
            authorizationCheck: formData.authorizationCheck,
            quoteId: quoteId,
          },
          types: [
            CREATE_APPLICATION_REQUEST,
            CREATE_APPLICATION_SUCCESS,
            CREATE_APPLICATION_FAILURE,
          ],
        },
      });
    } else if (
      res?.type === UPDATE_CUSTOMER_SUCCESS &&
      currentApplication &&
      formData.isFullDoc
    ) {
      dispatch({
        [CALL_API]: {
          endpoint: `/v1/users/${userId}/afapplications/${currentApplication}`,
          method: "PUT",
          body: {
            isFullDoc: formData.isFullDoc,
            quoteId: quoteId,
          },
          types: [
            UPDATE_APPLICATION_REQUEST,
            UPDATE_APPLICATION_SUCCESS,
            UPDATE_APPLICATION_FAILURE,
          ],
        },
      });
    }
  });
};

export const createCustomer = ({
  userId,
  formData,
}: {
  userId: any;
  formData: any;
}) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/customers`,
      method: "POST",
      body: formData,
      types: [
        ADD_NEW_CUSTOMER_REQUEST,
        ADD_NEW_CUSTOMER_SUCCESS,
        ADD_NEW_CUSTOMER_FAILURE,
      ],
    },
  });
  const success = res?.type === ADD_NEW_CUSTOMER_SUCCESS;
  return { success, customerId: res.payload.id };
};

export const updateCustomer = ({
  userId,
  formData,
  customerId,
}: {
  userId: any;
  formData: any;
  customerId: any;
}) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/customers/${customerId}`,
      method: "PUT",
      body: formData,
      types: [
        UPDATE_CUSTOMER_REQUEST,
        UPDATE_CUSTOMER_SUCCESS,
        UPDATE_CUSTOMER_FAILURE,
      ],
    },
  });
  let success = res?.type === UPDATE_CUSTOMER_SUCCESS;
  return { success, customerId };
};

export const updateOfficeHolders = ({
  isFullDoc,
  brokerId,
  customerId,
  applicationId,
  formData,
  quoteId,
}: {
  isFullDoc?: boolean;
  brokerId: any;
  customerId: any;
  applicationId?: string;
  formData: any;
  quoteId?: any;
}) => async (dispatch: ApiDispatch) => {
  const updateRes = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${brokerId}/customers/${customerId}/officeholders`,
      method: "PUT",
      body: formData,
      types: [
        UPDATE_OFFICE_HOLDERS_REQUEST,
        UPDATE_OFFICE_HOLDERS_SUCCESS,
        UPDATE_OFFICE_HOLDERS_FAILURE,
      ],
    },
  });
  let success = updateRes?.type === UPDATE_OFFICE_HOLDERS_SUCCESS;
  if (success) {
    if (!applicationId) {
      const createRes = await dispatch({
        [CALL_API]: {
          endpoint: `/v1/users/${brokerId}/afapplications`,
          method: "POST",
          body: {
            isFullDoc,
            customerId: customerId,
            authorizationCheck: true,
            quoteId: quoteId,
          },
          types: [
            CREATE_APPLICATION_REQUEST,
            CREATE_APPLICATION_SUCCESS,
            CREATE_APPLICATION_FAILURE,
          ],
        },
      });
      success = createRes?.type === CREATE_APPLICATION_SUCCESS;
      applicationId = createRes?.payload?.id;
    }
  }
  return { success, applicationId };
};

export const updateSelectedBusinessStructure = (structure: any) => async (
  dispatch: Dispatch
) =>
  dispatch({
    type: UPDATE_SELECTED_BUSINESS_STRUCTURE,
    payload: structure,
  });
