import isEmpty from "helpers/isEmpty";

export function stripObject<T extends Record<string, any>>(
  object: T
): Partial<T> {
  const shallowCopiedObject = { ...object };

  for (const [key, value] of Object.entries(shallowCopiedObject)) {
    if (isEmpty(value) || value === 0) {
      delete shallowCopiedObject[key];
    }
  }

  return shallowCopiedObject;
}
