import { getBrokerCompanies } from "actions";
import {
  DotPoint,
  DropdownButton,
  DropdownItem,
  Info,
  Name,
} from "components/common/dropdowns/Dropdown.styles";
import { Select } from "components/common/inputs";
import { abnFormat } from "helpers/stringHelpers";
import isEmpty from "lodash/isEmpty";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "redux-models/reduxStateTypes";
import { loadMoreOption } from "utils/constants/global-constant";

type BrokerCompanySelectPaginationProps = {
  formikValue: string;
  setFieldValue: (name: string, value: string) => any;
  handleBlur: (e: any) => any;
  name: string;
  placeholder: string;
  error: any;
};

const BrokerCompanySelectPagination = ({
  formikValue,
  setFieldValue,
  handleBlur,
  name,
  error,
  placeholder,
}: BrokerCompanySelectPaginationProps) => {
  const dispatch = useDispatch();

  const { userId, allBrokerCompanies, totalPages, loading } = useSelector(
    (state: ReduxState) => ({
      userId: state.auth.account.localAccountId,
      // @ts-expect-error
      allBrokerCompanies: state.company.items,
      totalPages: state.company.totalPages,
      loading: state.company.loading,
    })
  );

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const lastPage = page === totalPages;

  useEffect(() => {
    getBrokerCompanies(userId, 10, page, search)(dispatch);
  }, [formikValue, search, getBrokerCompanies, page, userId]);

  return (
    <Select
      text=""
      error={error}
      options={[
        ...allBrokerCompanies,
        ...(!lastPage && !isEmpty(allBrokerCompanies) ? loadMoreOption : []),
      ]}
      placeholder={placeholder}
      name={name}
      // @ts-expect-error
      value={formikValue}
      // @ts-expect-error
      change={setFieldValue}
      handleBlur={handleBlur}
      setSearch={setSearch}
      loading={loading}
      setPage={setPage}
      lastPage={lastPage}
      page={page}
      CustomOption={CompanyOption}
      filterOption={false}
      labelField="name"
      valueField="id"
      isPageable
      isPreRefactor={false}
    />
  );
};

export default BrokerCompanySelectPagination;

const CompanyOption = ({
  innerProps,
  innerRef,
  label,
  value,
  data,
}: {
  label: string;
  value: any;
  data: any;
  innerProps: any;
  innerRef: any;
}) => {
  return (
    <>
      <DropdownItem role="option" ref={innerRef} {...innerProps}>
        <DropdownButton highlightPastel>
          <Name>{label}</Name>
          {value !== "loadMore" && (
            <Info>
              {"ABN: " + abnFormat(data?.abn)} <DotPoint />
              {data?.address?.state + ", " + data?.address?.postcode}
            </Info>
          )}
        </DropdownButton>
      </DropdownItem>
    </>
  );
};
