import styled from "styled-components/macro";
import NavBodyItem from "../NavBodyItem";

const Container = styled.div`
  height: 100%;
  font-size: 16px;
  display: flex;

  #dropdown-option-main-6 {
    display: none;
  }
`;

function NavigationItemMain({ data }: any) {
  return (
    <Container className="nav-item-main">
      {data.map((item: any, index: number) => {
        return <NavBodyItem id={"dropdown-option-main-" + index} {...item} />;
      })}
    </Container>
  );
}

export default NavigationItemMain;
