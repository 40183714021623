import {
  ADMIN_VALUE,
  scotpacArray,
  clientArray,
  STAFF_VALUE,
} from "utils/constants";

/**
 *
 * @param role
 * @param roles
 * @returns {boolean}
 * @desc returns true when the roles includes the role
 */
const trueRoles = (role: string, roles = [ADMIN_VALUE]) => roles.includes(role);

// @ts-expect-error
const isStaff = (role: string | undefined) => scotpacArray.includes(role);

const isStaffUser = (role: string | undefined) => role === STAFF_VALUE;

const isClient = (role: string) => clientArray.includes(role);

export { trueRoles, isStaff, isClient, isStaffUser };
