import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Item, DropdownItems } from "./NavProfileDropdown.styled";
import { isStaff } from "helpers/role";
import NavSubLink from "./offcanvas-nav/NavSubLink.styled";
import Header from "./offcanvas-nav/Header";

const NavProfileDropdown = ({ showDropdown, reference, toggleModal, role }) => {
  return (
    <div>
      <DropdownItems
        className={`sm-text profile ${showDropdown ? "profile-active" : ""}`}
        ref={reference}
        showDropdown={showDropdown}
      >
        <Header isDesktop={true} role={role} />
        <div className="list-item">
          <Item>
            <NavSubLink to="/profile">Profile</NavSubLink>
          </Item>
          {isStaff(role) && (
            <>
              <Item>
                <NavSubLink to="/all-users">Users</NavSubLink>
              </Item>
              <Item>
                <NavSubLink
                  onClick={() => {
                    toggleModal();
                  }}
                >
                  Invite
                </NavSubLink>
              </Item>
              <Item>
                <NavSubLink to="/promotions">Promotions</NavSubLink>
              </Item>
            </>
          )}
          <Item>
            <NavSubLink to="/logout">Sign Out</NavSubLink>
          </Item>
        </div>
      </DropdownItems>
    </div>
  );
};

NavProfileDropdown.propTypes = {
  showDropdown: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  role: state.auth.account.idTokenClaims.role,
});

export default connect(mapStateToProps)(NavProfileDropdown);
