import { clean } from "helpers/data-helpers";
import { actionResponse } from "helpers/data-helpers/afActionResponse";
import { cloneDeep } from "lodash";
import { Dispatch } from "redux";
import {
  AfCriteria,
  ClientDetailsFormStructures,
  Guarantor,
  RemoveItemAtPathPayload,
  UpdateAddressAtPathPayload,
  UpdateItemAtPathPayload,
  User,
} from "redux-models/reduxStateTypes";
import {
  CALL_API,
  CLEAR_ASSET_FINANCE_ERROR,
  CLEAR_ASSET_FINANCE_RECOMMENDATION,
  CREATE_APPLICATION_FAILURE,
  CREATE_APPLICATION_REQUEST,
  CREATE_APPLICATION_SUCCESS,
  FINANCING_NEEDS_FAILURE,
  FINANCING_NEEDS_REQUEST,
  FINANCING_NEEDS_SUCCESS,
  GET_AF_APPLICATION_FAILURE,
  GET_AF_APPLICATION_REQUEST,
  GET_AF_APPLICATION_SUCCESS,
  GET_AF_FILES_FAILURE,
  GET_AF_FILES_REQUEST,
  GET_AF_FILES_SUCCESS,
  GET_ALL_AFCRITERIA_FAILURE,
  GET_ALL_AFCRITERIA_REQUEST,
  GET_ALL_AFCRITERIA_SUCCESS,
  AF_GET_BROKER_FAILURE,
  AF_GET_BROKER_REQUEST,
  AF_GET_BROKER_SUCCESS,
  GET_CUSTOMER_DETAILS_FAILURE,
  GET_CUSTOMER_DETAILS_REQUEST,
  GET_CUSTOMER_DETAILS_SUCCESS,
  GET_SUMMARY_AFCRITERIA_FAILURE,
  GET_SUMMARY_AFCRITERIA_REQUEST,
  GET_SUMMARY_AFCRITERIA_SUCCESS,
  REMOVE_ITEM_AT_PATH,
  RESET_ASSET_FINANCE_STATE,
  AF_SELECT_BROKER,
  SET_CUSTOMER_DETAILS,
  SET_ITEM_AT_PATH,
  SUBMIT_APPLICATION_FAILURE,
  SUBMIT_APPLICATION_REQUEST,
  SUBMIT_APPLICATION_SUCCESS,
  UPDATE_ALL_AFCRITERIA_FAILURE,
  UPDATE_ALL_AFCRITERIA_REQUEST,
  UPDATE_ALL_AFCRITERIA_SUCCESS,
  UPDATE_APPLICATION_FAILURE,
  UPDATE_APPLICATION_INFORMATION_FAILURE,
  UPDATE_APPLICATION_INFORMATION_REQUEST,
  UPDATE_APPLICATION_INFORMATION_SUCCESS,
  UPDATE_APPLICATION_REQUEST,
  UPDATE_APPLICATION_SUCCESS,
  UPDATE_GUARANTOR_INFO_FAILURE,
  UPDATE_GUARANTOR_INFO_REQUEST,
  UPDATE_GUARANTOR_INFO_SUCCESS,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPDATE_BROKER_NOB_REQUEST,
  UPDATE_BROKER_NOB_SUCCESS,
  UPDATE_BROKER_NOB_FAILURE,
} from "./types";

type ApiDispatch = (action: any) => Promise<any>;

/**
 * @route /v1/users/userId
 * @method GET
 * @param broker
 */
export const getBrokerAf = ({ brokerId }: { brokerId: string }) => async (
  dispatch: ApiDispatch
) => {
  await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${brokerId}`,
      method: "GET",
      types: [
        AF_GET_BROKER_REQUEST,
        AF_GET_BROKER_SUCCESS,
        AF_GET_BROKER_FAILURE,
      ],
    },
  });
};

/**
 *
 * @route /v1/users/userId/afapplications/applicationId/financing
 * @method PUT
 */
export const updateFinancingApplication = ({
  brokerId,
  applicationId,
  financingNeed,
  financingAmount,
  isPropertyOwner,
  monthlyTurnover,
  nboPresented,
  productNbo,
}: {
  brokerId: string;
  applicationId: string;
  financingNeed: string;
  financingAmount?: number;
  isPropertyOwner: boolean | undefined;
  monthlyTurnover: string | undefined;
  nboPresented: boolean | undefined;
  productNbo: string | undefined;
}) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${brokerId}/afapplications/${applicationId}/financing`,
      method: "PUT",
      body: {
        financingNeed,
        financingAmount,
        isPropertyOwner,
        monthlyTurnover,
        nboPresented,
        productNbo,
      },
      types: [
        FINANCING_NEEDS_REQUEST,
        FINANCING_NEEDS_SUCCESS,
        FINANCING_NEEDS_FAILURE,
      ],
    },
  });
  return actionResponse(FINANCING_NEEDS_SUCCESS, res);
};

/**
 * @route /users/{userId}/afapplications/{applicationId}
 * @method GET
 * @desc gets a specific af application
 * @param {string} userId
 * @param {string} applicationId
 */
export const getAfApplication = (
  userId: string,
  applicationId: string
) => async (dispatch: ApiDispatch) => {
  await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/afapplications/${applicationId}`,
      method: "GET",
      types: [
        GET_AF_APPLICATION_REQUEST,
        GET_AF_APPLICATION_SUCCESS,
        GET_AF_APPLICATION_FAILURE,
      ],
    },
  });
};

/**
 * @route /v1/users/${userId}/afapplications/${applicationId}/information
 * @method PUT
 * @desc Update step 4 full doc
 * @param {string} userId
 * @param {string} applicationId
 * @param {string} isSubmittedForm
 * @param {string} reason
 * @param {string} briefBackground
 * @param {string} isPropertyOwner
 * @param {string}  isAtoArrears
 * @param {string} atoArrears
 * @param {string} hardwired
 * @param {string} recommendation
 * @param {string} propertyValue
 */
export const updateApplicationInformation = ({
  userId,
  applicationId,
  isSubmittedForm,
  reason,
  briefBackground,
  isPropertyOwner,
  propertyValue,
  isAtoArrears,
  atoArrears,
  hardwired,
  recommendation,
}: {
  userId: string;
  applicationId: string;
  isSubmittedForm: boolean;
  reason?: string;
  briefBackground?: string;
  isPropertyOwner?: boolean | null;
  propertyValue?: string;
  isAtoArrears?: boolean | null;
  atoArrears?: string;
  hardwired?: string;
  recommendation?: string;
}) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/afapplications/${applicationId}/information`,
      method: "PUT",
      body: {
        isSubmittedForm,
        reason,
        briefBackground,
        isPropertyOwner,
        propertyValue,
        isAtoArrears,
        atoArrears,
        hardwired,
        recommendation,
      },
      types: [
        UPDATE_APPLICATION_INFORMATION_REQUEST,
        UPDATE_APPLICATION_INFORMATION_SUCCESS,
        UPDATE_APPLICATION_INFORMATION_FAILURE,
      ],
    },
  });

  return { success: res?.type === UPDATE_APPLICATION_INFORMATION_SUCCESS };
};

/**
 *
 * @param {object} formData
 * @param {string} userId
 * @param {string} applicationId
 * @desc adds an quote from the global state
 */
export const uploadFile = (
  formData: object,
  userId: string,
  applicationId: string
) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/applications/${applicationId}/documents`,
      method: "POST",
      body: formData,
      types: [UPLOAD_FILE_REQUEST, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAILURE],
      cancellable: true,
    },
  });

  return { success: res.type === UPLOAD_FILE_SUCCESS };
};

/**
 *
 * @param {string} userId
 * @param {string} applicationId
 * @desc gets documents for an application
 */
export const getApplicationFiles = (
  userId: string,
  applicationId: string
) => async (dispatch: ApiDispatch) => {
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/applications/${applicationId}/documents`,
      method: "GET",
      types: [GET_AF_FILES_REQUEST, GET_AF_FILES_SUCCESS, GET_AF_FILES_FAILURE],
      cancellable: true,
    },
  });
};

/**
 * @route /users/{userId}/afapplications/{applicationId}
 * @method POST
 * @desc switch to full doc
 * @param {string} userId
 * @param {string} applicationId
 * @param {boolean} isFullDoc
 */
export const updateApplicationToFullDoc = ({
  userId,
  applicationId,
  isFullDoc,
}: {
  userId: string;
  applicationId: string;
  isFullDoc: boolean;
}) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/afapplications/${applicationId}`,
      method: "PUT",
      body: {
        isFullDoc,
      },
      types: [
        UPDATE_APPLICATION_REQUEST,
        UPDATE_APPLICATION_SUCCESS,
        UPDATE_APPLICATION_FAILURE,
      ],
    },
  });

  return { success: res.type === UPDATE_APPLICATION_SUCCESS };
};

/**
 * @route /users/{userId}/afapplications/{applicationId}
 * @method POST
 * @desc gets a specific af application
 * @param {string} userId
 * @param {string} applicationId
 */
export const submitApplication = ({
  userId,
  applicationId,
}: {
  userId: string;
  applicationId: string;
}) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/afapplications/${applicationId}/submit`,
      method: "PUT",
      types: [
        SUBMIT_APPLICATION_REQUEST,
        SUBMIT_APPLICATION_SUCCESS,
        SUBMIT_APPLICATION_FAILURE,
      ],
    },
  });
  return { success: res?.type === SUBMIT_APPLICATION_SUCCESS };
};

/**
 * @route /settings/af/fastdoc/summary
 * @method GET
 * @desc gets af customer criteria
 */
export const getAFSummaryCriteria = () => async (dispatch: ApiDispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/settings/af/fastdoc/summary`,
      method: "GET",
      query: {},
      types: [
        GET_SUMMARY_AFCRITERIA_REQUEST,
        GET_SUMMARY_AFCRITERIA_SUCCESS,
        GET_SUMMARY_AFCRITERIA_FAILURE,
      ],
    },
  });

/**
 * @route /settings/af/fastdoc
 * @method GET
 * @desc gets af customer criteria
 */
export const getAFRulesFullCriteria = () => async (dispatch: ApiDispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/settings/af/fastdoc`,
      method: "GET",
      query: {},
      types: [
        GET_ALL_AFCRITERIA_REQUEST,
        GET_ALL_AFCRITERIA_SUCCESS,
        GET_ALL_AFCRITERIA_FAILURE,
      ],
    },
  });
/**
 * @route /settings/af/fastdoc
 * @method PUT
 * @param {object} formData
 * @desc gets af customer criteria
 */
export const updateAFRulesFullCriteria = (formData: AfCriteria) => async (
  dispatch: ApiDispatch
) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/settings/af/fastdoc`,
      method: "PUT",
      body: formData,
      types: [
        UPDATE_ALL_AFCRITERIA_REQUEST,
        UPDATE_ALL_AFCRITERIA_SUCCESS,
        UPDATE_ALL_AFCRITERIA_FAILURE,
      ],
    },
  });
  return { success: res?.type === UPDATE_ALL_AFCRITERIA_SUCCESS };
};

/**
 * @route /users/{userId}/customers/{customerId}/guarantors
 * @method PUT
 * @desc updates guarantor info
 */
export const updateGuarantors = ({
  userId,
  customerId,
  guarantors,
}: {
  userId: string;
  customerId: string;
  guarantors: Guarantor[];
  appId?: string;
}) => async (dispatch: ApiDispatch) => {
  const tempBody = cloneDeep({ guarantors: guarantors });
  clean(tempBody);

  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/customers/${customerId}/guarantors`,
      method: "PUT",
      body: tempBody,
      types: [
        UPDATE_GUARANTOR_INFO_REQUEST,
        UPDATE_GUARANTOR_INFO_SUCCESS,
        UPDATE_GUARANTOR_INFO_FAILURE,
      ],
    },
  });
  return { success: res?.type === UPDATE_GUARANTOR_INFO_SUCCESS };
};

/**
 * @param {string} customerId
 * @param {string} userId
 */
export const getCustomerDetails = ({
  userId,
  customerId,
}: {
  userId: string;
  customerId: string;
}) => {
  return async (dispatch: ApiDispatch) => {
    dispatch({
      [CALL_API]: {
        endpoint: `/v1/users/${userId}/customers/${customerId}`,
        method: "GET",
        types: [
          GET_CUSTOMER_DETAILS_REQUEST,
          GET_CUSTOMER_DETAILS_SUCCESS,
          GET_CUSTOMER_DETAILS_FAILURE,
        ],
      },
    });
  };
};

export const resetAssetFinanceState = () => async (dispatch: ApiDispatch) =>
  dispatch({
    type: RESET_ASSET_FINANCE_STATE,
  });

export const selectBrokerAf = (broker: User | null) => async (
  dispatch: ApiDispatch
) =>
  dispatch({
    type: AF_SELECT_BROKER,
    payload: broker,
  });

export const updateItemAtPath = (
  pathAndValues: UpdateItemAtPathPayload
) => async (dispatch: Dispatch<any>) =>
  dispatch({
    type: SET_ITEM_AT_PATH,
    payload: pathAndValues,
  });

export const removeItemAtPath = (payload: RemoveItemAtPathPayload) => async (
  dispatch: Dispatch<any>
) =>
  dispatch({
    type: REMOVE_ITEM_AT_PATH,
    payload,
  });

export const setCustomerDetails = (
  values: Partial<ClientDetailsFormStructures>
) => async (dispatch: Dispatch<any>) =>
  dispatch({
    type: SET_CUSTOMER_DETAILS,
    payload: values,
  });

export const updateAddressAtPath = (
  addressAndPath: UpdateAddressAtPathPayload
) => async (dispatch: Dispatch<any>) => {
  dispatch({
    type: SET_ITEM_AT_PATH,
    payload: {
      path: addressAndPath.path,
      item: addressAndPath.address,
    },
  });
};

export const deleteCustomerAddressDetails = (values: {
  id: number;
  structure: string;
}) => async (dispatch: Dispatch<any>) => {
  dispatch({
    type: REMOVE_ITEM_AT_PATH,
    payload: {
      path: `${values.structure}.addresses[${values.id}]`,
    },
  });
};

/**
 *
 * @route /v1/users/{userId}/afapplications/{applicationId}/nbo
 * @method PUT
 */
export const updateBrokerNboStatus = ({
  brokerId,
  applicationId,
  brokerAcceptedNbo,
}: {
  brokerId: string;
  applicationId: string;
  brokerAcceptedNbo: boolean;
}) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${brokerId}/afapplications/${applicationId}/nbo`,
      method: "PUT",
      body: {
        brokerAcceptedNbo,
      },
      types: [
        UPDATE_BROKER_NOB_REQUEST,
        UPDATE_BROKER_NOB_SUCCESS,
        UPDATE_BROKER_NOB_FAILURE,
      ],
    },
  });
  return { success: res?.type === UPDATE_BROKER_NOB_SUCCESS };
};

/**
 * Clear the asset finance error
 */
export const clearError = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: CLEAR_ASSET_FINANCE_ERROR,
  });
};

export const clearRecommendation = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: CLEAR_ASSET_FINANCE_RECOMMENDATION,
  });
};
