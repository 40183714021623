import {
  GET_PROMOTIONS_REQUEST,
  GET_PROMOTIONS_SUCCESS,
  GET_PROMOTIONS_FAILURE,
  ADD_PROMOTION_REQUEST,
  ADD_PROMOTION_SUCCESS,
  ADD_PROMOTION_FAILURE,
  ADD_PROMOTION_IMAGE_REQUEST,
  ADD_PROMOTION_IMAGE_SUCCESS,
  ADD_PROMOTION_IMAGE_FAILURE,
  UPDATE_PROMOTION_REQUEST,
  UPDATE_PROMOTION_SUCCESS,
  UPDATE_PROMOTION_FAILURE,
  UPDATE_PROMOTION_ENABLE_REQUEST,
  UPDATE_PROMOTION_ENABLE_SUCCESS,
  UPDATE_PROMOTION_ENABLE_FAILURE,
  DELETE_PROMOTION_REQUEST,
  DELETE_PROMOTION_SUCCESS,
  DELETE_PROMOTION_FAILURE,
  EDIT_PROMOTION,
  CANCEL_PROMOTION,
} from "actions/types";
import { loadingStateLegacy } from "helpers/reducerHelpers";
import { mapApiErrorToStateLegacy } from "helpers/mappers";

const initialState: any = {
  success: null,
  loading: false,
  error: {},
  promotions: [],
  promotion: {},
  pageNumber: null,
  pageSize: null,
  totalPages: null,
  loadingCount: 0,
};

const personalisation = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_PROMOTIONS_REQUEST:
      return loadingStateLegacy(state);
    case GET_PROMOTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalPages: action.payload.totalPages,
        promotions: action.payload.items,
      };
    case GET_PROMOTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: { promo: { ...action.payload, statusCode: action.statusCode } },
      };
    case ADD_PROMOTION_REQUEST:
      return loadingStateLegacy(state);
    case ADD_PROMOTION_SUCCESS:
      return {
        ...state,
        promotion: {},
        loading: false,
        promotions: [...state.promotions, action.payload].sort(
          (a, b) => a.order - b.order
        ),
      };
    case ADD_PROMOTION_FAILURE:
      return mapApiErrorToStateLegacy(action, state);
    case ADD_PROMOTION_IMAGE_REQUEST:
      return loadingStateLegacy(state);
    case ADD_PROMOTION_IMAGE_SUCCESS:
      return { ...state, loading: false };
    case ADD_PROMOTION_IMAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: { image: { ...action.payload, statusCode: action.statusCode } },
      };
    case UPDATE_PROMOTION_REQUEST:
      return { ...state, loadingCount: state.loadingCount + 1, loading: true };
    case UPDATE_PROMOTION_SUCCESS:
      return {
        ...state,
        loading: state.loadingCount - 1 !== 0,
        promotions: state.promotions
          .map((promo: any) =>
            promo.id === action.payload.id ? action.payload : promo
          )
          .sort((a: any, b: any) => a.order - b.order),
        promotion: {},
        loadingCount: state.loadingCount - 1,
      };
    case UPDATE_PROMOTION_FAILURE:
      return mapApiErrorToStateLegacy(action, {
        ...state,
        loading: state.loadingCount - 1 !== 0,
        loadingCount: state.loadingCount - 1,
      });
    case UPDATE_PROMOTION_ENABLE_REQUEST:
      return loadingStateLegacy(state);
    case UPDATE_PROMOTION_ENABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        promotions: state.promotions.map((promo: any) =>
          promo.id === action.payload.id ? action.payload : promo
        ),
      };
    case UPDATE_PROMOTION_ENABLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: { enable: { ...action.payload, statusCode: action.statusCode } },
      };
    case DELETE_PROMOTION_REQUEST:
      return loadingStateLegacy(state);
    case DELETE_PROMOTION_SUCCESS:
      return {
        ...state,
        loading: false,
        promotions: state.promotions.filter(
          (promo: any) => promo.id !== action.payload.id
        ),
      };
    case DELETE_PROMOTION_FAILURE:
      return { ...state, loading: false, error: { delete: action.payload } };
    case EDIT_PROMOTION:
      return { ...state, promotion: action.payload };
    case CANCEL_PROMOTION:
      return { ...state, promotion: {} };
    default:
      return state;
  }
};

export default personalisation;
