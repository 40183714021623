import isEmpty from "helpers/isEmpty";
import {
  COMPANY_NAME_COLUMN,
  LOCATION_COLUMN,
  CONTACT_COLUMN,
  PRODUCTS_COLUMN,
  COMPANY_STATUS_COLUMN,
} from "utils/constants";

export const defineCompanyColumns = (items: any[]) => {
  let columns: (string | null)[] = [null, null, null, null, null];

  items.forEach((item) => {
    if (!isEmpty(item.name)) {
      columns[0] = COMPANY_NAME_COLUMN;
    }
    if (!isEmpty(item.address) && !isEmpty(item.address.state)) {
      columns[1] = LOCATION_COLUMN;
    }
    if (!isEmpty(item.contactName)) {
      columns[2] = CONTACT_COLUMN;
    }
    if (!isEmpty(item.products)) {
      columns[3] = PRODUCTS_COLUMN;
    }
    if (!isEmpty(item.stage)) {
      columns[4] = COMPANY_STATUS_COLUMN;
    }
  });

  return columns.filter((col) => col !== null);
};
