import isEmpty from "helpers/isEmpty";
import {
  CLIENT_NAME_COLUMN,
  QUOTE_CREATED_AT_COLUMN,
  INTRODUCER_COLUMN,
  ASSET_COST_COLUMN,
  INTEREST_RATE_COLUMN,
  BROKERAGE_RATE_COLUMN,
} from "utils/constants";

export const defineQuoteColumns = (items: any[]) => {
  let columns: Array<string | null> = [null, null, null, null, null, null];

  items.forEach((item) => {
    if (!isEmpty(item.clientName)) {
      columns[0] = CLIENT_NAME_COLUMN;
    }
    if (!isEmpty(item.created)) {
      columns[1] = QUOTE_CREATED_AT_COLUMN;
    }
    if (!isEmpty(item.ownerName)) {
      columns[2] = INTRODUCER_COLUMN;
    }
    if (!isEmpty(item.assetCost)) {
      columns[3] = ASSET_COST_COLUMN;
    }
    if (!isEmpty(item.interestRate)) {
      columns[4] = INTEREST_RATE_COLUMN;
    }
    if (!isEmpty(item.brokerageRate)) {
      columns[5] = BROKERAGE_RATE_COLUMN;
    }
  });

  return columns.filter((col) => col !== null);
};
