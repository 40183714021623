import styled from "styled-components/macro";

const Container = styled.div`
  position: relative;
`;

const Profile = styled.div`
  height: 100%;
`;

const ProfileDropdown = styled.button`
  border: none;
  background: none;
  height: 100%;
  padding: 15px;
  display: inline-block;
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

const PromptCircle = styled.div`
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.coralActive};
`;

export { PromptCircle, Icon, ProfileDropdown, Profile, Container };
