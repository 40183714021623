import React from "react";
import styled from "styled-components/macro";
import { Triangle } from "./styled";

const Container = styled.div`
  position: absolute;
  top: ${({ $top }) => $top};
  left: ${({ $left }) => $left};
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 6px;
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.colorWhite};
  padding: 1.5rem;
`;

const SimpleTooltip = ({ text, top, left }) => {
  return (
    <Container $top={top} $left={left}>
      {text}
      <Triangle right />
    </Container>
  );
};

export { SimpleTooltip };
