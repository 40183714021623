import { CONTENT_URL_PATHS } from "./content";

export const ROUTES = {
  ALL: "*",
  HOME: "/",
  LOGOUT: "/logout",
  SIGN_IN: "/signin",
  NOT_FOUND: "/not-found",
  CREATE_COMPANY: "/create-company",
  UNVERIFIED: "/unverified",
  TERMS: "/terms",
  APPLICATIONS: "/applications",
  APPLY_AND_REFER: "/apply-and-refer",
  PRODUCT_SELECTOR_TOOL: "/product-selector-tool",
  PROMOTIONS: "/promotions",
  REPORTING: "/reporting",
  COMPANIES: "/companies",
  TRANSACTIONS: "/transactions",
  CREATE_QUOTE: "/create-quote",
  VIEW_QUOTE: "/:ownerId/quote/:quoteId",
  PRODUCT_SELECTOR: "/product-selector",
  PRODUCT_SELECTOR_AV: "/product-selector-av",
  WORKING_CAPITAL: "/working-capital-calculator",
  WORKING_CAPITAL_AV: "/working-capital-calculator-av",
  QUOTES: "/quotes",
  PROFILE: ["/profile", "/profile/:id"],
  ALL_USERS: "/all-users",
  PENDING_APPLICATIONS: "/pending-applications",
  FAST_DOC_SETTINGS: "/fast-doc/settings",
  ASSET_FINANCE_SELECTION: "/asset-finance",
  APPLY_ALL: "/apply-all",
  ASSET_FINANCE: [
    "/af/:docType",
    "/af/:docType/user/:brokerId",
    "/af/:docType/user/:brokerId/app/:appId",
    "/af/:docType/user/:brokerId/cust/:custId",
  ],
  BUSINESS_FUEL_SELECTION: "/business-fuel",
  BUSINESS_FUEL: [
    "/bf",
    "/bf/user/:brokerId",
    "/bf/user/:brokerId/app/:appId",
    "/bf/user/:brokerId/cust/:custId",
  ],
  BF_SUB_ROUTES: {
    BROKER: "broker",
    BUSINESS_DETAILS: "business-details",
    CLIENT_DETAILS: "client-details",
    FINANCE_NEEDS: "finance-needs",
    DOCUMENTS: "documents",
    SUCCESS: "success",
  },
  AF_SUB_ROUTES: {
    BROKER: "broker",
    BUSINESS_DETAILS: "business-details",
    CLIENT_DETAILS: "client-details",
    FINANCE_NEEDS: "finance-needs",
    FINANCE_DETAILS: "finance-details",
    DOCUMENTS: "documents",
    GUARANTORS: "guarantors",
    BACKGROUND: "background",
    SUCCESS: "success",
  },
  MIND_MATRIX: [
    "/resources",
    "/leads",
    "/email-lists",
    "/contact-lists",
    "/landing-pages",
    "/search-content",
    "/social-channels",
    "/web-campaigns",
    "/campaigns/:id",
    "/email-results",
    "/landing-results",
    "/campaign-results",
    "/resources-results",
    "/social-results",
    "/social-settings",
  ],
  CONTENT_RESOURCE_MANAGEMENT: Object.values(CONTENT_URL_PATHS) as string[],
};
