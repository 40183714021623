import React from "react";
import { connect } from "react-redux";
import logolight from "assets/logos/logo-background-dark.svg";
import ErrorBoundary from "./ErrorBoundary";
import { ReduxState } from "redux-models/reduxStateTypes";

class ErrorBoundaryA extends ErrorBoundary {
  render() {
    const { hasError, error, errorInfo } = this.state;
    if (this.props.auth.error) {
      const { errorCode, stack } = this.props.auth.error;
      return (
        <>
          <section
            style={{
              backgroundColor: "#4615d6",
              borderBottom: "12px solid #ffdf40",
              height: "29.3rem",
              minWidth: "320px",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: "50%",
                top: "7.7rem",
                transform: "translateX(-50%)",
                width: "26.5rem",
                maxWidth: "100%",
                height: "8.1rem",
              }}
            >
              <img
                src={logolight}
                style={{ width: "100%" }}
                alt="scotpac branding"
              />
            </div>
          </section>
          <div
            style={{
              backgroundColor: "#fff",
              position: "absolute",
              top: "18rem",
              left: "50%",
              transform: "translateX(-50%)",
              padding: "5rem",
              borderRadius: "12px",
              boxShadow: "0 0.4rem 1.5rem 0 rgba(0, 0, 0, 0.12)",
              minWidth: "320px",
              maxWidth: "calc(100% -4rem)",
            }}
          >
            <h1
              style={{
                color: "#4615d6",
                fontSize: "3.4rem",
              }}
            >
              Something went wrong:
            </h1>
            <h2
              style={{
                fontSize: "1.6rem",
              }}
            >
              Try reload{" "}
              <button
                style={{
                  color: "#4615d6",
                  background: "inherit",
                  border: "none",
                  textDecoration: "underline",
                  fontSize: "1.6rem",
                }}
                onClick={() => window.location.reload()}
              >
                here
              </button>
            </h2>
            <div style={{ paddingTop: "2rem" }}>{errorCode}</div>
            <div>Details: {stack}</div>
          </div>
        </>
      );
    } else if (hasError) {
      return (
        <>
          <section
            style={{
              backgroundColor: "#4615d6",
              borderBottom: "12px solid #ffdf40",
              height: "29.3rem",
              minWidth: "320px",
            }}
          >
            <div
              style={{
                position: "absolute",
                left: "50%",
                top: "7.7rem",
                transform: "translateX(-50%)",
                width: "26.5rem",
                maxWidth: "100%",
                height: "8.1rem",
              }}
            >
              <img
                src={logolight}
                style={{ width: "100%" }}
                alt="scotpac branding"
              />
            </div>
          </section>
          <div
            style={{
              backgroundColor: "#fff",
              position: "absolute",
              top: "20rem",
              left: "50%",
              transform: "translateX(-50%)",
              padding: "5rem",
              borderRadius: "12px",
              boxShadow: "0 0.4rem 1.5rem 0 rgba(0, 0, 0, 0.12)",
              // width: "65rem",
              minWidth: "320px",
              maxWidth: "calc(100% -4rem)",
              maxHeight: "calc(100vh - 30rem)",
              overflow: "auto",
            }}
          >
            <h1
              style={{
                color: "#4615d6",
                fontSize: "3.4rem",
              }}
            >
              Something went wrong:
            </h1>
            <h2
              style={{
                fontSize: "1.6rem",
              }}
            >
              Try navigating to dashboard <a href="/">here</a> <br />
              Try reload{" "}
              <button
                style={{
                  color: "#4615d6",
                  background: "inherit",
                  border: "none",
                  textDecoration: "underline",
                  fontSize: "1.6rem",
                }}
                onClick={() => window.location.reload()}
              >
                here
              </button>
            </h2>
            <div style={{ paddingTop: "2rem" }}>
              {error && error.toString()}
            </div>
            <div>Details: {errorInfo && errorInfo.componentStack}</div>
          </div>
        </>
      );
    }

    return this.props.children;
  }
}

const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
});

const ErrorBoundaryApp = connect(mapStateToProps)(ErrorBoundaryA);

export { ErrorBoundaryApp };
