import React, { useEffect, FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components/macro";
import { getPromotions } from "actions";
import isEmpty from "helpers/isEmpty";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination } from "swiper";

import WelcomeBack from "components/common/branding/WelcomeBack";
import PromotionCard from "./PromotionCard";
import "./promotions-styles.css";
import { ReduxState } from "redux-models/reduxStateTypes";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination]);

const Container = styled.div`
  width: 100%;
  overflow: hidden;
`;

type PromotionsCarouselProps = {
  active?: boolean;
};

const PromotionsCarousel: FC<PromotionsCarouselProps> = ({ active }) => {
  const { promotions, error } = useSelector(
    (state: ReduxState) => state.promotions
  );
  const dispatch = useDispatch();

  useEffect(() => {
    getPromotions(1, 5, true)(dispatch);
  }, [getPromotions]);

  // don't show an error in UI it will fall back to welcome back but log for debugging
  // @ts-expect-error
  if (error.promo) {
    // @ts-expect-error
    console.log(error.promo);
  }

  return (
    <Container>
      {!isEmpty(promotions) ? (
        <Swiper
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          slidesPerView={1}
          className="mySwiper"
          loop={true}
        >
          {promotions.map((promo) => {
            if (active && promo.isActiveBroker) {
              return (
                <SwiperSlide key={`promo-${promo.id}`}>
                  <PromotionCard {...promo} />
                </SwiperSlide>
              );
            } else if (!active && promo.isInactiveBroker) {
              return (
                <SwiperSlide key={`promo-${promo.id}`}>
                  <PromotionCard {...promo} />
                </SwiperSlide>
              );
            } else {
              return null;
            }
          })}
        </Swiper>
      ) : (
        <WelcomeBack />
      )}
    </Container>
  );
};

export { PromotionsCarousel };
