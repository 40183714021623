import React, { useState } from "react";
import styled from "styled-components/macro";
import { v4 as uuid } from "uuid";
import questionIcon from "assets/icons/custom-icons/icon-question.svg";

const Container = styled.div`
  position: relative;
`;

const Icon = styled.img`
  padding-top: 2.5rem;
  padding-right: 1rem;
`;

const List = styled.ul``;

const Information = styled.div`
  position: absolute;
  display: ${({ $display }) => $display};
  background-color: ${({ theme }) => theme.colors.colorWhite};
  border-radius: 10px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  left: -10rem;
  padding: 1rem;
`;

const ListItem = styled.li`
  list-style-type: none;
`;

const Title = styled.h5`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const QuestionTooltip = ({ acceptedFormats }) => {
  const [display, setDisplay] = useState("none");

  const toggleDisplayOn = () => setDisplay("block");

  const toggleDisplayOff = () => setDisplay("none");

  return (
    <Container onMouseEnter={toggleDisplayOn} onMouseLeave={toggleDisplayOff}>
      <Icon src={questionIcon} />
      <Information $display={display}>
        <Title>Files must not exceed 5MB</Title>
        <Title>Accepted file types:</Title>
        <List>
          {acceptedFormats?.map((format) => {
            return <ListItem key={uuid()}>{format}</ListItem>;
          })}
        </List>
      </Information>
    </Container>
  );
};

export { QuestionTooltip };
