import React from "react";
import styled from "styled-components/macro";

const Container = styled.div``;

const SwitchButton = styled.button<{ active?: any }>`
  border: 1px solid ${(props) => props.theme.colors.primary};
  background: ${(props) =>
    props.active ? props.theme.colors.primary : "inherit"};
  color: ${(props) =>
    props.active ? props.theme.colors.colorWhite : props.theme.colors.primary};
  font-size: 1.6rem;
  padding: 1rem;
  width: 10rem;
  text-align: center;
  margin: 2rem 0;

  &:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-right: none;
  }

  &:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`;

const SwitchButtonGroup = ({
  buttonValues,
  active,
  setActive,
}: {
  buttonValues?: any[];
  active?: any;
  setActive?: any;
}) => {
  return (
    <Container>
      {buttonValues &&
        buttonValues.map((button) => (
          <SwitchButton
            key={button}
            onClick={() =>
              active === button ? setActive("") : setActive(button)
            }
            active={active === button}
          >
            {button}
          </SwitchButton>
        ))}
    </Container>
  );
};

SwitchButtonGroup.propTypes = {};

export { SwitchButtonGroup };
