import styled, { css } from "styled-components/macro";
import { marginSpacing } from "components/common/mixins";

const LargeTitle = styled.h2`
  font-family: ${(props) => props.theme.fontStyles.headings};
  font-size: 5.5rem;
  font-weight: normal;
`;

const MediumTitle = styled.h2`
  font-family: ${(props) => props.theme.fontStyles.headings};
  font-size: 4rem;
  font-weight: normal;
  text-align: center;
  color: ${(props) => props.theme.colors.primary};
`;

const MediumSmalTitle = styled(MediumTitle)`
  font-size: 2.4rem;
`;

const SmallTitle = styled.h5`
  font-family: ${(props) => props.theme.fontStyles.headings};
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  color: ${(props) => props.theme.colors.primary};
`;

const SectionTitle = styled.h2`
  margin-bottom: 3.4rem;
  font-size: 2.5rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.colorDark};
`;

const PageTitle = styled.h1<{ padding?: any }>`
  font-size: 3rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.colorGrayDark};
  padding: ${(props) => props.padding};
`;

const FormTitle = styled.h3<{
  $primary?: any;
  $marginBottom?: any;
  $marginTop?: any;
}>`
  font-family: ${(props) => props.theme.fontStyles.subHeadings};
  font-size: 2rem;
  font-weight: 600;

  ${marginSpacing}

  ${({ $primary }) =>
    $primary &&
    css`
      color: ${(props) => props.theme.colors.primary};
    `}
`;

const LeftTitle = styled.h3`
  color: ${(props) => props.theme.colors.primary};
  font-size: 2.4rem;
  text-align: left;
  margin-bottom: 2rem;
`;

export {
  LargeTitle,
  LeftTitle,
  MediumTitle,
  MediumSmalTitle,
  SmallTitle,
  SectionTitle,
  PageTitle,
  FormTitle,
};
