import { Account } from "redux-models/reduxStateTypes";
import { CONTENT_CATEGORIES, CONTENT_URL_PATHS } from "utils/constants/content";

const loggedInNavOptions = (account: Account, isMindMatrixEnabled: boolean) => {
  type itemType = {
    name: string;
    link: string;
    comingSoon?: boolean;
    inNewTab?: boolean;
  };

  type navType = Omit<itemType, "name" | "link"> & {
    text: string;
    items: itemType[];
    name?: string;
    link?: string;
  };

  const nav: navType[] = [
    {
      text: "Applications",
      items: [
        // {
        //   name: "Apply Asset Finance",
        //   link: "/asset-finance",
        //   comingSoon: !isMindMatrixEnabled,
        // },
        // featureFlags.isBusinessFuelEnabled && {
        //   name: "Apply Business Loan",
        //   link: "/business-fuel",
        // },
        {
          name: "Apply",
          link: "/apply-all",
        },
        { name: "Refer Your Client", link: "/apply-and-refer" },
        { name: "Pending Applications", link: "/pending-applications" },
        { name: "View Application Status", link: "/applications" },
        { name: "View Applications by Applicant", link: "/companies" },
        { name: "View Saved Quotes", link: "/quotes" },
        // {
        //   name: "Secured Loans - Atlas",
        //   link: "https://services.scotpac.com.au/",
        //   inNewTab: true,
        // },
      ] as itemType[],
    },
    {
      text: "Tools",
      items: [
        { name: "Asset Finance Quote", link: "/create-quote" },
        {
          name: "Working Capital Calculator",
          link: "/working-capital-calculator-av",
        },
        // { name: "Product Selector", link: "/product-selector-av" },
        { name: "Product Selector", link: "/product-selector-tool" },
        {
          name: "Secured Loan Serviceability Calculator",
          link: process.env.REACT_APP_FILE_BL_CALC,
          inNewTab: true,
        },
        account.idTokenClaims.role === "admin" && {
          name: "Fast Doc Configuration",
          link: "/fast-doc/settings",
        },
      ] as itemType[],
    },
    {
      text: "Marketing",
      comingSoon: !isMindMatrixEnabled,
      items: [
        {
          name: CONTENT_CATEGORIES.productSalesEmails,
          link: CONTENT_URL_PATHS.productSalesEmails,
        },
        {
          name: CONTENT_CATEGORIES.thoughtLeadershipEmails,
          link: CONTENT_URL_PATHS.thoughtLeadershipEmails,
        },
        {
          name: CONTENT_CATEGORIES.productSummaries,
          link: CONTENT_URL_PATHS.productSummaries,
        },
        {
          name: CONTENT_CATEGORIES.blogs,
          link: CONTENT_URL_PATHS.blogs,
        },
      ] as itemType[],
    },
    {
      text: "Resources",
      comingSoon: !isMindMatrixEnabled,
      items: [
        {
          name: CONTENT_CATEGORIES.industryReportsAndInsights,
          link: CONTENT_URL_PATHS.industryReportsAndInsights,
        },
        {
          name: CONTENT_CATEGORIES.privacyAndOtherForms,
          link: CONTENT_URL_PATHS.privacyAndOtherForms,
        },
      ] as itemType[],
    },
    {
      text: "Help",
      items: [],
      link: "https://www.scotpac.com.au/partner-portal-learning/",
      inNewTab: true,
    },
    {
      text: "Sign out",
      items: [] as itemType[],
      link: "/logout",
    },
    // {
    //   text: "Apply & Refer",
    //   items: getApplyAndRefer(isMindMatrixEnabled),
    // },
  ];

  return nav;
};
const staffNavOptions = [
  {
    text: "Sign out",
    items: [],
    link: "/logout",
  },
];

export { loggedInNavOptions, staffNavOptions };

const getApplyAndRefer = (isMindMatrixEnabled: boolean) => {
  const links = [
    { name: "Fast Doc Configuration", link: "/fast-doc/settings" },
  ];

  return links;
};
