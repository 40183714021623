import { HttpResponseAction } from "utils/api";

export type FastDocErrorReduxState = {
  fastDocErrors: FastDocError[];
};

export type FastDocError = {
  field: string;
  message: string;
};

export const getFastDocErrorStateFromResponse = (
  action?: HttpResponseAction
): Partial<FastDocErrorReduxState> => {
  // if response does not provide fast doc errors, ignore
  if (action?.payload?.state === undefined) {
    return {};
  }

  return {
    fastDocErrors: action.payload.state.errors ?? [],
  };
};

export const isFastDocError = (action?: HttpResponseAction): boolean => {
  return action?.payload?.state?.isValid === false;
};
