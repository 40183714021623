import isEmpty from "helpers/isEmpty";
import {
  CALL_API,
  CLEAR_QUOTE_DATA,
  EMAIL_QUOTE_FAILURE,
  EMAIL_QUOTE_REQUEST,
  EMAIL_QUOTE_SUCCESS,
  GET_QUOTE_FAILURE,
  GET_QUOTE_REQUEST,
  GET_QUOTE_SUCCESS,
  GET_QUOTES_FAILURE,
  GET_QUOTES_REQUEST,
  GET_QUOTES_SUCCESS,
  SAVE_QUOTE_FAILURE,
  SAVE_QUOTE_REQUEST,
  SAVE_QUOTE_SUCCESS,
  UPDATE_FAVORITE_QUOTE_FAILURE,
  UPDATE_FAVORITE_QUOTE_REQUEST,
  UPDATE_FAVORITE_QUOTE_SUCCESS,
  UPDATE_QUOTE_FAILURE,
  UPDATE_QUOTE_REQUEST,
  UPDATE_QUOTE_SUCCESS,
} from "./types";
import { ApiDispatch } from "actions/apiDispatch";
import { QuoteDetails } from "redux-models/assetFinanceActionTypes";
import { stripObject } from "utils/stripObject";

export const clearQuoteData = () => async (dispatch: ApiDispatch) =>
  dispatch({ type: CLEAR_QUOTE_DATA });

/**
 * @route /users/{userId}/quotes
 * @method GET
 * @desc gets all quotes for the user
 * @param {string} userId
 * @param {number} itemsPerPage
 * @param {number} page
 * @param {string} search
 */
export const getAllQuotes = (
  userId: string,
  itemsPerPage: number,
  page: number,
  search: string
) => async (dispatch: ApiDispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/quotes`,
      method: "GET",
      query: { count: itemsPerPage, page: page, term: search },
      types: [GET_QUOTES_REQUEST, GET_QUOTES_SUCCESS, GET_QUOTES_FAILURE],
      cancellable: true,
    },
  });

/**
 * @route /users/{userId}/quotes/af/{quoteId}
 * @method PUT
 * @desc updates a quote
 *
 */
export const updateQuote = (
  userId: string,
  quoteId: number,
  quoteDetails: QuoteDetails
) => async (dispatch: ApiDispatch) => {
  const body = { ...quoteDetails };

  Object.keys(body).forEach(
    // @ts-expect-error
    (key) => (isEmpty(body[key]) || body[key] === 0) && delete body[key]
  );

  return dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/quotes/af/${quoteId}`,
      method: "PUT",
      body: body,
      types: [UPDATE_QUOTE_REQUEST, UPDATE_QUOTE_SUCCESS, UPDATE_QUOTE_FAILURE],
    },
  });
};

/**
 * @route /users/{userId}/quotes/af/{quoteId}
 * @method GET
 * @desc gets a single quote
 * @param {string} userId
 * @param {string} quoteId
 * @param {number} page
 * @param {term} string
 */
export const getQuote = (userId: string, quoteId: string) => async (
  dispatch: ApiDispatch
) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/quotes/af/${quoteId}`,
      method: "GET",
      types: [GET_QUOTE_REQUEST, GET_QUOTE_SUCCESS, GET_QUOTE_FAILURE],
    },
  });

/**
 * @route /users/{userId}/quotes/af
 * @method POST
 * @desc saves a quote
 */
export const saveQuote = (
  userId: string,
  quoteDetails: QuoteDetails,
  sendPrivacyConsentForm?: boolean,
  sendApplicationForm?: boolean,
  email?: boolean
) => async (dispatch: ApiDispatch) => {
  const body = stripObject({ ...quoteDetails });

  const saveQuoteResponse = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/quotes/af`,
      method: "POST",
      body: body,
      types: [SAVE_QUOTE_REQUEST, SAVE_QUOTE_SUCCESS, SAVE_QUOTE_FAILURE],
    },
  });

  if (
    saveQuoteResponse?.type === SAVE_QUOTE_SUCCESS &&
    saveQuoteResponse?.payload &&
    email
  ) {
    const quote = saveQuoteResponse?.payload;
    const sendQuoteResponse = await dispatch({
      [CALL_API]: {
        endpoint: `/v1/users/${userId}/quotes/af/${quote.id}/send`,
        query: {
          withConsentForm: sendPrivacyConsentForm,
          withAppForm: sendApplicationForm,
        },
        method: "POST",
        types: [EMAIL_QUOTE_REQUEST, EMAIL_QUOTE_SUCCESS, EMAIL_QUOTE_FAILURE],
      },
    });

    return {
      success: sendQuoteResponse?.type === EMAIL_QUOTE_SUCCESS,
      id: quote.id,
    };
  } else if (quoteDetails.firstQuote && saveQuoteResponse) {
    await dispatch({
      [CALL_API]: {
        endpoint: `/v1/users/${userId}/quotes/af/${saveQuoteResponse.payload.id}/favorite`,
        method: "POST",
        body: { contractId: saveQuoteResponse.payload.afContractId },
        types: [
          UPDATE_FAVORITE_QUOTE_REQUEST,
          UPDATE_FAVORITE_QUOTE_SUCCESS,
          UPDATE_FAVORITE_QUOTE_FAILURE,
        ],
      },
    });
  }

  return saveQuoteResponse;
};

/**
 * @route /users/{userId}/quotes/af/{quoteId}/send
 * @method POST
 * @desc emails quote to user
 * @param {string} userId
 * @param {string} quoteId
 * @param {number} page
 * @param {boolean} withConsentForm
 * @param {boolean} withAppForm
 */
export const emailQuote = (
  userId: string,
  quoteId: string,
  withConsentForm: boolean,
  withAppForm: boolean
) => async (dispatch: ApiDispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/quotes/af/${quoteId}/send`,
      query: {
        withConsentForm,
        withAppForm,
      },
      method: "POST",
      types: [EMAIL_QUOTE_REQUEST, EMAIL_QUOTE_SUCCESS, EMAIL_QUOTE_FAILURE],
    },
  });

/**
 * @route /users/{userId}/quotes/af/{quoteId}
 * @method PUT
 * @desc updates a quote
 * @param {string} userId
 * @param {string} quoteId
 * @param {object} QuoteDetails
 * @param {boolean} withConsentForm
 * @param {boolean} withAppForm
 */
export const updateThenEmail = (
  userId: string,
  quoteId: string,
  quoteDetails: QuoteDetails,
  withConsentForm: boolean,
  withAppForm: boolean
) => async (dispatch: ApiDispatch) => {
  const body = { ...quoteDetails };

  Object.keys(body).forEach(
    // @ts-expect-error
    (key) => (isEmpty(body[key]) || body[key] === 0) && delete body[key]
  );

  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/quotes/af/${quoteId}`,
      method: "PUT",
      body: body,
      types: [UPDATE_QUOTE_REQUEST, UPDATE_QUOTE_SUCCESS, UPDATE_QUOTE_FAILURE],
    },
  });
  let success = res?.type === UPDATE_QUOTE_SUCCESS;
  if (success) {
    const sendRes = await dispatch({
      [CALL_API]: {
        endpoint: `/v1/users/${userId}/quotes/af/${quoteId}/send`,
        query: {
          withConsentForm,
          withAppForm,
        },
        method: "POST",
        types: [EMAIL_QUOTE_REQUEST, EMAIL_QUOTE_SUCCESS, EMAIL_QUOTE_FAILURE],
      },
    });
    success = sendRes?.type === EMAIL_QUOTE_SUCCESS;
  }
  return { success };
};
