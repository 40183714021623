import { ProductSelectorApplication } from "./productSelectorApplication";
import { createSlice } from "@reduxjs/toolkit";
import {
  PST_SUBMIT_ENQUIRY_REQUEST,
  PST_SUBMIT_ENQUIRY_SUCCESS,
  PST_SUBMIT_ENQUIRY_FAILURE,
} from "actions/types";
import { ProductSelectorRecommendation } from "./productSelectorRecommendation";

type ShouldContact = "ME" | "MY_CLIENT" | "";

export type ProductSelectorEnquiry = {
  shouldContact: ShouldContact;
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: number;
  status: string;
  isDirect: boolean;
  // error: null;
  businessDetails: {
    abn: string;
    name: string;
    address: { state: string; postcode: string };
  };
  productSelectorRecommendation: ProductSelectorRecommendation;
  productSelectorApplication: ProductSelectorApplication;
};

const initialState: ProductSelectorEnquiry = {
  shouldContact: "ME",
  firstName: "",
  lastName: "",
  email: "",
  mobileNumber: NaN,
  status: "idle",
  isDirect: false,
  businessDetails: {
    abn: "",
    name: "",
    address: { state: "", postcode: "" },
  },
  productSelectorRecommendation: {
    pstApplicationId: "",
    assetFinance: "",
    businessFuel: "",
    tradeFinance: "",
    debtorFinance: "",
    isPropertyFlag: false,
    assetFinanceScore: NaN,
    businessFuelScore: NaN,
    tradeFinanceScore: NaN,
    debtorFinanceScore: NaN,
    status: "",
  },
  productSelectorApplication: {
    financingAmount: NaN,
    businessNeed: [],
    industry: "",
    tradingTime: "",
    monthlyRevenue: "",
    howDoYouGetPaid: [],
    ownedAssets: [],
  },
};

const productSelectorEnquiry = createSlice({
  name: "productSelectorEnquiry",
  initialState,
  reducers: {
    setProductSelectorEnquiry(state, action) {
      return { ...state, ...action.payload };
    },
    resetProductSelectorEnquiry() {
      return initialState;
    },
  },
  extraReducers: {
    PST_SUBMIT_ENQUIRY_REQUEST: (state) => {
      return {
        ...state,
        status: "loading",
      };
    },
    PST_SUBMIT_ENQUIRY_SUCCESS: (state) => {
      return {
        ...state,
        status: "success",
      };
    },
    PST_SUBMIT_ENQUIRY_FAILURE: (state) => {
      return {
        ...state,
        status: "failed",
      };
    },
  },
});

export const {
  setProductSelectorEnquiry,
  resetProductSelectorEnquiry,
} = productSelectorEnquiry.actions;

export default productSelectorEnquiry.reducer;
