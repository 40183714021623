import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { marginSpacing, SpacingProps } from "components/common/mixins";

const bigButtonStyles = css<SpacingProps>`
  width: 100%;
  max-width: 40rem;
  height: 5.5rem;
  border-radius: 32.5px;
  overflow: hidden;
  font-size: 2.2rem;
  border: none;

  ${marginSpacing}

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.colorLightGrayBlue};
    cursor: auto;

    &:hover {
      background-color: ${(props) => props.theme.colors.colorLightGrayBlue};
      cursor: auto;
    }
  }
`;

const coralButtonStyles = css`
  background-color: ${(props) => props.theme.colors.secondaryCoral};
  color: ${(props) => props.theme.colors.colorWhite};

  &:hover {
    background-color: ${(props) => props.theme.colors.coralActive};
  }
`;

const outlinePrimaryStyles = css`
  border: solid 2px ${(props) => props.theme.colors.primary};
  background-color: ${(props) => props.theme.colors.colorWhite};
  color: ${(props) => props.theme.colors.primary};

  &:hover {
    background-color: ${(props) => props.theme.colors.buttonHover};
  }
`;

const outlineLightGrayBlue = css`
  background-color: inherit;
  border: 2px solid ${(props) => props.theme.colors.colorLightGrayBlue};
  color: ${(props) => props.theme.colors.colorLightGrayBlue};

  &:hover {
    background-color: ${(props) => props.theme.colors.buttonHover};
  }
`;

const primary = css`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.colorWhite};
  border: none;

  &:hover {
    background-color: ${(props) => props.theme.colors.primaryActive};
  }
`;

const buttonLinkStyles = css`
  display: inline-block;
  text-align: center;
  text-decoration: none;
  line-height: 5.5rem;
`;

const BigButton = styled.button<
  {
    $primary?: any;
    $coral?: any;
    $outlinePrimary?: any;
    $outlineLightGrayBlue?: any;
  } & SpacingProps
>`
  ${bigButtonStyles}

  ${({ $primary }) => $primary && primary}

  ${({ $coral }) => $coral && coralButtonStyles}

  ${({ $outlinePrimary }) => $outlinePrimary && outlinePrimaryStyles}

  ${({ $outlineLightGrayBlue }) =>
    $outlineLightGrayBlue && outlineLightGrayBlue}
`;

const BigButtonLink = styled(Link)<{
  $primary?: any;
  $coral?: any;
  $outlinePrimary?: any;
  $outlineLightGrayBlue?: any;
}>`
  ${bigButtonStyles}
  ${buttonLinkStyles}

  ${({ $primary }) => $primary && primary}

  ${({ $coral }) => $coral && coralButtonStyles}

  ${({ $outlinePrimary }) => $outlinePrimary && outlinePrimaryStyles}

  ${({ $outlineLightGrayBlue }) =>
    $outlineLightGrayBlue && outlineLightGrayBlue}
`;

const BigButtonLinkEmail = styled.a<{
  $coral?: any;
  $outlinePrimary?: any;
}>`
  ${bigButtonStyles}
  ${buttonLinkStyles}

  ${({ $coral }) => $coral && coralButtonStyles}
    
  ${({ $outlinePrimary }) => $outlinePrimary && outlinePrimaryStyles}
`;

export { BigButton, BigButtonLink, BigButtonLinkEmail };
