import dateFormat from "dateformat";
import isEmpty from "./isEmpty";
import moneyFormat from "./moneyFormat";
import { dateFormatsByUse } from "helpers/dateHelpers";
import { Address } from "redux-models/reduxStateTypes";

const tableDollarFormat = (money: any) =>
  !isEmpty(money) ? moneyFormat(money) : " ";
const tableStringFormat = (text: any) => (!isEmpty(text) ? text : " ");
const tableDateFormat = (date: string | number | Date | undefined) =>
  !isEmpty(date)
    ? dateFormat(date, dateFormatsByUse.DATE_FORMAT_TABLE_DISPLAY)
    : " ";

//crm content hub format
const tableContentDateFormat = (date: string | number | Date | undefined) =>
  !isEmpty(date)
    ? dateFormat(date, dateFormatsByUse.DATE_FORMAT_TABLE_CONTENT_DISPLAY)
    : " ";

const handleAddress = (
  address?: Address,
  suburbLevel?: boolean | undefined
) => {
  let summary = "";
  if (!suburbLevel) {
    if (address?.line1) {
      summary += `${address?.line1.trim()}, `;
    }
    if (address?.line2) {
      summary += `${address?.line2.trim()}, `;
    }
    if (address?.line3) {
      summary += `${address?.line3.trim()}, `;
    }
  }
  if (address?.suburb) {
    summary += `${address?.suburb}, `;
  }
  if (address?.state) {
    summary += address?.state + " ";
  }
  if (address?.postcode) {
    summary += address?.postcode;
  }
  if (address?.country) {
    summary += `, ${address?.country.trim()}`;
  }
  return summary;
};

export {
  tableStringFormat,
  tableDollarFormat,
  tableDateFormat,
  tableContentDateFormat,
  handleAddress,
};
