import {
  GET_QUOTES_REQUEST,
  GET_QUOTES_SUCCESS,
  GET_QUOTES_FAILURE,
  UPDATE_QUOTE_REQUEST,
  UPDATE_QUOTE_SUCCESS,
  UPDATE_QUOTE_FAILURE,
  GET_QUOTE_REQUEST,
  GET_QUOTE_SUCCESS,
  GET_QUOTE_FAILURE,
  SAVE_QUOTE_REQUEST,
  SAVE_QUOTE_SUCCESS,
  SAVE_QUOTE_FAILURE,
  EMAIL_QUOTE_REQUEST,
  EMAIL_QUOTE_SUCCESS,
  EMAIL_QUOTE_FAILURE,
  CLEAR_QUOTE_DATA,
} from "actions/types";
import { mapApiErrorToStateLegacy } from "helpers/mappers";
import { loadingStateLegacy } from "helpers/reducerHelpers";
import { loadingQuoteColumns } from "utils/constants";
import { defineQuoteColumns } from "helpers/data-helpers/quotes";

const initialState: any = {
  quotes: [],
  quote: {},
  pageNumber: null,
  columns: loadingQuoteColumns,
  success: null,
  error: null,
  pageSize: null,
  totalPages: null,
  loading: false,
  source: null,
};

const quotes = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_QUOTES_REQUEST:
      return loadingStateLegacy(state);
    case GET_QUOTES_SUCCESS:
      return {
        ...state,
        quotes: action.payload.items,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalPages: action.payload.totalPages,
        columns: defineQuoteColumns(action.payload.items),
        loading: false,
      };
    case GET_QUOTES_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case GET_QUOTE_REQUEST:
      return loadingStateLegacy(state);
    case GET_QUOTE_SUCCESS:
      return { ...state, loading: false, quote: action.payload };
    case GET_QUOTE_FAILURE:
      return {
        ...state,
        loading: false,
        error: { getQuote: true, ...action.payload },
      };
    case SAVE_QUOTE_REQUEST:
      return loadingStateLegacy(state);
    case SAVE_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        quote: { ...action.payload, push: true },
      };
    case SAVE_QUOTE_FAILURE:
      return mapApiErrorToStateLegacy(action, state);
    case UPDATE_QUOTE_REQUEST:
      return loadingStateLegacy(state);
    case UPDATE_QUOTE_SUCCESS:
      return {
        ...state,
        quote: { ...state.quote, ...action.payload },
        loading: false,
      };
    case UPDATE_QUOTE_FAILURE:
      return mapApiErrorToStateLegacy(action, state);
    case EMAIL_QUOTE_REQUEST:
      return loadingStateLegacy(state);
    case EMAIL_QUOTE_SUCCESS:
      return { ...state, success: "Email was sent to client", loading: false };
    case EMAIL_QUOTE_FAILURE:
      return { ...state, loading: false, error: { email: action.payload } };
    case CLEAR_QUOTE_DATA:
      return initialState;
    default:
      return state;
  }
};

export default quotes;
