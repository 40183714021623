import { Dispatch } from "@reduxjs/toolkit";
import { ApiDispatch } from "actions/apiDispatch";
import {
  CALL_API,
  CREATE_COMPANY_REQUEST,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAILURE,
  COMPANY_PREFILL_REQUEST,
  COMPANY_PREFILL_SUCCESS,
  COMPANY_PREFILL_FAILURE,
  GET_ALL_COMPANIES_REQUEST,
  GET_ALL_COMPANIES_SUCCESS,
  GET_ALL_COMPANIES_FAILURE,
  CLEAR_COMPANY_DATA,
  SET_COMPANY,
  GET_ALL_BROKER_COMPANIES_REQUEST,
  GET_ALL_BROKER_COMPANIES_SUCCESS,
  GET_ALL_BROKER_COMPANIES_FAILURE,
} from "./types";
import { UserCompanyAddress } from "redux-models/userTypes";

/**
 *
 * @param {object} company
 * @desc put a company obejct in the global state
 */
export const setCompany = (company: any) => async (dispatch: Dispatch) =>
  dispatch({ type: SET_COMPANY, payload: company });

export const clearCompanyData = () => async (dispatch: Dispatch) =>
  dispatch({ type: CLEAR_COMPANY_DATA });

/**
 * @route /companies
 * @method POST
 * @desc create company from destructured form data
 * @param {Object} formData
 * @param {string} formData.companyName
 * @param {string} formData.abn
 * @param {string} formData.registrationDate
 * @param {Object} formData.address
 * @param {string} formData.address.streetAddress
 * @param {string} formData.address.suburb
 * @param {string} formData.address.postCode
 * @param {string} formData.address.state
 * @param {string} formData.contactNumber
 * @param {bool} formData.isAssetFinance
 * @param {string} formData.aggregator
 * @param {string} userId
 */
export const createCompany = (
  {
    companyName,
    abn,
    registrationDate,
    address,
    contactNumber,
    aggregator,
  }: {
    companyName: string;
    abn: string | null;
    registrationDate: string | null;
    address: UserCompanyAddress;
    contactNumber: string;
    aggregator: string | null;
  },
  userId: string
) => async (dispatch: ApiDispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/company`,
      method: "POST",
      body: {
        companyName,
        abn,
        registrationDate,
        address,
        contactNumber,
        aggregator,
      },
      types: [
        CREATE_COMPANY_REQUEST,
        CREATE_COMPANY_SUCCESS,
        CREATE_COMPANY_FAILURE,
      ],
    },
  });

/**
 * @route /users/{userId}/company
 * @method GET
 * @desc gets companies prefill for a user
 * @param {string} userId
 */
export const companyPrefill = (userId: string) => async (
  dispatch: ApiDispatch
) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/company`,
      method: "GET",
      types: [
        COMPANY_PREFILL_REQUEST,
        COMPANY_PREFILL_SUCCESS,
        COMPANY_PREFILL_FAILURE,
      ],
    },
  });

/**
 * @route /users/{userId}/companies
 * @method GET
 * @desc gets all companies for the user
 * @param {string} userId
 * @param {number} itemsPerPage
 * @param {number} page
 * @param {string} search
 */
export const getAllCompanies = (
  userId: string,
  itemsPerPage: number,
  page: number,
  search: string
) => async (dispatch: ApiDispatch) => {
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/companies`,
      method: "GET",
      query: { count: itemsPerPage, page: page, term: search },
      types: [
        GET_ALL_COMPANIES_REQUEST,
        GET_ALL_COMPANIES_SUCCESS,
        GET_ALL_COMPANIES_FAILURE,
      ],
      cancellable: true,
    },
  });
};

/**
 * @route /companies/brokers
 * @method GET
 * @desc gets all companies for the user
 * @param {string} userId
 * @param {number} itemsPerPage
 * @param {number} page
 * @param {string} search
 */
export const getBrokerCompanies = (
  userId: string,
  itemsPerPage: number,
  page: number,
  search: string
) => async (dispatch: ApiDispatch) => {
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/companies/brokers`,
      method: "GET",
      query: { count: itemsPerPage, page: page, term: search },
      types: [
        GET_ALL_BROKER_COMPANIES_REQUEST,
        GET_ALL_BROKER_COMPANIES_SUCCESS,
        GET_ALL_BROKER_COMPANIES_FAILURE,
      ],
      cancellable: true,
    },
  });
};
