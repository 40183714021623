import { isFastDocRecommendation } from "./getFastDocRecommendationStateFromResponse";
import { HttpResponseAction } from "utils/api";
import { isFastDocError } from "helpers/data-helpers/getFastDocErrorStateFromResponse";

type AfActionResponse = {
  success: boolean;
  isFastDocError: boolean;
  isFastDocRecommendation: boolean;
};

export const actionResponse = (
  successType: string,
  action?: HttpResponseAction
): AfActionResponse => {
  return {
    success: action?.type === successType,
    isFastDocError: isFastDocError(action),
    isFastDocRecommendation: isFastDocRecommendation(action),
  };
};
