import PropTypes from "prop-types";
import styled from "styled-components/macro";
import editIcon from "assets/icons/custom-icons/icon-edit.svg";
import InputContainer from "./InputContainer";
import { FormSubText, Input } from "./styled";

const EditIcon = styled.img`
  position: absolute;
  right: 2rem;
  top: 5rem;
  color: #f7f7f9;
`;

const GeneralInput = ({
  placeholder,
  value,
  name,
  type,
  change,
  error,
  text,
  disabled,
  required,
  subText,
  size,
  handleBlur,
  setFieldValue,
  percentage,
  minLength,
  maxLength,
  dollars,
  lettersOnly,
  phoneNumber,
  edit,
  icon,
  showError,
  linkText,
  onClick,
}: {
  placeholder?: any;
  value?: any;
  name?: any;
  type?: any;
  change?: any;
  error?: any;
  text?: any;
  disabled?: any;
  required?: any;
  subText?: any;
  size?: any;
  handleBlur?: any;
  setFieldValue?: any;
  percentage?: any;
  minLength?: any;
  maxLength?: any;
  dollars?: any;
  lettersOnly?: any;
  phoneNumber?: any;
  edit?: any;
  icon?: any;
  showError?: boolean;
  linkText?: string;
  onClick?: () => void;
}) => {
  const handleNumber: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    const regex = /^[0-9\b]+$/;
    if (value === "" || regex.test(value.toString())) {
      setFieldValue(name, value);
    }
  };

  const handlePhoneNumber: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    const regex = /^[0-9()+\b]+$/;
    if (value === "" || regex.test(value.toString())) {
      setFieldValue(name, value);
    }
  };

  const handlePercentage: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    const regex = /^((0|[1-9]\d?)(\.\d{0,3})?|100(\.00?)?)$/;
    if (value === "" || regex.test(value.toString())) {
      setFieldValue(name, value);
    }
  };

  const handleDollars: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    const regex = /^\d*(\.\d{0,2})?$/;
    if (value === "" || regex.test(value.toString())) {
      setFieldValue(name, value);
    }
  };
  const handleLettersOnly: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (value === "" || regex.test(value.toString())) {
      setFieldValue(name, value);
    }
  };

  return (
    <InputContainer
      error={error}
      name={name}
      text={text}
      required={required}
      icon={icon}
      showError={showError}
      linkText={linkText}
      onClick={onClick}
    >
      <Input
        placeholder={placeholder}
        value={value}
        name={name}
        id={name}
        type={type === "number" ? "text" : type}
        error={error}
        step="any"
        minLength={minLength}
        maxLength={maxLength}
        onChange={(e) => {
          if (type === "number" || (type === "tel" && setFieldValue)) {
            percentage
              ? handlePercentage(e)
              : dollars
              ? handleDollars(e)
              : phoneNumber
              ? handlePhoneNumber(e)
              : handleNumber(e);
          } else {
            if (lettersOnly && setFieldValue) {
              handleLettersOnly(e);
            } else {
              change(e);
            }
          }
        }}
        disabled={disabled}
        onBlur={handleBlur}
      />
      {subText && <FormSubText>{subText}</FormSubText>}
      {edit && <EditIcon src={editIcon} />}
    </InputContainer>
  );
};

GeneralInput.defaultProps = {
  disabled: false,
  required: false,
  size: "default",
};

GeneralInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  change: PropTypes.func,
  error: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  subText: PropTypes.string,
  icon: PropTypes.string,
};

export { GeneralInput };
