import { useSelector } from "react-redux";
import { ReduxState } from "redux-models/reduxStateTypes";
import chmln from "@chamaeleonidae/chmln";

const Chamaeleon = () => {
  const { userId, name, email } = useSelector((state: ReduxState) => ({
    userId: state.auth.account.localAccountId,
    name: state.auth.account.name,
    email: state.auth.account.username,
  }));

  chmln.identify(userId, {
    name: name,
    email: email,
  });
  return null;
};

export default Chamaeleon;
