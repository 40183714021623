import { bulkInviteBroker } from "actions";
import { BULK_INVITE_FAILURE } from "actions/types";
import { MedAButton, MedButton } from "components/common/buttons";
import { OverallErrors } from "components/common/error/OverallErrors";
import { FileInput } from "components/common/inputs";
import { LeftTitle } from "components/common/text";
import isEmpty from "helpers/isEmpty";
import { cutOff } from "helpers/stringHelpers";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "redux-models/reduxStateTypes";
import styled from "styled-components/macro";

const InviteManyContainer = styled.div`
  text-align: left;
  margin-top: 2rem;
  padding-top: 2rem;
`;

const Description = styled.div`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.colorGrayDark};
  margin-bottom: 1rem;
`;

const DownloadButton = styled(MedAButton)`
  width: fit-content;
  padding-left: 2rem;
  padding-right: 2rem;
`;

const InviteMany = ({ reset }: { reset: () => any }) => {
  const [url, setUrl] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string | undefined>(undefined);

  const dispatch = useDispatch();

  const { download, userId } = useSelector((state: ReduxState) => ({
    userId: state.auth.account.localAccountId,
    download: state.users.download,
  }));

  useEffect(() => {
    if (!isEmpty(download)) {
      const csv = new Blob([download], { type: "text/csv" });
      const tempUrl = URL.createObjectURL(csv);

      setUrl(tempUrl);
    }
  }, [download]);

  const inviteBrokers = (e: any) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append(file.name, file);
    setName(file.name);

    bulkInviteBroker(userId, formData)(dispatch);
  };

  return (
    <InviteManyContainer>
      <LeftTitle>Invite Many</LeftTitle>
      <Description>
        Upload a CSV file including named columns firstName, lastName and email
        and a new CSV will be downloaded including invitation links.
      </Description>

      <OverallErrors filterActions={[BULK_INVITE_FAILURE]} />

      {isEmpty(download) ? (
        <FileInput
          label=""
          setFile={inviteBrokers}
          name="uploadCSV"
          accept=".csv, text/csv"
          btnText="Upload CSV"
        />
      ) : (
        <div style={{ marginBottom: "2rem" }}>
          <MedButton
            $outlineLightGrayBlue
            onClick={() => {
              setUrl(undefined);
              setName(undefined);
              reset();
            }}
            width={22}
            $marginRight="2rem"
          >
            Invite Another
          </MedButton>
        </div>
      )}
      {!isEmpty(url) && (
        <DownloadButton
          $outlinePrimary
          width={22}
          href={url}
          download={`${name}`}
          target="_blank"
          rel="noreferrer"
        >
          Download {cutOff(name ?? null, 20)}
        </DownloadButton>
      )}
    </InviteManyContainer>
  );
};

export default InviteMany;
