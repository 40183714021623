import {
  PST_SUBMIT_APPLICATION_FAILURE,
  PST_SUBMIT_APPLICATION_REQUEST,
  PST_SUBMIT_APPLICATION_SUCCESS,
} from "actions/types";

type RecommendationResult = "recommend" | "maybe" | "no" | "";

export type ProductSelectorRecommendation = {
  pstApplicationId: string;
  assetFinance: RecommendationResult;
  businessFuel: RecommendationResult;
  tradeFinance: RecommendationResult;
  debtorFinance: RecommendationResult;
  isPropertyFlag: boolean;
  status: string;
  assetFinanceScore: number;
  businessFuelScore: number;
  tradeFinanceScore: number;
  debtorFinanceScore: number;
  // error: null;
};

const initialState: ProductSelectorRecommendation = {
  pstApplicationId: "",
  assetFinance: "",
  businessFuel: "",
  tradeFinance: "",
  debtorFinance: "",
  isPropertyFlag: false,
  status: "idle",
  assetFinanceScore: NaN,
  businessFuelScore: NaN,
  tradeFinanceScore: NaN,
  debtorFinanceScore: NaN,
  // error: null,
};

const productSelectorRecommendation = (
  state = initialState,
  action: any
): ProductSelectorRecommendation => {
  switch (action.type) {
    case PST_SUBMIT_APPLICATION_REQUEST:
      return {
        ...state,
        status: "loading",
      };
    case PST_SUBMIT_APPLICATION_FAILURE:
      return {
        ...state,
        status: "failed",
      };
    case PST_SUBMIT_APPLICATION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        status: "success",
      };
    default:
      return state;
  }
};

export default productSelectorRecommendation;
