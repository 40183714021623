import { WithRouteProps, withRouter } from "components/common/utils/withRouter";
import React, { FC } from "react";
import { MdClear } from "react-icons/md";
import Modal from "react-modal";
import styled from "styled-components/macro";

const customStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "96.1rem",
    minWidth: "fit-content",
    zIndex: "20",
    textAlign: "center",
    verticalAlign: "middle",
    height: "min-content",
    minHeight: "30rem",
    maxHeight: "98rem",
    borderRadius: "12px",
  },

  overlay: {
    zIndex: "11",
    backgroundColor: "rgba(0, 0, 0, 0.61)",
  },
} as const;

if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

const ExitButton = styled.button`
  position: absolute;
  top: 3.1rem;
  right: 3.1rem;
  border: none;
  outline: none;
  background: inherit;

  &:hover {
    cursor: pointer;
  }
`;

const ModalContentContainer = styled.div`
  min-width: 320px;
  max-height: calc(100vh - 8rem);
`;

const ModalContainer: FC<
  {
    children?: React.ReactNode;
    isOpen: any;
    closeModal: (_: any) => any;
    modalType?: any;
    shouldCloseOnOverlayClick?: any;
    contentStyles?: any;
  } & WithRouteProps
> = ({
  children,
  isOpen,
  closeModal,
  modalType,
  shouldCloseOnOverlayClick = true,
  contentStyles = {},
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc
      style={{
        ...customStyles,
        content: { ...customStyles.content, ...contentStyles },
      }}
      contentLabel=""
      ariaHideApp={process.env.NODE_ENV !== "test"}
    >
      <ExitButton name="exit" onClick={() => closeModal("exit")}>
        <MdClear style={{ fontSize: "4rem", color: "#77889a" }} title="clear" />
      </ExitButton>
      {/* @ts-expect-error */}
      <ModalContentContainer modalType={modalType}>
        {children}
      </ModalContentContainer>
    </Modal>
  );
};

export default withRouter(ModalContainer);
