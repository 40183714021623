import React from "react";
import styled from "styled-components/macro";
import { MdArrowUpward } from "react-icons/md";

const Container = styled.div`
  position: sticky;
  top: calc(100vh - 15rem);
  text-align: center;
  // z-index: ${(props) => props.theme.zIndex.nav};
  width: min-content;
  height: 10rem;
  margin: 2rem auto;
`;

const Text = styled.p`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-family: ${(props) => props.theme.fontStyles.headings};
  font-size: 2rem;
  color: ${(props) => props.theme.colors.primary};
  font-weight: bold;
  white-space: nowrap;
`;

const Button = styled.button`
  height: 7rem;
  width: 7rem;
  border-radius: 50%;
  border: none;
  background-color: ${(props) => props.theme.colors.secondaryCoral};
  margin-bottom: 1rem;
`;

const Icon = styled(MdArrowUpward)`
  font-size: 6rem;
  color: ${(props) => props.theme.colors.colorWhite};
`;

const BackToTop = () => {
  return (
    <Container>
      <Button onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
        <Icon />
      </Button>
      <Text>Back to top</Text>
    </Container>
  );
};

export { BackToTop };
