import styled from "styled-components/macro";

export const Window = styled.div`
  max-width: 1157px;
  min-width: 320px;
  margin: 0 auto;
`;

export const LargeWindow = styled.div`
  max-width: 1760px;
  min-width: 320px;
  margin: 0 auto;
`;
