import {
  BULK_INVITE_FAILURE,
  BULK_INVITE_REQUEST,
  BULK_INVITE_SUCCESS,
  CLEAR_USER_DATA,
  CLEAR_USER_SUCCESS,
  CLEAR_USER_ERROR,
  FAIL_USER_FAILURE,
  FAIL_USER_REQUEST,
  FAIL_USER_SUCCESS,
  GET_PERSONALISATION_FAILURE,
  GET_PERSONALISATION_REQUEST,
  GET_PERSONALISATION_SUCCESS,
  GET_USERS_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  INVITE_BROKER_FAILURE,
  INVITE_BROKER_REQUEST,
  INVITE_BROKER_SUCCESS,
  REFER_CLIENT_FAILURE,
  REFER_CLIENT_REQUEST,
  REFER_CLIENT_SUCCESS,
  SET_USER_ONBOARDING,
  SUSPEND_USER_FAILURE,
  SUSPEND_USER_REQUEST,
  SUSPEND_USER_SUCCESS,
  UPDATE_COMPANY_FAILURE,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPLOAD_COMPANY_IMAGE_FAILURE,
  UPLOAD_COMPANY_IMAGE_REQUEST,
  UPLOAD_COMPANY_IMAGE_SUCCESS,
  UPLOAD_USER_IMAGE_FAILURE,
  UPLOAD_USER_IMAGE_REQUEST,
  UPLOAD_USER_IMAGE_SUCCESS,
  VERIFY_USER_FAILURE,
  VERIFY_USER_REQUEST,
  VERIFY_USER_SUCCESS,
  ADD_INVITE_BROKER_REQUEST,
  ADD_INVITE_BROKER_SUCCESS,
  ADD_INVITE_BROKER_FAILURE,
  CLEAR_INVITED_BROKER,
} from "actions/types";
import { defineUserColumns } from "helpers/data-helpers";
import { mapApiErrorToReduxState } from "helpers/mappers";
import { addPage, loadingState, unloadingState } from "helpers/reducerHelpers";
import { Users } from "redux-models/reduxStateTypes";
import { userColumns } from "utils/constants";

const initialState: Users = {
  success: null,
  loading: false,
  error: null,
  recommendation: null,
  clientReferral: {},
  items: [],
  user: {},
  pageNumber: 1,
  pageSize: 10,
  totalPages: 0,
  companyLogoUrl: "",
  onboarding: false,
  columns: userColumns,
  download: null,
  createdBroker: {},
};

const users = (state = initialState, action: any) => {
  switch (action.type) {
    case REFER_CLIENT_REQUEST:
      return loadingState(state, action.type);
    case REFER_CLIENT_SUCCESS:
      return unloadingState(
        { ...state, clientReferral: action.payload, success: true },
        action.type
      );
    case REFER_CLIENT_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case INVITE_BROKER_REQUEST:
      return loadingState(state, action.type);
    case INVITE_BROKER_SUCCESS:
      return unloadingState(
        { ...state, success: "invite-broker" },
        action.type
      );
    case INVITE_BROKER_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case BULK_INVITE_REQUEST:
      return loadingState(state, action.type);
    case BULK_INVITE_SUCCESS:
      return unloadingState(
        { ...state, download: action.payload, success: "bulk-invite" },
        action.type
      );
    case BULK_INVITE_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case GET_USER_REQUEST:
      return loadingState(state, action.type);
    case GET_USER_SUCCESS:
      return unloadingState({ ...state, user: action.payload }, action.type);
    case GET_USER_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case GET_USERS_REQUEST:
      if (state.getUsersCancelToken) {
        state.getUsersCancelToken.cancel();
      }
      const newState = { ...state, getUsersCancelToken: action.payload };
      return loadingState(newState, action.type);
    case GET_USERS_SUCCESS:
      const updated = addPage(state, action.payload);
      return unloadingState(
        {
          ...state,
          ...updated,
          getUsersCancelToken: undefined,
          columns: defineUserColumns(updated.items),
        },
        action.type
      );
    case GET_USERS_FAILURE:
      return mapApiErrorToReduxState(action, {
        ...state,
        getUsersCancelToken: undefined,
      });

    case VERIFY_USER_REQUEST:
      return loadingState(state, action.type);
    case VERIFY_USER_SUCCESS:
      return unloadingState(
        { ...state, user: { ...state.user, status: "verified" } },
        action.type
      );
    case VERIFY_USER_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case SUSPEND_USER_REQUEST:
      return loadingState(state, action.type);
    case SUSPEND_USER_SUCCESS:
      return unloadingState(
        { ...state, user: { ...state.user, status: "suspended" } },
        action.type
      );
    case SUSPEND_USER_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case FAIL_USER_REQUEST:
      return loadingState(state, action.type);
    case FAIL_USER_SUCCESS:
      return unloadingState(
        { ...state, user: { ...state.user, status: "failed" } },
        action.type
      );
    case FAIL_USER_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case UPDATE_USER_REQUEST:
      return loadingState(state, action.type);
    case UPDATE_USER_SUCCESS:
      return unloadingState(
        { ...state, user: { ...state.user, ...action.payload } },
        action.type
      );
    case UPDATE_USER_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case UPDATE_COMPANY_REQUEST:
      return loadingState(state, action.type);
    case UPDATE_COMPANY_SUCCESS:
      delete action.payload.aggregatorOptions;
      return unloadingState(
        {
          ...state,
          user: {
            ...state.user,
            company: { ...state.user.company, ...action.payload },
          },
        },
        action.type
      );
    case UPDATE_COMPANY_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case UPLOAD_USER_IMAGE_REQUEST:
      return loadingState(state, action.type);
    case UPLOAD_USER_IMAGE_SUCCESS:
      return unloadingState(state, action.type);
    case UPLOAD_USER_IMAGE_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case UPLOAD_COMPANY_IMAGE_REQUEST:
      return loadingState(state, action.type);
    case UPLOAD_COMPANY_IMAGE_SUCCESS:
      return unloadingState(state, action.type);
    case UPLOAD_COMPANY_IMAGE_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case GET_PERSONALISATION_REQUEST:
      return loadingState(state, action.type);
    case GET_PERSONALISATION_SUCCESS:
      return unloadingState({ ...state, ...action.payload }, action.type);
    case GET_PERSONALISATION_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case SET_USER_ONBOARDING:
      return { ...state, onboarding: true };
    case CLEAR_USER_SUCCESS:
      return {
        ...state,
        success: null,
        download: null,
      };
    case CLEAR_USER_ERROR:
      return {
        ...state,
        error: null,
      };

    case CLEAR_USER_DATA:
      return initialState;
    case ADD_INVITE_BROKER_REQUEST:
      return loadingState(state, action.type);
    case ADD_INVITE_BROKER_SUCCESS:
      return unloadingState(
        {
          ...state,
          createdBroker: { ...state.createdBroker, ...action.payload },
        },
        action.type
      );
    case ADD_INVITE_BROKER_FAILURE:
      return mapApiErrorToReduxState(action, state);
    case CLEAR_INVITED_BROKER:
      return { ...state, createdBroker: {} };
    default:
      return state;
  }
};

export default users;
