import isEmpty from "helpers/isEmpty";
import { HttpErrorAction } from "utils/api";

/**
 * @deprecated in favour of ApiErrorReduxState
 *
 * The shape is inconsistent, not clear which code depends on which shapes
 * best to deprecate this behavior and phase it out
 *
 * Switch over to new error handling for more consistency
 */
export type ApiErrorReduxStateLegacy = {
  loading: boolean;

  error?:
    | null
    | Record<string, string | { message: string; fastDoc: true }>
    | HttpErrorAction["payload"];

  firstError?: ApiErrorItemLegacy | null;
  message?: string;
};

/**
 * @deprecated in favour of ApiErrorItem
 *
 * Switch over to new error handling for more consistency
 */
export type ApiErrorItemLegacy = {
  field: string;
  message: string;
};

/**
 * @deprecated in favour of mapApiErrorToReduxState
 *
 * Switch over to new error handling for more consistency
 */
export const mapApiErrorToStateLegacy = <T extends ApiErrorReduxStateLegacy>(
  rawAction: HttpErrorAction,
  state: T
): T => {
  const action = serverErrorFormat(rawAction);

  const err: ApiErrorReduxStateLegacy["error"] = {};
  // @ts-expect-error
  if (action.payload.errors) {
    // @ts-expect-error
    action.payload.errors.forEach((elem) => {
      if (!err.hasOwnProperty(elem.field)) {
        // @ts-expect-error
        err[elem.field] = elem.message;
      }
    });

    return {
      ...state,
      loading: false,
      error: isEmpty(err) ? null : err,
      // @ts-expect-error
      firstError: action.payload.errors[0],
    };
    //Fast Doc Validation Errors
    // @ts-expect-error
  } else if (action.payload.state && action.payload.state.errors) {
    // @ts-expect-error
    action.payload.state.errors.forEach((elem) => {
      if (!err.hasOwnProperty(elem.field)) {
        // @ts-expect-error
        err[elem.field] = {
          message: elem.message,
          fastDoc: true,
        };
      }
    });

    return {
      ...state,
      loading: false,
      error: isEmpty(err) ? null : err,
      // @ts-expect-error
      firstError: action.payload.state.errors[0],
    };
  } else {
    return {
      ...state,
      loading: false,
      // @ts-expect-error
      error: { ...action.payload, statusCode: action.statusCode },
      // @ts-expect-error
      message: action.payload.statusCode + " " + action.payload.message,
    };
  }
};

const serverErrorFormat = (
  action: HttpErrorAction
):
  | HttpErrorAction
  | {
      type: string;
      payload: { errors: Array<{ field: string; message: string }> };
    } => {
  if (action?.statusCode === 500) {
    return {
      payload: {
        errors: [
          {
            field: "Server",
            message: action?.payload.message ?? "",
          },
        ],
      },
      type: action.type,
    };
  } else if (action?.statusCode === 404) {
    return {
      payload: {
        errors: [
          {
            field: "Not Found",
            message: action?.payload.message ?? "",
          },
        ],
      },
      type: action.type,
    };
  } else if (
    action?.statusCode === 400 &&
    !action?.payload?.errors &&
    !action?.payload?.state?.errors
  ) {
    return {
      payload: {
        errors: [
          {
            field: "Bad Request",
            message: action?.payload.message ?? "",
          },
        ],
      },
      type: action.type,
    };
    // @ts-expect-error
  } else if (action?.payload?.state && !action?.payload?.state?.errors) {
    return {
      payload: {
        errors: [
          {
            field: "Server",
            message: action?.payload.message ?? "",
          },
        ],
      },
      type: action.type,
    };
  } else {
    return action;
  }
};
