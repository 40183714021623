import React from "react";
import styled from "styled-components/macro";
import { MdPhone } from "react-icons/md";

const Phone = styled(MdPhone)`
  color: ${(props) => props.theme.colors.secondaryCoral};
  transform: rotate(20deg);
  font-size: 1.8rem;
`;

const Number = styled.a`
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary};
  font-weight: 600;
  font-size: 1.4rem;
`;

const Text = styled.span``;

const Container = styled.div`
  padding-right: 15px;

  @media only screen and (max-width: ${(props) =>
    props.theme.breakpoints.mediumScreen}) {
        display: none;
    }
  }
`;

const Contact = () => {
  return (
    <Container className="contact">
      <Number href="tel:+1300 177 496">
        <Phone />
        <Text>1300 177 496</Text>
      </Number>
    </Container>
  );
};

export default Contact;
