import { Dispatch } from "@reduxjs/toolkit";
import { ApiDispatch } from "actions/apiDispatch";
import {
  CALL_API,
  GET_ALL_APPLICATIONS_REQUEST,
  GET_ALL_APPLICATIONS_SUCCESS,
  GET_ALL_APPLICATIONS_FAILURE,
  GET_ALL_AFAPPLICATIONS_REQUEST,
  GET_ALL_AFAPPLICATIONS_SUCCESS,
  GET_ALL_AFAPPLICATIONS_FAILURE,
  CLEAR_APPLICATION_DATA,
  RETURN_APPLICATION_INITIAL_STATE,
} from "./types";

export const clearApplicationData = () => async (dispatch: ApiDispatch) =>
  dispatch({ type: CLEAR_APPLICATION_DATA });

/**
 * @route /users/{userId}/applications
 * @method GET
 * @desc gets all applications for the user
 * @param {string} userId
 * @param {number} itemsPerPage
 * @param {number} page
 * @param {string} search
 * @param {string} cid
 */
export const getAllApplications = (
  userId: any,
  itemsPerPage: any,
  page: any,
  search: any,
  cid: any
) => async (dispatch: ApiDispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/applications`,
      method: "GET",
      query: { count: itemsPerPage, page: page, term: search, cid },
      types: [
        GET_ALL_APPLICATIONS_REQUEST,
        GET_ALL_APPLICATIONS_SUCCESS,
        GET_ALL_APPLICATIONS_FAILURE,
      ],
      cancellable: true,
    },
  });

/**
 * @route /users/{userId}/afapplications
 * @method GET
 * @desc gets all af applications for the user
 * @param {string} userId
 * @param {number} itemsPerPage
 * @param {number} page
 * @param {boolean} showAll
 */
export const getAllPendingApplications = (
  userId: any,
  itemsPerPage: any,
  page: any,
  showAll: any
) => async (dispatch: ApiDispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/applications/all`,
      method: "GET",
      query: { count: itemsPerPage, page: page, showAll },
      types: [
        GET_ALL_AFAPPLICATIONS_REQUEST,
        GET_ALL_AFAPPLICATIONS_SUCCESS,
        GET_ALL_AFAPPLICATIONS_FAILURE,
      ],
      cancellable: true,
    },
  });

export const returnQuotesInitialState = () => async (dispatch: Dispatch) =>
  dispatch({ type: RETURN_APPLICATION_INITIAL_STATE });
