import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  // REMOVE THIS AFTER YOU HAVE CLEANED UP THE FONTS
  button {
    font-family: 'Poppins', sans-serif;
  }

  body {
    background-color: #F1F2F6;
  }

  @media (max-width: 770px) {
    html {
      font-size: 45%;
    }

    .sm-text {
      font-size: 14px;
    }
  }

  h1,h2,h3,h4,h5 {
    font-family:  'Poppins', 'NotoSerif', sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  button:hover,
  a:hover {
    cursor: pointer;
  }
`;

// Theme from ScotPacs branding guidelines
export const theme = {
  colors: {
    primary: "#4615d6",
    primaryActive: "#23057b",
    primaryDark: "#3d14b7",
    secondaryCoral: "#ff4d63",
    lightCoral: "#fef2f4",
    coralActive: "#e12038",
    secondaryYellow: "#ffde40",
    pastelLilac: "#e1ddfd",
    pastelPurple: "#F4F3FC",
    pastelGreen: "#ddeedc",
    pastelPink: "#f2dde9",
    pastelGray: "#dcddde",
    submitBlue: "#77889a",
    lightPurple: "#7666e6",
    lightGreen: "#d6f5d1",
    lightGreen01: "#4b893b",
    offYellow: "#926500",
    offYellow01: "#f3d39b",
    approvedGreen: "#00c66e",
    pendingGray: "#e5e7ed",
    dividerGray: "#e5e6ea",
    pendingText: "#a6b0bb",
    colorWhite: "#ffffff",
    colorCream: "#fffbe5",
    colorBlack: "#000000",
    colorDark: "#3b3b3b",
    colorGrayd0: "#d0d3d5",
    colorGray5c: "#5c5c5c",
    colorGraye3: "#e3e4ea",
    colorGraye5: "#e5e5e5",
    colorGrayf4: "#f4f4f6",
    colorGrayf8: "#f8f9fb",
    colorGraye2: "#E2E4ED",
    colorGrayd9: "#d9d9d9",
    colorDarkGrey: "#404040",
    colorLigntGray: "#545454",
    colorLightGrayBlue: "#adb8c4",
    colorGrayDark: "#586069",
    inputGray: "#f1f2f6",
    inputTextGray: "#aab4bf",
    buttonHover: "#efedfb",
    lighterPurple: "#e1e6fc",
    businessFuel: "#ff7800",
  },
  breakpoints: {
    largeScreen: "1165px",
    mediumLargerScreen: "1100px",
    mediumLargeScreen: "950px",
    mediumScreen: "770px",
    smallMediumScreen: "550px",
    smallScreen: "450px",
    minimumWidth: "320px",
  },
  zIndex: {
    belowBase: "-1",
    abovebase: "1",
    dropdown: "5",
    important: "10",
    nav: "11",
    overlay: "1000",
  },
  borders: {},
  fontSizes: {
    subText: "1.4rem",
    nav: "1.6rem",
    medium: "2rem",
    regular: "2.2rem",
  },
  fontStyles: {
    headings: '"Noto Serif", sans-serif',
    subHeading: '"Poppins", sans-serif',
  },
  fontWeight: {
    subHeading: "Semibold",
    body: "Regular",
  },
};
