import { breakpoint } from "helpers/data-helpers/breakpoint";
import { useEffect, useState } from "react";
import { theme } from "theme";

type Props = {
  width: number;
  height: number;
};

export function useOffcanvas({ width, height }: Props) {
  const [offcanvas, setOffcanvas] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const handleOpen = () => setOffcanvas(!offcanvas);

  useEffect(() => {
    if (width > breakpoint(theme.breakpoints.largeScreen)) {
      setIsDesktop(false);

      if (offcanvas) {
        handleOpen();
      }
    } else {
      setIsDesktop(true);
    }
  });
  let isActive = isDesktop && offcanvas;

  return {
    offcanvas: isActive,
    handleOpen: handleOpen,
  };
}
