import { ApiDispatch } from "actions/apiDispatch";
import {
  CALL_API,
  MM_GET_LEADS_DATA_REQUEST,
  MM_GET_LEADS_DATA_SUCCESS,
  MM_GET_LEADS_DATA_FAILURE,
  MM_GET_CAMPAIGNS_REQUEST,
  MM_GET_CAMPAIGNS_SUCCESS,
  MM_GET_CAMPAIGNS_FAILURE,
  MM_GET_EMAILS_REQUEST,
  MM_GET_EMAILS_SUCCESS,
  MM_GET_EMAILS_FAILURE,
  MM_GET_NEWSLETTERS_REQUEST,
  MM_GET_NEWSLETTERS_SUCCESS,
  MM_GET_NEWSLETTERS_FAILURE,
  MM_GET_SOCIAL_SYNDICATION_REQUEST,
  MM_GET_SOCIAL_SYNDICATION_SUCCESS,
  MM_GET_SOCIAL_SYNDICATION_FAILURE,
  MM_GET_OVERVIEW_REQUEST,
  MM_GET_OVERVIEW_SUCCESS,
  MM_GET_OVERVIEW_FAILURE,
} from "./types";

/**
 * @route /users/{userId}/media/leadsData
 * @method GET
 * @desc get leads data for specifc user
 * @param {string} userId
 */
export const getLeadsData = (userId: any) => async (dispatch: ApiDispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/media/leads`,
      method: "GET",
      types: [
        MM_GET_LEADS_DATA_REQUEST,
        MM_GET_LEADS_DATA_SUCCESS,
        MM_GET_LEADS_DATA_FAILURE,
      ],
    },
  });

/**
 * @route /users/{userId}/media/campaigns
 * @method GET
 * @desc get campaigns
 * @param {string} userId
 */
export const getCampaigns = (userId: any) => async (dispatch: ApiDispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/media/campaigns`,
      method: "GET",
      types: [
        MM_GET_CAMPAIGNS_REQUEST,
        MM_GET_CAMPAIGNS_SUCCESS,
        MM_GET_CAMPAIGNS_FAILURE,
      ],
    },
  });

/**
 * @route /users/{userId}/media/emails
 * @method GET
 * @desc get emails
 * @param {string} userId
 */
export const getEmails = (userId: any) => async (dispatch: ApiDispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/media/emails`,
      method: "GET",
      types: [
        MM_GET_EMAILS_REQUEST,
        MM_GET_EMAILS_SUCCESS,
        MM_GET_EMAILS_FAILURE,
      ],
    },
  });

/**
 * @route /users/{userId}/media/newsletters
 * @method GET
 * @desc get newsletters
 * @param {string} userId
 */
export const getNewsletters = (userId: any) => async (dispatch: ApiDispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/media/newsletters`,
      method: "GET",
      types: [
        MM_GET_NEWSLETTERS_REQUEST,
        MM_GET_NEWSLETTERS_SUCCESS,
        MM_GET_NEWSLETTERS_FAILURE,
      ],
    },
  });

/**
 * @route /users/{userId}/media/overview
 * @method GET
 * @desc get overview
 * @param {string} userId
 */
export const getOverview = (userId: any) => async (dispatch: ApiDispatch) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/media/overview`,
      method: "GET",
      types: [
        MM_GET_OVERVIEW_REQUEST,
        MM_GET_OVERVIEW_SUCCESS,
        MM_GET_OVERVIEW_FAILURE,
      ],
    },
  });

/**
 * @route /users/{userId}/media/social
 * @method GET
 * @desc get social channels
 * @param {string} userId
 */
export const getSocialChannels = (userId: any) => async (
  dispatch: ApiDispatch
) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/media/social`,
      method: "GET",
      types: [
        MM_GET_SOCIAL_SYNDICATION_REQUEST,
        MM_GET_SOCIAL_SYNDICATION_SUCCESS,
        MM_GET_SOCIAL_SYNDICATION_FAILURE,
      ],
    },
  });
