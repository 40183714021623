import axios from "axios";
import setAuthToken from "utils/axios";
import {
  AAD_LOGIN_SUCCESS,
  AAD_LOGIN_ERROR,
  AAD_LOGIN_FAILED,
  AAD_ACQUIRED_TOKEN_SUCCESS,
  AAD_LOGOUT_SUCCESS,
  AAD_ACQUIRED_ACCESS_TOKEN_ERROR,
  AAD_SET_ACCOUNT_SUCCESS,
  ACCEPT_TERMS_REQUEST,
  ACCEPT_TERMS_SUCCESS,
  ACCEPT_TERMS_FAILURE,
  AAD_ID_TOKEN_HINT_EXPIRED,
  AAD_ID_USER_HINT_MISSING,
} from "actions/types";

const initialState: any = {
  account: {},
  jwtIdToken: "",
  loading: false,
  state: {},
  reqInterceptor: null,
  authenticationError: false,
  error: null,
  apiError: null,
  success: null,
};

const auth = (state = initialState, action: any) => {
  switch (action.type) {
    case AAD_LOGIN_FAILED:
      return { ...state, error: action.payload };
    case AAD_LOGIN_ERROR:
      return { ...state };
    case AAD_LOGIN_SUCCESS:
      axios.interceptors.request.eject(state.reqInterceptor);
      const { reqInterceptor } = setAuthToken(
        // @ts-expect-error
        action.payload.account.idTokenClaims
      );
      return { ...state, ...action.payload, reqInterceptor };
    case AAD_SET_ACCOUNT_SUCCESS:
      axios.interceptors.request.eject(state.reqInterceptor);
      const { reqInterceptor: reqInt } = setAuthToken(
        // @ts-expect-error
        action.payload.account.idTokenClaims
      );
      return { ...state, ...action.payload, reqInterceptor: reqInt };
    case AAD_ACQUIRED_TOKEN_SUCCESS:
      axios.interceptors.request.eject(state.reqInterceptor);
      const { reqInterceptor: req } = setAuthToken(
        // @ts-expect-error
        action.payload.account.idTokenClaims
      );
      return { ...state, ...action.payload, reqInterceptor: req };
    case AAD_LOGOUT_SUCCESS:
      axios.interceptors.request.eject(state.reqInterceptor);
      return { ...initialState };
    case AAD_ACQUIRED_ACCESS_TOKEN_ERROR:
      return { ...initialState };
    case ACCEPT_TERMS_REQUEST:
      return { ...state, loading: true };
    case ACCEPT_TERMS_SUCCESS:
      return { ...state, loading: false, success: true };
    case ACCEPT_TERMS_FAILURE:
      return { ...state, loading: false, apiError: action.payload };
    case AAD_ID_TOKEN_HINT_EXPIRED:
      return { ...initialState, error: action.payload };
    case AAD_ID_USER_HINT_MISSING:
      return { ...initialState, error: action.payload };
    default:
      return state;
  }
};

export default auth;
