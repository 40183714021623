import { BigButton } from "components/common/buttons";
import { InviteModelFormData } from "components/layout/invite-modal/types";
import { useFormikContext } from "formik";
import styled from "styled-components/macro";
import { Title } from "./styled";

const Container = styled.div`
  border-bottom: 1px solid rgb(229, 230, 234);
`;

type InviteSuccessProps = {
  clearUserSuccess: () => any;
  close: () => any;
};

const InviteSuccess = ({ clearUserSuccess, close }: InviteSuccessProps) => {
  const { values, resetForm } = useFormikContext<InviteModelFormData>();

  return (
    <Container>
      <Title>User {values.inviteFirstName} was successfully invited</Title>
      <BigButton
        onClick={() => {
          resetForm();
          clearUserSuccess();
        }}
        $primary
        $marginBottom="2rem"
      >
        Invite another
      </BigButton>
      <BigButton
        onClick={() => {
          resetForm();
          clearUserSuccess();
          close();
        }}
        $outlineLightGrayBlue
      >
        Exit
      </BigButton>
    </Container>
  );
};

export default InviteSuccess;
