import {
  CLEAR_PERSONALISATION_DATA,
  GET_PERSONALISATION_FAILURE,
  GET_PERSONALISATION_REQUEST,
  GET_PERSONALISATION_SUCCESS,
  SET_REMINDER_FAILURE,
  SET_REMINDER_REQUEST,
  SET_REMINDER_SUCCESS,
  UPDATE_USER_SUCCESS,
} from "actions/types";
import { loadingStateLegacy } from "helpers/reducerHelpers";
import { Personalisation } from "redux-models/reduxStateTypes";

const initialState: Personalisation = {
  success: null,
  loading: false,
  error: {},
  companyLogoUrl: "",
  profilePhotoUrl: "",
  reminders: { personalisationDashboard: true },
  isPersonalisationComplete: true,
  isMindMatrixEnabled:
    process.env.REACT_APP_TOGGLE_MIND_MATRIX_FEATURE !== "true",
};

const personalisation = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_PERSONALISATION_REQUEST:
      return loadingStateLegacy(state);
    case GET_PERSONALISATION_SUCCESS:
      return { ...state, ...action.payload, loading: false };
    case GET_PERSONALISATION_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case SET_REMINDER_REQUEST:
      return loadingStateLegacy(state);
    case SET_REMINDER_SUCCESS:
      return {
        ...state,
        loading: false,
        reminders: { personalisationDashboard: true },
      };
    case SET_REMINDER_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        profilePhotoUrl: action.payload.profilePhoto?.url,
      };

    case CLEAR_PERSONALISATION_DATA:
      return initialState;
    default:
      return state;
  }
};

export default personalisation;
