const CLIENT_NAME_COLUMN = "Client Name";
const QUOTE_CREATED_AT_COLUMN = "Created At";
const INTRODUCER_COLUMN_QUOTE = "Introducer";
const ASSET_COST_COLUMN = "Asset Cost";
const INTEREST_RATE_COLUMN = "Interest Rate";
const BROKERAGE_RATE_COLUMN = "Brokerage Rate";

const allQuoteColumns = [
  CLIENT_NAME_COLUMN,
  QUOTE_CREATED_AT_COLUMN,
  INTRODUCER_COLUMN_QUOTE,
  ASSET_COST_COLUMN,
  INTEREST_RATE_COLUMN,
  BROKERAGE_RATE_COLUMN,
];

const loadingQuoteColumns = [
  CLIENT_NAME_COLUMN,
  QUOTE_CREATED_AT_COLUMN,
  ASSET_COST_COLUMN,
  INTEREST_RATE_COLUMN,
  BROKERAGE_RATE_COLUMN,
];

export {
  CLIENT_NAME_COLUMN,
  QUOTE_CREATED_AT_COLUMN,
  INTRODUCER_COLUMN_QUOTE,
  ASSET_COST_COLUMN,
  INTEREST_RATE_COLUMN,
  BROKERAGE_RATE_COLUMN,
  allQuoteColumns,
  loadingQuoteColumns,
};

export const termOptionsStandard = [
  { name: "12 months", value: "12" },
  { name: "24 months", value: "24" },
  { name: "36 months", value: "36" },
  { name: "48 months", value: "48" },
  { name: "60 months", value: "60" },
];

export const handleTermMonths = (
  termMonthsAmount: number,
  termOptionsStandard: {
    name: string;
    value: string;
  }[]
) => {
  const termMonthsMapped = [...Array(termMonthsAmount).keys()]
    .filter((month) => month % 12 !== 0)
    .map((i, idx) => {
      return {
        name: `${i} ${idx === 0 ? "month" : "months"}`,
        value: i.toString(),
      };
    });
  return [...termOptionsStandard, ...termMonthsMapped];
};
