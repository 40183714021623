import styled from "styled-components/macro";

const Title = styled.h3`
  color: ${(props) => props.theme.colors.primary};
  font-size: 3.5rem;
  margin: 3rem 0;
`;

const Info = styled.p`
  font-size: 2.4rem;
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
`;

export { Title, Info };
