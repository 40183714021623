import styled from "styled-components/macro";
import Select from "react-select";

export const StyledSelect = styled(Select)<{ error?: any; $border?: any }>`
  .react-select {
    border: transparent;
    flex-wrap: nowrap;

    &__value-container {
      padding: 0.5rem 0 0.5rem 2.2rem;
    }

    &__placeholder {
      color: ${(props) => props.theme.colors.inputTextGray};
    }

    &__control {
      height: 5.5rem;
      flex-wrap: nowrap;

      &:hover {
        border-color: ${(props) => props.theme.colors.pastelLilac};
      }

      background-color: ${(props) => props.theme.colors.inputGray};
      border: 1px solid
        ${({ error, theme, $border }) =>
          error
            ? theme.colors.coralActive
            : $border
            ? "hsl(0, 0%, 80%)"
            : "transparent"};

      &--is-focused {
        box-shadow: 0 0 0 1px ${(props) => props.theme.colors.pastelLilac};
        box-sizing: border-box;
      }

      &--is-selected {
        border: 1px solid ${(props) => props.theme.colors.pastelLilac};
      }
    }

    background-color: ${(props) => props.theme.colors.colorWhite};

    &__option {
      color: ${(props) => props.theme.colors.inputTextGray};
    }

    &__menu {
      z-index: 4;
    }
  }
`;
