import styled from "styled-components/macro";

const CheckboxContainer = styled.div<{ $noMargin?: any }>`
  display: flex;
  flex-direction: row;
  color: ${(props) => props.theme.colors.colorWhite};

  ${({ $noMargin }) =>
    $noMargin ? "" : "margin-bottom: 2rem;\n  margin-left: 2rem;"}
`;

const CheckboxText = styled.p`
  color: ${(props) => props.theme.colors.colorLightGrayBlue};
  font-size: 1.6rem;
`;

const Input = styled.input`
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const LabelInput = styled.label<{ checked?: any }>`
  width: 2.5rem;
  height: 2.7rem;
  border-radius: 6px;
  border: solid 2px ${(props) => props.theme.colors.colorLightGrayBlue};
  margin-right: 2.5rem;
  color: ${(props) => props.theme.colors.colorWhite};
  background-color: ${(props) =>
    props.checked
      ? props.theme.colors.colorLightGrayBlue
      : props.theme.colors.colorWhite};

  &::before {
    display: inline-block;
    content: "✔";
    font-size: 2.5rem;
    line-height: 1;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.colorLightGrayBlue};
    cursor: pointer;
  }
`;

export { CheckboxContainer, CheckboxText, Input, LabelInput };
