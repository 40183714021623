import { ApiErrorItemLegacy } from "helpers/mappers";
import isEmpty from "helpers/isEmpty";
import { MutableRefObject, useEffect, useRef } from "react";

const triggerFocus = (
  inputRef: MutableRefObject<any>,
  error: any,
  firstError: ApiErrorItemLegacy | undefined | null,
  isMounted: boolean
) => {
  if (
    !isEmpty(error) &&
    inputRef.current &&
    firstError &&
    firstError.message === error &&
    isMounted
  ) {
    try {
      inputRef.current.scrollIntoView({ behavior: "smooth" });
      inputRef.current.focus();
    } catch (err) {}
  }
};

const useInputRef = (
  error: any,
  firstError: ApiErrorItemLegacy | undefined | null
) => {
  const inputRef = useRef<any>();

  useEffect(() => {
    let isMounted = true;
    triggerFocus(inputRef, error, firstError, isMounted);

    return () => {
      isMounted = false;
    };
  }, [error, firstError]);

  return inputRef;
};

export default useInputRef;
