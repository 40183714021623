const AF_APP_NAME_COLUMN = "Name";
const AF_APP_DATE_CREATED_COLUMN = "Date Created";
const AF_APP_FINANCING_AMOUNT = "Financing Amount";
const AF_APP_DOC_TYPE_COLUMN = "Document Type";
const AF_APP_BROKER_COLUMN = "Broker";
const AF_APP_IS_SUBMITTED = "Status";
const AF_APP_DEPOSIT_REQUIRED = "Deposit";

const allAFApplicationColumns = [
  AF_APP_NAME_COLUMN,
  AF_APP_DATE_CREATED_COLUMN,
  AF_APP_FINANCING_AMOUNT,
  AF_APP_DOC_TYPE_COLUMN,
  AF_APP_BROKER_COLUMN,
  AF_APP_IS_SUBMITTED,
  AF_APP_DEPOSIT_REQUIRED,
];

const afApplicationLoadingColumns = [
  AF_APP_NAME_COLUMN,
  AF_APP_DATE_CREATED_COLUMN,
  AF_APP_FINANCING_AMOUNT,
  AF_APP_DOC_TYPE_COLUMN,
  AF_APP_IS_SUBMITTED,
  AF_APP_DEPOSIT_REQUIRED,
];

export {
  AF_APP_NAME_COLUMN,
  AF_APP_DATE_CREATED_COLUMN,
  AF_APP_FINANCING_AMOUNT,
  AF_APP_DOC_TYPE_COLUMN,
  AF_APP_IS_SUBMITTED,
  AF_APP_BROKER_COLUMN,
  allAFApplicationColumns,
  afApplicationLoadingColumns,
  AF_APP_DEPOSIT_REQUIRED,
};
