import React from "react";
import ErrorBoundary from "./ErrorBoundary";

class ErrorBoundaryPage extends ErrorBoundary {
  render() {
    const { hasError, error, errorInfo } = this.state;
    const { text } = this.props;
    if (hasError) {
      return (
        <>
          <div
            style={{
              backgroundColor: "#fff",
              position: "absolute",
              top: "5rem",
              left: "50%",
              transform: "translate(-50%,0)",
              padding: "5rem",
              borderRadius: "12px",
              boxShadow: "0 0.4rem 1.5rem 0 rgba(0, 0, 0, 0.12)",
              minWidth: "320px",
              maxWidth: "calc(100% -4rem)",
            }}
          >
            <h1
              style={{
                color: "#4615d6",
                fontSize: "3.4rem",
              }}
            >
              Something went wrong:
            </h1>
            <h2
              style={{
                fontSize: "1.6rem",
              }}
            >
              A problem occured on {text} page <br />
              Try reload{" "}
              <button
                style={{
                  color: "#4615d6",
                  background: "inherit",
                  border: "none",
                  textDecoration: "underline",
                  fontSize: "1.6rem",
                }}
                onClick={() => window.location.reload()}
              >
                here
              </button>
            </h2>
            <div style={{ paddingTop: "2rem" }}>
              {error && error.toString()}
            </div>
            <div>
              Details: {errorInfo && errorInfo.componentStack.repeat(20)}
            </div>
          </div>
        </>
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundaryPage };
