import {
  ApiErrorReduxState,
  IdModel,
  LoadingState,
  Paging,
} from "redux-models/reduxStateTypes";

/**
 * @deprecated move to typed errors, and use loadingState
 * @param state
 */
export const loadingStateLegacy = <T>(state: T) => ({
  ...state,
  loading: true,
  success: null,
});

export const loadingState = <T extends ApiErrorReduxState>(
  state: T,
  type: string
) => {
  const actions = new Set(state.loadingActions);
  actions.add(actionGroup(type));
  return {
    ...state,
    loadingActions: actions,
    loading: Boolean(actions.size),
    error: null,
    success: null,
  };
};

export const unloadingState = <T extends LoadingState>(
  state: T,
  type: string
) => {
  const actions = new Set(state.loadingActions);
  actions.delete(actionGroup(type));
  return {
    ...state,
    loadingActions: actions,
    loading: Boolean(actions.size),
  };
};

const actionGroup = (action: string) =>
  action
    .split("_")
    .filter((p) => !["REQUEST", "SUCCESS", "FAILURE"].includes(p))
    .join("_");

export const addPage = <T extends IdModel>(
  state: Paging<T>,
  response: Paging<T>
): Paging<T> => {
  let existing = state.items;
  if (response.pageNumber === 1) {
    existing = [];
  }
  return {
    items: [...existing, ...response.items],
    pageNumber: response.pageNumber,
    pageSize: response.pageSize,
    totalPages: response.totalPages,
    loading: false,
  };
};
