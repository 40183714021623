import { OverallErrorContainer, Error } from "components/common/inputs/styled";
import { OverallErrorArgs, useOverallErrors } from "hooks/useErrors";
import { ComponentProps, FC } from "react";

type OverallErrorProps = ComponentProps<typeof OverallErrorContainer> &
  OverallErrorArgs;

export const OverallErrors: FC<OverallErrorProps> = ({
  filterActions,
  reducers,
  ...props
}) => {
  const errors = useOverallErrors({
    filterActions,
    reducers,
  });

  if (!errors.length) {
    return null;
  }

  return (
    <OverallErrorContainer {...props}>
      {errors.map((error) => {
        return <Error key={error}>{error}</Error>;
      })}
    </OverallErrorContainer>
  );
};
