import styled from "styled-components/macro";
import { isStaff } from "helpers/role";

const Container = styled.section`
  position: relative;
  min-width: 100%;
  max-height: 28rem;
  border-bottom: 12px solid
    ${({ role, theme }) =>
      isStaff(role) ? theme.colors.secondaryYellow : theme.colors.primary};
  max-width: 2261px;
  background-color: ${({ role, theme }) =>
    isStaff(role) ? theme.colors.primary : "#dcdde2"};
  display: flex;
  flex-direction: row;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    content: "";
    height: 100%;
    background-color: #fff;
    width: calc(50% - 578px);
  }
`;

const InnerContainer = styled.div`
  width: 1157px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;

  @media (max-width: ${(props) => props.theme.breakpoints.largeScreen}) {
    width: 100%;
    justify-content: space-around;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 40%;
  min-width: fit-content;
  background-color: ${(props) => props.theme.colors.colorWhite};
  padding: 0 1rem 0 8rem;
  overflow: hidden;
  z-index: 2;
  margin-left: -0.1rem;

  @media (max-width: ${(props) => props.theme.breakpoints.mediumScreen}) {
    width: 26rem;
    padding: 2rem 1rem 5rem 3rem;
  }
`;

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: calc(100% - 8rem);
  max-height: 100%;
`;

const Error = styled.div`
  color: ${(props) => props.theme.colors.secondaryCoral};
`;

export { Container, InnerContainer, ImageContainer, Image, Error };
