import React from "react";
import styled from "styled-components/macro";

const Container = styled.div`
  display: block;
  position: absolute;
  bottom: calc(100% + 1.5rem);
  left: ${({ progress }) => progress}%;
  transform: translateX(-2.5rem);
  border-radius: 4px;
  background-color: ${({ progress, theme }) =>
    progress < 100 ? "#23057b" : theme.colors.approvedGreen};
  color: ${(props) => props.theme.colors.colorWhite};
  box-shadow: 0 3px 6px 0 rgba(88, 96, 105, 0.5);
  padding: 0.5rem;
  width: 5rem;
  text-align: center;
`;

const InnerContainer = styled.div`
  font-size: 1.4rem;
`;

const Triangle = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -1rem;
  width: 1rem;
  height: 1rem;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-top: 1rem solid
    ${({ progress, theme }) =>
      progress < 100 ? "#23057b" : theme.colors.approvedGreen};
`;

const MiniTooltip = ({ progress, activeStep }) => {
  return (
    <Container progress={progress}>
      <InnerContainer>
        {progress < 100 ? `Q${activeStep}` : "Done"}
      </InnerContainer>
      <Triangle progress={progress} />
    </Container>
  );
};

export { MiniTooltip };
