export default {
  small: "only screen and (max-width: 450px)",
  medium: "only screen and (max-width: 770px)",
  large: "only screen and (max-width: 1165px)",
};

// largeScreen: "1165px",
// mediumLargerScreen: "1100px",
// mediumLargeScreen: "950px",
// mediumScreen: "770px",
// smallMediumScreen: "550px",
// smallScreen: "450px",
// minimumWidth: "320px"
