import styled from "styled-components/macro";
import { MedButton } from "../buttons";

export const Container = styled.div`
  margin: 18rem 1rem 5rem;
  * {
    font-family: Poppins, sans-serif;
  }
`;

export const FormSettingsContainer = styled.div`
  background-color: ${(props) => props.theme.colors.colorWhite};
  border-radius: 12px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.colors.colorGraye3};
  * {
    font-family: "Noto Serif", sans-serif;
  }
`;

export const FormPageSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakpoints.mediumScreen}) {
    width: 48%;
  }
`;

export const FormColumnsContainer = styled.div`
  background-color: ${(props) => props.theme.colors.colorWhite};
  display: flex;
  flex-direction: column;
  @media (min-width: ${(props) => props.theme.breakpoints.mediumScreen}) {
    flex-direction: row;
  }
  &.success {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`;

export const FormSectionContainer = styled.div`
  padding: 0 20px 20px 20px;
  width: 100%;
  &.company-details {
    margin-top: -6px !important;
  }
  &.primary-contact {
    margin-top: -7px !important;
  }
  &.trust-details {
    margin-top: -10px !important;
  }
  &.asset-finance-contract {
    border-radius: 12px;
    padding: 0;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  }
`;

export const FormFooterButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0 20px;
`;

export const MediumButton = styled(MedButton)<{
  next?: any;
  previous?: any;
  updateCustomer?: any;
}>`
  background-color: ${(props) =>
    props?.next
      ? props.theme.colors.primary
      : props?.previous
      ? "transparent"
      : "transparent"};
  color: ${(props) =>
    props?.next
      ? props.theme.colors.colorWhite
      : props?.previous
      ? props.theme.colors.primary
      : "inherit"};
  border: 2px solid
    ${(props) =>
      props.next
        ? props.theme.colors.primary
        : props?.previous
        ? props.theme.colors.primary
        : "inherit"};
  cursor: ${(props) =>
    props.next
      ? "inherit"
      : props?.previous
      ? props.theme.colors.primary
      : "inherit"};
  &:hover {
    background-color: ${(props) =>
      props.next
        ? props.theme.colors.primary
        : props?.previous
        ? "transparent"
        : "transparent"};
  }
  margin: 0;
  line-height: inherit;
  width: ${(props) => (props.updateCustomer ? "20rem" : "16rem")};
`;

export const StyledFormPageSection = styled(FormPageSection)`
  @media (min-width: ${(props) => props.theme.breakpoints.mediumScreen}) {
    width: 50%;
  }
  * {
    font-family: Poppins, sans-serif;
  }
`;

export const InputsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 2.2rem;
  margin-bottom: 2rem;

  @media (max-width: ${(props) => props.theme.breakpoints.smallScreen}) {
    display: block;
  }
`;
