import errorIcon from "assets/icons/custom-icons/icon-error.svg";
import { ErrorIcon } from "components/common/inputs/InputContainer";
import { FC } from "react";
import { Error, ErrorContainer, ErrorMessageContainer } from "./styled";

export const InputError: FC<{
  error?: string | null;
  id?: string;
  errorHasLinkWord?: string;
  linkText?: string;
  onClick?: () => void;
}> = ({ linkText, error, id, errorHasLinkWord, onClick }) => {
  if (!error) {
    return null;
  }

  let errorMessage = error;
  if (errorHasLinkWord) {
    errorMessage = error.replace(
      new RegExp(errorHasLinkWord, "g"),
      `<a id="link-text" href="javascript:void(0)">${linkText}</a>`
    );

    const link = document.getElementById("link-text");
    if (link) {
      link.addEventListener("click", () => {
        onClick && onClick();
      });
    }
  }

  return (
    <ErrorContainer id={id}>
      <ErrorIcon src={errorIcon} />
      <ErrorMessageContainer>
        <Error bold dangerouslySetInnerHTML={{ __html: errorMessage }} />
      </ErrorMessageContainer>
    </ErrorContainer>
  );
};
