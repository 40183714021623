import { FinanceNeeds } from "redux-models/reduxStateTypes";

export const mapApiToAfFinanceNeeds = (payload: any): FinanceNeeds => {
  if (!payload.afBackground) {
    return {
      financingAmount: undefined,
      financingNeed: undefined,
      isPropertyOwner: undefined,
      monthlyTurnover: undefined,
      nboPresented: undefined,
      productNbo: undefined,
      brokerAcceptedNBO: undefined,
    };
  }
  const isUnfilled =
    payload.afBackground.financingNeed === undefined ||
    payload.financingAmount === 0;

  return {
    financingAmount: payload.financingAmount ?? undefined,
    financingNeed: payload.afBackground.financingNeed,
    monthlyTurnover: payload.monthlyTurnover ?? undefined,
    nboPresented: payload.nboPresented ?? undefined,
    productNbo: payload.productNBO ?? undefined,
    // ensure isPropertyOwner radio in form is left empty if that part of the application
    // hasn't been filled out
    isPropertyOwner: isUnfilled
      ? undefined
      : payload.afBackground.isPropertyOwner,
    brokerAcceptedNBO: payload.brokerAcceptedNBO ?? undefined,
  };
};
