import isEmpty from "helpers/isEmpty";
import { handleAddress } from "helpers/tableHelpers";
import { Individual } from "redux-models/reduxStateTypes";

/**
 * @deprecated
 */
export const mapApiToReduxCustomerDetailsOld = (payload: any) => {
  const values = {
    ...getCustomerDataFromPayloadOld(payload),
    structure: payload.structure,
  };

  return {
    id: values?.id,
    name: values?.name ? values?.name : "",
    country: values?.country,
    type: values?.type,
    arbn: values?.arbn ? values?.arbn : "",
    orgNumber: values?.orgNumber ? values?.orgNumber : "",
    isPrivate:
      typeof values?.isPrivate === "boolean"
        ? values?.isPrivate
          ? values?.isPrivate
          : false
        : null,
    localAgent: values?.localAgent ? values?.localAgent : null,
    isIncorporated:
      typeof values?.isIncorporated === "boolean"
        ? values?.isIncorporated
          ? values?.isIncorporated
          : false
        : null,
    incNumber: values?.incNumber ? values?.incNumber : "",
    incBody: values?.incBody ? values?.incBody : "",
    contactNumber: values?.contactNumber || "",
    addresses: handleAddressesApiOld(values?.addresses ?? [values?.address]),
    trustName: values?.trustName || "",
    trustAbn: values?.trustAbn || "",
    tradingName: values?.tradingName || "",
    businessName: values?.businessName || "",
    abn: values?.abn || "",
    acn: values?.acn || "",
    registration: values?.registration || "",
    gstRegistration: values?.gstRegistration || "",
    website: values?.website ? values?.website : "",
    industry: values?.industry,
    employees: values?.employees,
    structure: values?.structure,
    authorizationCheck: values?.authorizationCheck,
    individuals: handleIndividualsOld(values.individuals),
    directors: handleDirectorsApiOld(values?.directors),
    companies: handleCompaniesOld(values?.companies),
    trusts: handleTrustsOld(values?.trusts),
    soleTraders: handleSoleTradersOld(values?.soleTraders),
  };
};

/**
 * @deprecated
 */
// @ts-expect-error
const handleAddressesApiOld = (addresses) => {
  // @ts-expect-error
  return addresses?.map((address) => {
    return {
      addressLookup: handleAddress(address),
      id: address?.id || "",
      name: address?.name || "",
      type: address?.type || "",
      country: address?.country || "",
      line1: address?.line1 || "",
      line2: address?.line2 || "",
      postcode: address?.postcode || "",
      state: address?.state || "",
      suburb: address?.suburb || "",
    };
  });
};

/**
 * @deprecated
 */
// @ts-expect-error
export const handleAddressApiOld = (address) => {
  return {
    id: address?.id ? address?.id : "",
    name: address?.name || "",
    country: address?.country || "",
    line1: address?.line1 || "",
    line2: address?.line2 || "",
    postcode: address?.postcode || "",
    state: address?.state || "",
    suburb: address?.suburb || "",
  };
};

/**
 * @deprecated
 */
// @ts-expect-error
const handleDirectorsApiOld = (directors) => {
  // @ts-expect-error
  return directors?.map((director) => {
    return {
      id: director?.id,
      isDirector: director?.isDirector,
      isOwner: director?.isOwner,
      isPrimaryContact: director?.isPrimaryContact,
      isShareholder: director?.isShareholder,
      individual: handleIndividualOld(director.individual),
    };
  });
};

/**
 * @deprecated
 */
// @ts-expect-error
export const handleSoleTradersOld = (soleTraders) => {
  return soleTraders?.length > 0
    ? // @ts-expect-error
      soleTraders.map((soleTrader) => {
        return {
          abnLookup: soleTrader?.abnLookup ?? "",
          id: soleTrader?.id ?? "",
          abn: soleTrader?.abn ?? "",
          name: soleTrader?.name ?? "",
          tradingName: soleTrader?.tradingName ?? "",
          registration: soleTrader?.registration ?? "",
          gstRegistration: soleTrader?.gstRegistration ?? "",
          website: soleTrader?.website ?? "",
          industry: soleTrader?.industry ?? "",
          individual: handleIndividualsOld(soleTrader.individuals),
          addresses: handleAddressesApiOld(
            soleTrader?.addresses ?? [soleTrader?.address]
          ),
        };
      })
    : [];
};

/**
 * @deprecated
 */
// @ts-expect-error
export const handleTrustsOld = (trusts) => {
  return trusts?.length > 0
    ? // @ts-expect-error
      trusts?.map((trust) => {
        return {
          id: trust?.id ?? "",
          type: trust?.type ?? "",
          name: trust?.name ?? "",
          businessName: trust?.businessName ?? "",
          abn: trust?.abn ?? "",
          registration: trust?.registration ?? "",
          gstRegistration: trust?.gstRegistration ?? "",
          individuals: handleIndividualsOld(trust.individuals),
          companies: handleCompaniesOld(trust?.companies),
        };
      })
    : [];
};

/**
 * @deprecated
 */
// @ts-expect-error
export const handleCompaniesOld = (companies) => {
  const companyMapped =
    companies?.length > 0
      ? // @ts-expect-error
        companies?.map((company) => {
          return {
            id: company?.id,
            name: company?.name ? company?.name : "",
            country: company?.country,
            type: company?.type,
            arbn: company?.arbn ? company?.arbn : "",
            orgNumber: company?.orgNumber ? company?.orgNumber : "",
            isPrivate:
              typeof company?.isPrivate === "boolean"
                ? company?.isPrivate
                  ? company?.isPrivate
                  : false
                : null,
            localAgent: company?.localAgent ? company?.localAgent : null,
            isIncorporated:
              typeof company?.isIncorporated === "boolean"
                ? company?.isIncorporated
                  ? company?.isIncorporated
                  : false
                : null,
            incNumber: company?.incNumber ? company?.incNumber : "",
            incBody: company?.incBody ? company?.incBody : "",
            contactNumber: company?.contactNumber || "",
            addresses: handleAddressesApiOld(
              company?.addresses ?? [company?.address]
            ),
            trustName: company?.trustName || "",
            trustAbn: company?.trustAbn || "",
            tradingName: company?.tradingName || "",
            businessName: company?.businessName || "",
            abn: company?.abn || "",
            acn: company?.acn || "",
            registration: company?.registration || "",
            gstRegistration: company?.gstRegistration || "",
            website: company?.website ? company?.website : "",
            industry: company?.industr,
            employees: company?.employees,
            structure: company?.structure,
            individuals: handleIndividualsOld(company.individuals),
            directors: handleDirectorsApiOld(company?.directors),
          };
        })
      : [];

  return companyMapped;
};

/**
 * @deprecated
 */
export const handleIndividualOld = (individual: any): Individual => {
  return {
    ...individual,
    id: individual?.id ?? "",
    addresses: handleAddressApiOld(individual?.address),
    idType: individual?.idType,
    idNumber: individual?.idNumber ?? "",
    email: individual?.email ?? "",
    firstName: individual?.firstName ?? "",
    lastName: individual?.lastName ?? "",
    middleName: individual?.middleName ?? "",
    phone: individual?.phone ?? "",
  };
};

/**
 * @deprecated
 */

const handleIndividualsOld = (individuals: any[]) => {
  return individuals?.length > 0
    ? individuals?.map((individual) => handleIndividualOld(individual))
    : [];
};

/**
 * @deprecated
 */
const getCustomerDataFromPayloadOld = (payload: any) => {
  if (!isEmpty(payload?.company)) {
    return payload.company;
  } else if (!isEmpty(payload?.sole)) {
    return payload.sole;
  } else if (!isEmpty(payload?.soleTrader)) {
    return payload.soleTrader;
  } else if (!isEmpty(payload?.trust)) {
    return payload.trust;
  } else if (!isEmpty(payload?.foreign)) {
    return payload.foreign;
  } else if (!isEmpty(payload?.foreignCompany)) {
    return payload.foreignCompany;
  } else if (!isEmpty(payload?.gov)) {
    return payload.gov;
  } else if (!isEmpty(payload?.government)) {
    return payload.government;
  } else if (!isEmpty(payload?.coop)) {
    return payload.coop;
  } else if (!isEmpty(payload?.cooperative)) {
    return payload.cooperative;
  } else if (!isEmpty(payload?.assoc)) {
    return payload.assoc;
  } else if (!isEmpty(payload?.association)) {
    return payload.association;
  } else if (!isEmpty(payload?.partnership)) {
    return payload.partnership;
  }
};
