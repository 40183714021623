import PropTypes from "prop-types";
import Select, { i18nTimezones } from "react-timezone-select";
import InputContainer from "./InputContainer";

const scotpacTimezones = { ...i18nTimezones };

Object.keys(scotpacTimezones).forEach((key) => {
  if (
    !key.match(/(Australia)|(Auckland)/) ||
    key.includes("Hobart") ||
    key.includes("Brisbane")
  ) {
    delete scotpacTimezones[key];
  }
});

const customStyles = {
  container: (provided: any) => ({
    ...provided,
  }),
  control: (provided: any) => ({
    ...provided,
    border: "none",
    backgroundColor: "#f1f2f6",
    height: "5.5rem",
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: "none",
  }),
};

const TimezoneSelect = ({
  error,
  name,
  icon,
  text,
  value,
  change,
}: {
  error?: any;
  name?: any;
  icon?: any;
  text?: any;
  value?: any;
  change?: any;
}) => {
  return (
    <InputContainer error={error} name={name} icon={icon} text={text}>
      {value && (
        <Select
          value={value}
          name={name}
          onChange={change}
          labelStyle="abbrev"
          timezones={scotpacTimezones}
          styles={customStyles}
        />
      )}
    </InputContainer>
  );
};

TimezoneSelect.propTypes = {
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  change: PropTypes.func.isRequired,
};

export { TimezoneSelect };
