// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  authDomain: process.env.REACT_APP_GOOGLE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_GOOGLE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_GOOGLE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_GOOGLE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_GOOGLE_APP_ID,
  measurementId: process.env.REACT_APP_GOOGLE_MEASUREMENT_ID,
};

let app, analytics;

if (process.env.NODE_ENV !== "test") {
  // Initialize Firebase
  app = initializeApp(firebaseConfig);
  analytics = getAnalytics(app);
}

function logSession(account) {
  setUserId(analytics, account.localAccountId);
  setUserProperties(analytics, {
    company_id: account.idTokenClaims?.companyId,
    role: account.idTokenClaims?.role,
  });
  logEvent(analytics, "login");
}

function clearSession() {
  setUserId(analytics, null);
  setUserProperties(analytics, {});
  logEvent(analytics, "logout");
}

function logPageView(pathname) {
  logEvent(analytics, "page_view", {
    page_location: pathname,
  });
}

function logAssetFinanceStart(isFullDoc) {
  logEvent(analytics, "af_app_start", {
    content_type: isFullDoc ? "fulldoc" : "fastdoc",
  });
}

function logAssetFinanceComplete(isFullDoc) {
  logEvent(analytics, "af_app_complete", {
    content_type: isFullDoc ? "fulldoc" : "fastdoc",
  });
}

export {
  logSession,
  clearSession,
  logPageView,
  logAssetFinanceStart,
  logAssetFinanceComplete,
};
