import React from "react";
import styled from "styled-components/macro";

const ErrorStatusCode = styled.p<{ $fontSize?: number | string }>`
  font-size: ${({ $fontSize }) => $fontSize};
  color: ${(props) => props.theme.colors.colorBlack};
`;

const ErrorStatusCodeValue = styled.span`
  color: ${(props) => props.theme.colors.secondaryCoral};
`;

const ErrorMessage = styled.p<{ $fontSize?: number | string }>`
  font-size: ${({ $fontSize }) => $fontSize};
`;

export const ErrorDescription = ({
  statusCode,
  message,
  fontSize = "1.2rem",
}: {
  statusCode?: string | number;
  message: React.ReactNode;
  fontSize?: number | string;
}) => {
  return (
    <>
      <ErrorStatusCode $fontSize={fontSize}>
        Status: <ErrorStatusCodeValue>{statusCode}</ErrorStatusCodeValue>
      </ErrorStatusCode>
      <ErrorMessage $fontSize={fontSize}>Error: {message}</ErrorMessage>
    </>
  );
};
