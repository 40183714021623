import React from "react";
import styled from "styled-components/macro";

const Container = styled.button`
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  font-weight: 500;
  color: ${(props) => props.theme.colors.colorWhite};
  font-size: 16px;
  text-decoration: none;
  text-align: center;
  outline: none;
  background: ${(props) => props.theme.colors.secondaryCoral};
  border: none;
  line-height: 1;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.coralActive};
    color: ${(props) => props.theme.colors.colorWhite};
  }
`;

type AvatarProps = {
  handleClick: () => void;
};

const Avatar = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<AvatarProps>
>(({ children, handleClick }, ref) => {
  return (
    <Container onClick={handleClick} ref={ref}>
      {children}
    </Container>
  );
});

export default Avatar;
