import {
  GET_ALL_APPLICATIONS_REQUEST,
  GET_ALL_APPLICATIONS_SUCCESS,
  GET_ALL_APPLICATIONS_FAILURE,
  PST_SUBMIT_APPLICATION_SUCCESS,
} from "actions/types";
import isEmpty from "helpers/isEmpty";
import { defineApplicationColumns } from "helpers/data-helpers";
import { applicationLoadingColumns } from "utils/constants";
import { Applications } from "redux-models/reduxStateTypes";

const initialState: Applications = {
  allApplications: [],
  pageNumber: null,
  columns: applicationLoadingColumns,
  success: null,
  pageSize: null,
  totalPages: null,
  loading: false,
  source: null,
  error: null,
  term: null,
};

const applications = (state = initialState, action: any): Applications => {
  switch (action.type) {
    case GET_ALL_APPLICATIONS_REQUEST:
      action.payload && state.source && state.source.cancel();
      return {
        ...state,
        loading: true,
        source: action.payload,
        success: null,
        error: null,
      };
    case GET_ALL_APPLICATIONS_SUCCESS:
      return {
        ...state,
        allApplications: action.payload.items,
        columns: defineApplicationColumns(action.payload.items),
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
        totalPages: action.payload.totalPages,
        loading: false,
        term: action.payload.term ? action.payload.term : "",
        active: !isEmpty(action.payload.items),
      };
    case GET_ALL_APPLICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default applications;
