import React from "react";
import styled from "styled-components/macro";

const TabsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 7rem;
  width: 76.4rem;
  max-width: 100%;
  margin: 0 auto;
  z-index: ${(props) => props.theme.zIndex.important};
`;

const Tab = styled.div<{ off?: any }>`
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  background-color: ${({ off, theme }) =>
    off ? theme.colors.primaryDark : theme.colors.colorWhite};
  padding: 1.8rem 3.5rem 1.8rem 3.4rem;
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSizes.medium};
  color: ${(props) =>
    props.off ? "rgba(255, 255, 255, 0.35)" : props.theme.colors.primary};

  @media (max-width: ${(props) => props.theme.breakpoints.mediumScreen}) {
    padding: 1rem 1.8rem 1rem;
  }
`;

function Tabs({
  tabs,
}: {
  tabs: Array<{ name: string; isActive?: any; text?: React.ReactNode }>;
}) {
  return (
    <TabsContainer>
      {tabs.map((tab) => {
        return (
          <Tab key={tab.name} off={!tab.isActive}>
            {tab.text}
          </Tab>
        );
      })}
    </TabsContainer>
  );
}

export { Tabs };
