import React from "react";
import {
  FlexBetween,
  Flex,
  Triangle,
  Info,
  Title,
  InfoContainer,
  Container,
  Dismiss,
} from "./styled";

const SpeechBubbleTip = ({
  titleText,
  infoText,
  dismissText,
  display,
  top,
  offsetY,
  offsetX,
  dismiss,
}) => {
  return (
    <Container $display={display} top={top} offsetY={offsetY} offsetX={offsetX}>
      <Flex>
        <InfoContainer>
          {titleText && <Title>{titleText}</Title>}
          {infoText && <Info>{infoText}</Info>}
        </InfoContainer>
      </Flex>
      <FlexBetween>
        <Dismiss onClick={dismiss}>{dismissText}</Dismiss>
      </FlexBetween>
      <Triangle top={top} />
    </Container>
  );
};

export { SpeechBubbleTip };
