import React from "react";
import styled from "styled-components/macro";
import { BsStopwatch } from "react-icons/bs";

const Container = styled.div`
  position: absolute;
  bottom: -100%;
  right: -3rem;
  border-radius: 12px;
  background-color: ${(props) => props.theme.colors.colorGrayDark};
  color: ${(props) => props.theme.colors.colorWhite};
  display: flex;
  padding: 2rem;
  width: 30rem;

  @media (max-width: ${(props) => props.theme.breakpoints.largeScreen}) {
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    opacity: 0.7;
    justify-content: center;
    border-radius: 0;
  }
`;

const Icon = styled(BsStopwatch)`
  font-size: 5.5rem;
  margin-right: 2rem;

  @media (max-width: ${(props) => props.theme.breakpoints.largeScreen}) {
    font-size: 4rem;
  }
`;

const InfoContainer = styled.div``;

const Title = styled.p`
  font-size: 2.4rem;
  font-family: ${(props) => props.theme.fontStyles.headings};

  @media (max-width: ${(props) => props.theme.breakpoints.largeScreen}) {
    font-size: 1.8rem;
  }
`;

const Text = styled.p`
  font-size: 1.2rem;
  font-family: ${(props) => props.theme.fontStyles.headings};

  @media (max-width: ${(props) => props.theme.breakpoints.largeScreen}) {
    font-size: 1rem;
  }
`;

const Triangle = styled.div`
  position: absolute;
  right: 3.2rem;
  top: -2rem;
  // transform: translateY(-50%);
  width: 2rem;
  height: 2rem;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: 2rem solid ${(props) => props.theme.colors.colorGrayDark};

  @media (max-width: ${(props) => props.theme.breakpoints.largeScreen}) {
    display: none;
  }
`;

const NavComingSoon = () => {
  return (
    <Container>
      <Icon />
      <InfoContainer>
        <Title>Coming Soon</Title>
        <Text>We'll keep you up to date</Text>
      </InfoContainer>
      <Triangle />
    </Container>
  );
};

export { NavComingSoon };
