import { InputError } from "components/common/inputs/InputError";
import { FC } from "react";
import styled from "styled-components/macro";
import { Asterisk, InputContainer as Container, Label } from "./styled";

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Icon = styled.img`
  width: 14px;
  cursor: pointer;
`;

export const ErrorIcon = styled.img`
  width: 21px;
  height: 21px;
  margin-right: 5px;
`;

interface IInputContainerProps {
  text?: string;
  name?: string;
  error?: string;
  required?: boolean;
  icon?: string | null;
  inputRef?: any;
  children?: any;
  size?: string;
  showError?: boolean;
  linkText?: string;
  onClick?: () => void;
}

const InputContainer: FC<IInputContainerProps> = ({
  text,
  error,
  inputRef,
  name,
  children,
  required,
  icon,
  showError = true,
  linkText,
  onClick,
}) => {
  return (
    <Container ref={inputRef}>
      {text ? (
        <LabelContainer>
          <Label htmlFor={name} aria-labelledby={name}>
            {text}
            {required && <Asterisk>*</Asterisk>}
          </Label>
          {icon && <Icon src={icon} />}
        </LabelContainer>
      ) : null}
      {children}
      {showError && (
        <InputError
          onClick={onClick}
          linkText={linkText}
          id={`error-${name}`}
          error={error}
        />
      )}
    </Container>
  );
};

export default InputContainer;
