import React, { useRef } from "react";
import styled from "styled-components/macro";
import PropTypes from "prop-types";
import isEmpty from "helpers/isEmpty";
import InputContainer from "./InputContainer";
import { StyledSelect } from "./Select.styled";
import { DropdownButton, DropdownItem } from "../dropdowns/Dropdown.styles";
import { components } from "react-select";

const Button = styled(DropdownButton)`
  font-size: 2rem;
  font-weight: 400;
  font-family: ${({ theme }) => theme.fontStyles.subHeading};
`;

const Input = ({
  autoComplete,
  ...props
}: React.ComponentProps<typeof components.Input>) => {
  return (
    <components.Input {...props} autoComplete="off" data-lpignore="true" />
  );
};

const Option = ({
  innerProps,
  innerRef,
  children,
}: {
  innerProps?: React.ComponentProps<typeof DropdownItem>;
  children?: React.ReactNode;
  innerRef?: any;
}) => {
  return (
    <>
      <DropdownItem role="option" ref={innerRef} {...innerProps}>
        <Button highlightPastel>{children}</Button>
      </DropdownItem>
    </>
  );
};

const Select = ({
  placeholder,
  name,
  change,
  error,
  text,
  value,
  options,
  labelField,
  valueField,
  required,
  disabled,
  handleBlur,
  icon,
  getOptionLabel,
  border,
  // Pageable related arguments
  filterOption,
  isPageable,
  setSearch,
  page = 1,
  setPage,
  lastPage,
  loading,
  CustomOption,
}: {
  placeholder?: any;
  name?: any;
  change: (name: string, value: Array<{ value: string }> | null) => any;
  error?: any;
  text?: string;
  value?: any;
  options?: any;
  valueField?: any;
  required?: any;
  disabled?: any;
  handleBlur?: any;
  icon?: any;
  labelField?: string;
  getOptionLabel?: (option: any) => string;
  border?: any;
  filterOption?: any;
  isPageable?: any;
  setSearch?: any;
  page?: number;
  setPage?: any;
  lastPage?: any;
  loading?: boolean;
  CustomOption?: any;
}) =>
  // ref
  {
    const selectRef = useRef();

    return (
      <InputContainer
        error={error}
        text={text}
        name={name}
        required={required}
        icon={icon}
      >
        {/* @ts-expect-error */}
        <StyledSelect
          id={name}
          error={error}
          autoComplete="off"
          ref={selectRef}
          classNamePrefix="react-select"
          getOptionLabel={(option: any) =>
            getOptionLabel
              ? getOptionLabel(option)
              : option[labelField ?? "label"]
          }
          getOptionValue={(option: any) => option[valueField]}
          name={name}
          cacheOptions
          value={value}
          filterOption={
            filterOption
              ? (option, input) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
              : false
          }
          options={options}
          isClearable={true}
          backspaceRemovesValue={true}
          onInputChange={(e) => {
            if (!filterOption && isPageable) {
              setSearch(e);
              setPage(1);
            }
          }}
          isLoading={loading}
          onMenuScrollToBottom={() => {
            if (isPageable && !lastPage) {
              setPage(page + 1);
            }
          }}
          onChange={(val: { value: string }) =>
            change(name, val ? [val] : null)
          }
          isDisabled={disabled}
          components={
            CustomOption
              ? { Option: CustomOption, Input: Input }
              : { Option: Option, Input: Input }
          }
          placeholder={
            isEmpty(value) ? (
              placeholder
            ) : (
              <p style={{ display: "none" }}>{placeholder}</p>
            )
          }
          onBlur={() => {
            handleBlur && handleBlur({ target: { name } });
          }}
          $border={border}
        />
      </InputContainer>
    );
  };

Select.propTypes = {
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  error: PropTypes.string,
  text: PropTypes.string.isRequired,
  labelField: PropTypes.string.isRequired,
  valueField: PropTypes.string.isRequired,
  value: PropTypes.array,
};

Select.defaultProps = {
  value: [{}],
  required: false,
  filterOption: true,
  isPreRefactor: true,
  border: false,
};

export { Select };
