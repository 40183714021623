import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import logo from "assets/logos/logo-background-light.svg";

const linkStyles = css`
  display: block;
  width: 15.7rem;
  height: 5rem;
`;

const BrandContainer = styled(Link)`
  ${linkStyles}
`;

const BrandingContainerExternal = styled.a`
  ${linkStyles}
`;

const BrandImg = styled.img`
  width: 100%;
`;

function NavBrand({ isPublic }) {
  return isPublic ? (
    <BrandingContainerExternal href="https://www.scotpac.com.au/">
      <BrandImg src={logo} />
    </BrandingContainerExternal>
  ) : (
    <BrandContainer to="/">
      <BrandImg src={logo} />
    </BrandContainer>
  );
}

NavBrand.defaultProps = {
  isPublic: false,
};

NavBrand.propTypes = {
  isPublic: PropTypes.bool.isRequired,
};

export default NavBrand;
