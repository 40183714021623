import {
  ADD_ASSET_FAILURE,
  ADD_ASSET_REQUEST,
  ADD_ASSET_SUCCESS,
  CLEAR_AF_CONTRACT_SUCCESS,
  CLEAR_ASSET_FINANCE_ERROR,
  CREATE_CONTRACT_FAILURE,
  CREATE_CONTRACT_REQUEST,
  CREATE_CONTRACT_SUCCESS,
  GET_AF_APPLICATION_SUCCESS,
  GET_ASSET_CATEGORY_FAILURE,
  GET_ASSET_CATEGORY_REQUEST,
  GET_ASSET_CATEGORY_SUCCESS,
  GET_CONTRACT_FAILURE,
  GET_CONTRACT_REQUEST,
  GET_CONTRACT_SUCCESS,
  REMOVE_ASSET_FAILURE,
  REMOVE_ASSET_REQUEST,
  REMOVE_ASSET_SUCCESS,
  REMOVE_CONTRACT_FAILURE,
  REMOVE_CONTRACT_REQUEST,
  REMOVE_CONTRACT_SUCCESS,
  REMOVE_QUOTE_FAILURE,
  REMOVE_QUOTE_REQUEST,
  REMOVE_QUOTE_SUCCESS,
  RESET_ASSET_FINANCE_STATE,
  RETURN_ASSET_FINANCE_CONTRACT_INITIAL_STATE,
  SAVE_QUOTE_FAILURE,
  SAVE_QUOTE_REQUEST,
  SAVE_QUOTE_SUCCESS,
  UPDATE_ASSET_FAILURE,
  UPDATE_ASSET_REQUEST,
  UPDATE_ASSET_SUCCESS,
  UPDATE_FAVORITE_QUOTE_FAILURE,
  UPDATE_FAVORITE_QUOTE_REQUEST,
  UPDATE_FAVORITE_QUOTE_SUCCESS,
  UPDATE_QUOTE_FAILURE,
  UPDATE_QUOTE_REQUEST,
  UPDATE_QUOTE_SUCCESS,
  VALIDATE_CONTRACT_FAILURE,
  VALIDATE_CONTRACT_REQUEST,
  VALIDATE_CONTRACT_SUCCESS,
} from "actions/types";
import { getFastDocErrorStateFromResponse } from "helpers/data-helpers/getFastDocErrorStateFromResponse";
import { getFastDocRecommendationStateFromResponse } from "helpers/data-helpers/getFastDocRecommendationStateFromResponse";
import { mapApiErrorToReduxState } from "helpers/mappers";
import { loadingState, unloadingState } from "helpers/reducerHelpers";
import { AssetFinanceContracts } from "redux-models/reduxStateTypes";

const initialState: AssetFinanceContracts = {
  fastDocErrors: [],
  fastDocRecommendations: [],
  assetCategories: [],
  assets: [],
  quotes: [],
  afContractId: null,
  afApplicationId: null,
  favoriteQuoteId: null,
  success: null,
  error: null,
  recommendation: null,
  loading: false,
};

const assetFinanceContracts = (
  state = initialState,
  action: any
): AssetFinanceContracts => {
  switch (action.type) {
    case RESET_ASSET_FINANCE_STATE:
      return {
        ...initialState,
        loading: state.loading,
        loadingActions: state.loadingActions,
      };

    case GET_ASSET_CATEGORY_REQUEST:
      return loadingState(state, action.type);
    case GET_ASSET_CATEGORY_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        assetCategories: action.payload.items,
      };
    case GET_ASSET_CATEGORY_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case ADD_ASSET_REQUEST:
      return loadingState(state, action.type);
    case ADD_ASSET_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        assets: [...state.assets, action.payload],
        success: "asset",
        ...getFastDocErrorStateFromResponse(action),
        ...getFastDocRecommendationStateFromResponse(action),
      };
    case ADD_ASSET_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case SAVE_QUOTE_REQUEST:
      return loadingState(state, action.type);
    case SAVE_QUOTE_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        quotes: [...state.quotes, action.payload],
        success: "quote",
      };
    case SAVE_QUOTE_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case UPDATE_ASSET_REQUEST:
      return loadingState(state, action.type);
    case UPDATE_ASSET_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        assets: state.assets.map((asset) =>
          asset.id === action.payload.id ? action.payload : asset
        ),
        success: "asset",
        ...getFastDocErrorStateFromResponse(action),
        ...getFastDocRecommendationStateFromResponse(action),
      };
    case UPDATE_ASSET_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case CREATE_CONTRACT_REQUEST:
      return loadingState(state, action.type);
    case CREATE_CONTRACT_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        afContractId: action.payload.id,
      };
    case CREATE_CONTRACT_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case GET_CONTRACT_REQUEST:
      return loadingState(state, action.type);
    case GET_CONTRACT_SUCCESS: {
      const contract = action.payload.items[0];

      if (!contract) {
        return unloadingState(state, action.type);
      }

      return {
        ...unloadingState(state, action.type),
        afContractId: contract.id,
        quotes: contract.quotes,
        assets: contract.assets,
        favoriteQuoteId: contract.favoriteQuoteId,
        ...getFastDocErrorStateFromResponse(action),
        ...getFastDocRecommendationStateFromResponse(action),
      };
    }
    case GET_CONTRACT_FAILURE:
      return mapApiErrorToReduxState(action, state);
    case GET_AF_APPLICATION_SUCCESS: {
      const contract = action.payload.afContracts[0];

      if (!contract) {
        return state;
      }

      return {
        ...state,
        afContractId: contract.id,
        quotes: contract.quotes,
        assets: contract.assets,
        favoriteQuoteId: contract.favoriteQuoteId,
      };
    }

    case REMOVE_ASSET_REQUEST:
      return loadingState(state, action.type);
    case REMOVE_ASSET_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        assets: state.assets.filter((asset) => asset.id !== action.payload.id),
        ...getFastDocErrorStateFromResponse(action),
        ...getFastDocRecommendationStateFromResponse(action),
      };
    case REMOVE_ASSET_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case REMOVE_QUOTE_REQUEST:
      return loadingState(state, action.type);
    case REMOVE_QUOTE_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        quotes: state.quotes.filter((quote) => quote.id !== action.payload.id),
        ...getFastDocErrorStateFromResponse(action),
        ...getFastDocRecommendationStateFromResponse(action),
      };
    case REMOVE_QUOTE_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case UPDATE_FAVORITE_QUOTE_REQUEST:
      return loadingState(state, action.type);
    case UPDATE_FAVORITE_QUOTE_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        favoriteQuoteId: action.payload.id,
        ...getFastDocErrorStateFromResponse(action),
        ...getFastDocRecommendationStateFromResponse(action),
      };
    case UPDATE_FAVORITE_QUOTE_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case UPDATE_QUOTE_REQUEST:
      return loadingState(state, action.type);
    case UPDATE_QUOTE_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        quotes: state.quotes.map((quote) =>
          quote.id === action.payload.id ? action.payload : quote
        ),
        success: "quote",
        ...getFastDocErrorStateFromResponse(action),
        ...getFastDocRecommendationStateFromResponse(action),
      };
    case UPDATE_QUOTE_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case REMOVE_CONTRACT_REQUEST:
      return loadingState(state, action.type);
    case REMOVE_CONTRACT_SUCCESS:
      return unloadingState(state, action.type);
    case REMOVE_CONTRACT_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case VALIDATE_CONTRACT_REQUEST:
      return loadingState(state, action.type);
    case VALIDATE_CONTRACT_SUCCESS:
      return {
        ...unloadingState(state, action.type),
        error: initialState.error,
        ...getFastDocErrorStateFromResponse(action),
        ...getFastDocRecommendationStateFromResponse(action),
      };
    case VALIDATE_CONTRACT_FAILURE:
      return mapApiErrorToReduxState(action, state);

    case CLEAR_AF_CONTRACT_SUCCESS:
      return {
        ...state,
        success: null,
      };
    case RETURN_ASSET_FINANCE_CONTRACT_INITIAL_STATE:
      return initialState;
    case CLEAR_ASSET_FINANCE_ERROR:
      return {
        ...state,
        error: initialState.error,
      };
    default:
      return state;
  }
};

export default assetFinanceContracts;
