import React from "react";
import { BiChevronRight } from "react-icons/bi";
import styled from "styled-components/macro";
import { theme } from "theme";
import NavSubLink from "./NavSubLink.styled";
import NavSubLinkAnchor from "./NavSubLinkAnchor.styled";

const NavOptionDropdown = styled.div<{
  isActive?: boolean;
  id: string;
  onClick: () => void;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  padding: 1.5rem;
  color: ${(props) => props.theme.colors.primary};

  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.colorGrayf8};
    color: ${(props) => props.theme.colors.primary};
  }

  ${(props) =>
    props.isActive &&
    `
    font-weight: 600;
    color: ${props.theme.colors.primary};
  `}
`;

const ChevronRight = styled.div<{ isActive?: boolean }>`
  .chevron-right {
    width: 25px;
    height: 25px;
    transition: all 200ms cubic-bezier(0.25, 0.25, 0.47, 0.455);
    color: ${(props) => props.theme.colors.primary};

    ${(props) =>
      props.isActive &&
      `
      transform: rotate(90deg);
    `}
  }
`;

const RouterText = styled.div<{ isOpen?: boolean }>`
  visibility: hidden;
  height: 0;
  opacity: 0.5;
  overflow: auto;

  ${(props) =>
    props.isOpen &&
    `
    visibility: visible;
    height: auto;
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.02, 0.87, 0.6, 1.06); 
  `}
`;

const NavDropdown = styled.div`
  font-size: 16px;

  & > .link-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    margin: 25px 0;
    padding: 5px;

    a {
      text-decoration: none;
      opacity: 1;
    }
  }
`;

const NavOption = styled.span`
  display: block;
`;

type NavigationItemProps = {
  data: NavigationProps[];
  handleOpen: () => void;
  isPublic: boolean;
};

type NavigationProps = {
  id?: number;
  text?: string;
  items?: [];
  open?: boolean;
  link?: string;
};

type NavigationItemState = {
  navigationItems: NavigationProps[];
};

class NavigationItem extends React.Component<
  NavigationItemProps,
  NavigationItemState
> {
  constructor(props: NavigationItemProps) {
    super(props);
    this.state = {
      navigationItems: [],
    };

    this.onNavigate = this.onNavigate.bind(this);
    this.onRouteChange = this.onRouteChange.bind(this);
  }

  componentWillMount() {
    const items: NavigationProps[] = [];

    this.props.data.forEach((item: NavigationProps, index: number) => {
      items.push({
        id: index,
        text: item.text,
        items: item.items,
        open: false,
        link: item.link,
      });
    });

    this.setState({ navigationItems: items });
  }

  onNavigate(index: number, item: NavigationProps) {
    const navigationItem = this.state.navigationItems.slice();
    const id = index;

    navigationItem.forEach((item: NavigationProps) => {
      if (id === item.id) {
        item.open = !item.open;
      } else {
        item.open = false;
      }
    });

    this.setState({ navigationItems: navigationItem });
  }

  onRouteChange(e: any) {
    this.closeDraw();
  }

  activeDropdown(link: NavigationProps): boolean {
    return link.open === true;
  }

  closeDraw(): void {
    const activeDropdown:
      | NavigationProps
      | undefined = this.state.navigationItems.find(this.activeDropdown);

    if (activeDropdown) {
      activeDropdown.open = false;
    }

    this.props.handleOpen();
  }

  render() {
    const links = this.state.navigationItems.map(
      (item: NavigationProps, index: number, array: NavigationProps[]) => (
        <div key={this.state.navigationItems.indexOf(item)}>
          <div className="link-option">
            {item.items?.length === 0 ? (
              <NavSubLinkAnchor
                className="hide-text-overflow"
                href={item.link}
                target="_blank"
                rel="noreferrer"
                data-link={item.link}
                style={{
                  color: theme.colors.primary,
                  paddingLeft: "15px",
                  borderTop:
                    index === array.length - 1
                      ? `1px solid ${theme.colors.colorGraye3}`
                      : "none",
                }}
                onClick={this.onRouteChange}
              >
                {item.text}
              </NavSubLinkAnchor>
            ) : (
              <NavOptionDropdown
                isActive={item.open}
                id={"dropdown-option-" + index}
                onClick={() => this.onNavigate(index, item)}
              >
                <NavOption className="hide-text-overflow">
                  {item.text}
                </NavOption>
                {item.items!.length > 0 && (
                  <ChevronRight isActive={item.open}>
                    <BiChevronRight className="chevron-right" />
                  </ChevronRight>
                )}
              </NavOptionDropdown>
            )}
          </div>
          <RouterText isOpen={item.open}>
            {
              // get the navigation properties
              item.items?.map((item: any, index: number) => {
                if (this.props.isPublic) {
                  return (
                    <NavSubLinkAnchor
                      className="hide-text-overflow"
                      href={item.link}
                      data-link={item.link}
                      onClick={this.onRouteChange}
                    >
                      {item.name}
                    </NavSubLinkAnchor>
                  );
                } else if (item.inNewTab) {
                  return (
                    <NavSubLinkAnchor
                      className="hide-text-overflow"
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                      data-link={item.link}
                      onClick={this.onRouteChange}
                    >
                      {item.name}
                    </NavSubLinkAnchor>
                  );
                } else {
                  return (
                    <NavSubLink
                      className="hide-text-overflow"
                      key={index}
                      to={item.link}
                      data-link={item.link}
                      onClick={this.onRouteChange}
                    >
                      {item.name}
                    </NavSubLink>
                  );
                }
              })
            }
          </RouterText>
        </div>
      )
    );

    return (
      <div className="navigation-item">
        <NavDropdown className="sm-text">{links}</NavDropdown>
      </div>
    );
  }
}

export default NavigationItem;
