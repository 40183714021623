import { useEffect } from "react";
import { ReduxState } from "redux-models/reduxStateTypes";
import { clearUserData, getUser } from "actions/users";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DisplayUsernameType } from "./types";

export function useUsername(displayUsernameType: DisplayUsernameType) {
  const { user, userId, loading, name } = useSelector((state: ReduxState) => ({
    userId: state.auth.account.localAccountId,
    user: state.users.user,
    loading: state.personalisation.loading,
    name: state.auth.account.name,
  }));

  const dispatch = useDispatch();
  const params = useParams();
  const { firstName, lastName } = user;

  let result: string = name;
  // let result: string = `${firstName} ${lastName}`;

  if (result !== undefined && displayUsernameType === "initals") {
    let words = name.split(" ");
    let firstLetters = words[0].charAt(0) + words[1].charAt(0);
    result = firstLetters.toUpperCase();
  }

  useEffect(() => {
    if (params) {
      // getUser(params?.id || userId)(dispatch);
    }

    return () => {
      clearUserData()(dispatch);
    };
  }, [params.id, userId]);

  return !loading && result;
}
