import { getFastDocErrorStateFromResponse } from "helpers/data-helpers/getFastDocErrorStateFromResponse";
import { getFastDocRecommendationStateFromResponse } from "helpers/data-helpers/getFastDocRecommendationStateFromResponse";
import { unloadingState } from "helpers/reducerHelpers";
import { ApiErrorReduxState } from "redux-models/reduxStateTypes";
import {
  ErrorOrRecommendationActionWithData,
  ErrorOrRecommendationActionWithoutData,
  HttpErrorAction,
} from "utils/api";

export type ApiDataRedux = {
  hasError: boolean;
  overallError?: string;
  overallErrorAction?: string;
  fieldErrors: ApiFieldError[];

  hasRecommendation: boolean;
  overallRecommendation?: string;
  overallRecommendationAction?: string;
  fieldRecommendations: ApiFieldRecommendation[];
};

export type ApiFieldError = {
  field: string;
  message: string;
};

export type ApiFieldRecommendation = {
  field: string;
  message: string;
};

export type ApiFieldData = ApiFieldError | ApiFieldRecommendation;

export const mapApiErrorToReduxState = <T extends ApiErrorReduxState>(
  action: HttpErrorAction,
  state: T
): T => {
  // ignore error handling from cancelled requests
  if (action.isCancelledRequest) {
    return {
      ...state,
      loading: false,
    };
  }

  const payload = action.payload as Record<string, any>;
  const fieldDataKey = payload?.errors
    ? "errors"
    : payload?.recommendations
    ? "recommendations"
    : undefined;

  const error =
    action.statusCode !== undefined
      ? mapApiDataToState(
          action,
          action.payload?.errors ? "errors" : "recommendations"
        )
      : mapApiDataWithoutDataToState(action, fieldDataKey);

  return {
    ...unloadingState(state, action.type),
    error,
    ...getFastDocErrorStateFromResponse(action),
    ...getFastDocRecommendationStateFromResponse(action),
  };
};

const DEFAULT_MESSAGE = "An unknown error occurred";

export const mapApiDataToState = (
  action: ErrorOrRecommendationActionWithData,
  fieldDataKey: "errors" | "recommendations"
): ApiDataRedux => {
  const isErrors = fieldDataKey === "errors";

  return {
    hasError: isErrors,
    fieldErrors: isErrors ? action?.payload?.[fieldDataKey] ?? [] : [],
    ...getOverallError(action),

    hasRecommendation: !isErrors,
    fieldRecommendations: !isErrors
      ? action?.payload?.[fieldDataKey] ?? []
      : [],
    ...getOverallError(action),
  };
};

export const mapApiDataWithoutDataToState = (
  action: ErrorOrRecommendationActionWithoutData,
  fieldDataKey: "errors" | "recommendations" | undefined
): ApiDataRedux => {
  const isErrors = fieldDataKey === "errors";

  return {
    hasError: isErrors,
    fieldErrors: [],
    ...getOverallError(action),

    hasRecommendation: !isErrors,
    fieldRecommendations: [],
    ...getOverallError(action),
  };
};

const getOverallError = (
  action:
    | ErrorOrRecommendationActionWithoutData
    | ErrorOrRecommendationActionWithData
) => ({
  overallError: action.payload.message ?? DEFAULT_MESSAGE,
  overallErrorAction: action.type,
});
