import React from "react";
import styled from "styled-components/macro";
import logoLight from "assets/logos/logo-background-dark.svg";

const LogoContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 7.7rem;
  transform: translateX(-50%);
  width: 26.5rem;
  max-width: 100%;
  height: 8.1rem;
`;

const LogoImage = styled.img`
  width: 100%;
`;

function Logo() {
  return (
    <LogoContainer>
      <LogoImage src={logoLight} />
    </LogoContainer>
  );
}

export { Logo };
