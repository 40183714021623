import { createSlice } from "@reduxjs/toolkit";

export type ProductSelectorApplication = {
  financingAmount: number;
  businessNeed: string[];
  industry: string;
  tradingTime: string;
  howDoYouGetPaid: string[];
  ownedAssets: string[];
  monthlyRevenue: number | string;
};

const initialState: ProductSelectorApplication = {
  financingAmount: NaN,
  businessNeed: [],
  industry: "",
  tradingTime: "",
  monthlyRevenue: "",
  howDoYouGetPaid: [],
  ownedAssets: [],
};

const productSelectorApplicationSlice = createSlice({
  name: "productSelectorApplication",
  initialState,
  reducers: {
    setProductSelectorApplication(state, action) {
      return { ...state, ...action.payload };
    },
    resetProductSelectorApplication() {
      return initialState;
    },
  },
});

export const {
  setProductSelectorApplication,
  resetProductSelectorApplication,
} = productSelectorApplicationSlice.actions;

export default productSelectorApplicationSlice.reducer;
