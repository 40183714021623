import { ApiDispatch } from "actions/apiDispatch";
import {
  BF_CREATE_APPLICATION_FAILURE,
  BF_CREATE_APPLICATION_REQUEST,
  BF_CREATE_APPLICATION_SUCCESS,
  BF_CREATE_CUSTOMER_FAILURE,
  BF_CREATE_CUSTOMER_REQUEST,
  BF_CREATE_CUSTOMER_SUCCESS,
  BF_FINANCING_NEEDS_FAILURE,
  BF_FINANCING_NEEDS_REQUEST,
  BF_FINANCING_NEEDS_SUCCESS,
  BF_GET_APPLICATION_FAILURE,
  BF_GET_APPLICATION_REQUEST,
  BF_GET_APPLICATION_SUCCESS,
  BF_GET_BROKER_FAILURE,
  BF_GET_BROKER_REQUEST,
  BF_GET_BROKER_SUCCESS,
  BF_GET_CUSTOMER_DETAILS_FAILURE,
  BF_GET_CUSTOMER_DETAILS_REQUEST,
  BF_GET_CUSTOMER_DETAILS_SUCCESS,
  BF_GET_FILES_FAILURE,
  BF_GET_FILES_REQUEST,
  BF_GET_FILES_SUCCESS,
  BF_GET_RULES_SUMMARY_FAILURE,
  BF_GET_RULES_SUMMARY_REQUEST,
  BF_GET_RULES_SUMMARY_SUCCESS,
  BF_REMOVE_ITEM_AT_PATH,
  BF_RESET_STATE,
  BF_SELECT_BROKER,
  BF_SELECT_MANAGER,
  BF_SET_ITEM_AT_PATH,
  BF_SUBMIT_APPLICATION_FAILURE,
  BF_SUBMIT_APPLICATION_REQUEST,
  BF_SUBMIT_APPLICATION_SUCCESS,
  BF_UPDATE_BDM_FAILURE,
  BF_UPDATE_BDM_REQUEST,
  BF_UPDATE_BDM_SUCCESS,
  BF_UPDATE_CUSTOMER_ADDRESS_DETAILS,
  BF_UPDATE_CUSTOMER_FAILURE,
  BF_UPDATE_CUSTOMER_REQUEST,
  BF_UPDATE_CUSTOMER_SUCCESS,
  BF_UPDATE_OFFICE_HOLDERS_FAILURE,
  BF_UPDATE_OFFICE_HOLDERS_REQUEST,
  BF_UPDATE_OFFICE_HOLDERS_SUCCESS,
  BF_UPLOAD_FILE_FAILURE,
  BF_UPLOAD_FILE_REQUEST,
  BF_UPLOAD_FILE_SUCCESS,
  CALL_API,
} from "actions/types";
import { Dispatch } from "redux";
import {
  Manager,
  RemoveItemAtPathPayload,
  UpdateAddressAtPathPayload,
  UpdateItemAtPathPayload,
  User,
} from "redux-models/reduxStateTypes";

export const getBfSelectionRules = () => async (dispatch: ApiDispatch) => {
  await dispatch({
    [CALL_API]: {
      endpoint: `/v1/settings/bl/online/summary`,
      method: "GET",
      query: {},
      types: [
        BF_GET_RULES_SUMMARY_REQUEST,
        BF_GET_RULES_SUMMARY_SUCCESS,
        BF_GET_RULES_SUMMARY_FAILURE,
      ],
    },
  });
};

/**
 * @route /users/{userId}/afapplications/{applicationId}
 * @method GET
 * @desc gets a specific af application
 * @param {string} userId
 * @param {string} applicationId
 */
export const getBfApplication = (
  userId: string,
  applicationId: string
) => async (dispatch: ApiDispatch) => {
  await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/blapplications/${applicationId}`,
      method: "GET",
      types: [
        BF_GET_APPLICATION_REQUEST,
        BF_GET_APPLICATION_SUCCESS,
        BF_GET_APPLICATION_FAILURE,
      ],
    },
  });
};

/**
 *
 * @param {object} formData
 * @param {string} userId
 * @param {string} applicationId
 * @desc adds an quote from the global state
 */
export const uploadBfFile = (
  formData: object,
  userId: string,
  applicationId: string
) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/applications/${applicationId}/documents`,
      method: "POST",
      body: formData,
      types: [
        BF_UPLOAD_FILE_REQUEST,
        BF_UPLOAD_FILE_SUCCESS,
        BF_UPLOAD_FILE_FAILURE,
      ],
      cancellable: true,
    },
  });

  return { success: res?.type === BF_UPLOAD_FILE_SUCCESS };
};

/**
 * @route /users/{userId}/afapplications/{applicationId}
 * @method POST
 * @desc gets a specific af application
 * @param {string} userId
 * @param {string} applicationId
 */
export const submitBfApplication = ({
  userId,
  applicationId,
}: {
  userId: string;
  applicationId: string;
}) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/blapplications/${applicationId}/submit`,
      method: "PUT",
      types: [
        BF_SUBMIT_APPLICATION_REQUEST,
        BF_SUBMIT_APPLICATION_SUCCESS,
        BF_SUBMIT_APPLICATION_FAILURE,
      ],
    },
  });
  return { success: res?.type === BF_SUBMIT_APPLICATION_SUCCESS };
};

/**
 *
 * @param {string} userId
 * @param {string} applicationId
 * @desc gets documents for an application
 */
export const getBfApplicationFiles = (
  userId: string,
  applicationId: string
) => async (dispatch: ApiDispatch) => {
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/applications/${applicationId}/documents`,
      method: "GET",
      types: [BF_GET_FILES_REQUEST, BF_GET_FILES_SUCCESS, BF_GET_FILES_FAILURE],
      cancellable: true,
    },
  });
};

/**
 * @param {string} customerId
 * @param {string} userId
 */
export const getCustomerDetailsBf = ({
  userId,
  customerId,
}: {
  userId: string;
  customerId: string;
}) => {
  return async (dispatch: ApiDispatch) => {
    dispatch({
      [CALL_API]: {
        endpoint: `/v1/users/${userId}/customers/${customerId}`,
        method: "GET",
        types: [
          BF_GET_CUSTOMER_DETAILS_REQUEST,
          BF_GET_CUSTOMER_DETAILS_SUCCESS,
          BF_GET_CUSTOMER_DETAILS_FAILURE,
        ],
      },
    });
  };
};

/**
 * @param {string} customerId
 * @param {string} userId
 */
export const createCustomerBf = ({
  userId,
  formData,
}: {
  userId: string;
  formData: any;
}) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/customers`,
      method: "POST",
      body: formData,
      types: [
        BF_CREATE_CUSTOMER_REQUEST,
        BF_CREATE_CUSTOMER_SUCCESS,
        BF_CREATE_CUSTOMER_FAILURE,
      ],
    },
  });

  const success = res?.type === BF_CREATE_CUSTOMER_SUCCESS;
  return { success, customerId: res?.payload?.id };
};

export const updateCustomerBf = ({
  userId,
  formData,
  customerId,
}: {
  userId: string;
  formData: any;
  customerId: string;
}) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/customers/${customerId}`,
      method: "PUT",
      body: formData,
      types: [
        BF_UPDATE_CUSTOMER_REQUEST,
        BF_UPDATE_CUSTOMER_SUCCESS,
        BF_UPDATE_CUSTOMER_FAILURE,
      ],
    },
  });
  const success = res?.type === BF_UPDATE_CUSTOMER_SUCCESS;
  return { success, customerId };
};

/**
 * @route /v1/users/userId
 * @method GET
 * @param broker
 */
export const getBrokerBf = ({ brokerId }: { brokerId: string }) => async (
  dispatch: ApiDispatch
) => {
  await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${brokerId}`,
      method: "GET",
      types: [
        BF_GET_BROKER_REQUEST,
        BF_GET_BROKER_SUCCESS,
        BF_GET_BROKER_FAILURE,
      ],
    },
  });
};

export const selectBrokerBf = (broker: User | null) => async (
  dispatch: ApiDispatch
) =>
  dispatch({
    type: BF_SELECT_BROKER,
    payload: broker,
  });

export const selectManagerBf = (manager: Manager | null) => async (
  dispatch: ApiDispatch
) =>
  dispatch({
    type: BF_SELECT_MANAGER,
    payload: manager,
  });

export const updateOfficeHoldersBf = ({
  brokerId,
  customerId,
  applicationId,
  formData,
  managerId,
}: {
  brokerId: string;
  customerId: string;
  applicationId?: string;
  formData: any;
  managerId: string | null | undefined;
}) => async (dispatch: ApiDispatch) => {
  const updateRes = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${brokerId}/customers/${customerId}/officeholders`,
      method: "PUT",
      body: formData,
      types: [
        BF_UPDATE_OFFICE_HOLDERS_REQUEST,
        BF_UPDATE_OFFICE_HOLDERS_SUCCESS,
        BF_UPDATE_OFFICE_HOLDERS_FAILURE,
      ],
    },
  });

  if (updateRes?.type === BF_UPDATE_OFFICE_HOLDERS_SUCCESS) {
    if (!applicationId) {
      const createRes = await dispatch({
        [CALL_API]: {
          endpoint: `/v1/users/${brokerId}/blapplications`,
          method: "POST",
          body: {
            customerId: customerId,
            authorizationCheck: true,
            managerId: managerId,
          },
          types: [
            BF_CREATE_APPLICATION_REQUEST,
            BF_CREATE_APPLICATION_SUCCESS,
            BF_CREATE_APPLICATION_FAILURE,
          ],
        },
      });
      // Update BDM id here, new a new endpoint updateApplicationBDM
      const success = createRes?.type === BF_CREATE_APPLICATION_SUCCESS;
      applicationId = createRes?.payload?.id;

      return { success, applicationId };
    } else {
      const udpateRes = await dispatch({
        [CALL_API]: {
          endpoint: `/v1/users/${brokerId}/blapplications/${applicationId}/bdm`,
          method: "PUT",
          body: {
            managerId: managerId,
          },
          types: [
            BF_UPDATE_BDM_REQUEST,
            BF_UPDATE_BDM_SUCCESS,
            BF_UPDATE_BDM_FAILURE,
          ],
        },
      });
    }
  }

  return {
    success: updateRes?.type === BF_UPDATE_OFFICE_HOLDERS_SUCCESS,
    applicationId,
  };
};

/**
 *
 * @route /v1/users/userId/afapplications/applicationId/financing
 * @method PUT
 */
export const updateFinancingApplicationBf = ({
  brokerId,
  applicationId,
  financingNeed,
  financingAmount,
  averageMonthlySales,
  bankStatementBrokerFlowId,
}: {
  brokerId: string;
  applicationId: string;
  financingNeed: string;
  financingAmount: number;
  averageMonthlySales: number;
  bankStatementBrokerFlowId: string | undefined;
}) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${brokerId}/blapplications/${applicationId}/financing`,
      method: "PUT",
      body: {
        financingNeed,
        financingAmount,
        averageMonthlySales,
        bankStatementBrokerFlowId,
      },
      types: [
        BF_FINANCING_NEEDS_REQUEST,
        BF_FINANCING_NEEDS_SUCCESS,
        BF_FINANCING_NEEDS_FAILURE,
      ],
    },
  });

  return { success: res?.type === BF_FINANCING_NEEDS_SUCCESS };
};

export const updateItemAtPathBf = (
  pathAndValues: UpdateItemAtPathPayload
) => async (dispatch: Dispatch<any>) =>
  dispatch({
    type: BF_SET_ITEM_AT_PATH,
    payload: pathAndValues,
  });

export const updateAddressAtPathBf = (
  addressAndPath: UpdateAddressAtPathPayload
) => async (dispatch: Dispatch<any>) =>
  dispatch({
    type: BF_UPDATE_CUSTOMER_ADDRESS_DETAILS,
    payload: addressAndPath,
  });

export const removeItemAtPathBf = (payload: RemoveItemAtPathPayload) => async (
  dispatch: Dispatch<any>
) =>
  dispatch({
    type: BF_REMOVE_ITEM_AT_PATH,
    payload,
  });

export const resetBusinessFuelState = () => async (dispatch: ApiDispatch) =>
  dispatch({
    type: BF_RESET_STATE,
  });
