import { MsalProvider } from "@azure/msal-react";
import { AuthBranding as Branding } from "components/common/branding";
import { PushDown } from "components/common/container";
import Navigation from "components/layout/Navigation";
import { logPageView } from "config/firebase";
import { msalInstance } from "config/msal-instance";
import TokenSignIn from "pages/TokenSignIn";
import { Suspense, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import PublicViewRoute from "routing/PublicViewRoute";
import { ROUTES } from "utils/constants/routes-constants";
import lazy from "utils/lazyWithRetry";
import styled from "styled-components";
import { Overlay } from "components/common/container/Overlay";
import { useWindowSize } from "hooks/useWindowSize";
import { useOffcanvas } from "hooks/useOffcanvas";

const Container = styled.div`
  overflow: hidden;
`;

// routes
const PortalRoutes = lazy(() => import("routing/PortalRoutes"));
const ProductSelector = lazy(() => import("pages/ProductSelector"));
const WorkingCapitalCalculator = lazy(() =>
  import("pages/WorkingCapitalCalculator")
);

const Router = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { width, height } = useWindowSize();
  const { offcanvas, handleOpen } = useOffcanvas(width);

  useEffect(() => {
    logPageView(location.pathname);
  }, [navigate, location]);

  return (
    <MsalProvider instance={msalInstance}>
      <Suspense fallback={<Branding />}>
        <Routes>
          <Route path={ROUTES.SIGN_IN} element={<TokenSignIn />} exact />
          {/* <Route
            path={ROUTES.PRODUCT_SELECTOR}
            element={
              <Container>
                <Navigation
                  width={width}
                  active={offcanvas}
                  handleOpen={handleOpen}
                  isPublic
                />
                <Overlay active={offcanvas} />
                <PushDown active={offcanvas}>
                  <PublicViewRoute
                    path={ROUTES.PRODUCT_SELECTOR}
                    redirectRoute={ROUTES.PRODUCT_SELECTOR_AV}
                    component={ProductSelector}
                    exact
                  />
                </PushDown>
              </Container>
            }
            exact
          /> */}
          <Route
            path={ROUTES.WORKING_CAPITAL}
            element={
              <Container>
                <Navigation
                  width={width}
                  active={offcanvas}
                  handleOpen={handleOpen}
                  isPublic
                />
                <Overlay active={offcanvas} />
                <PushDown active={offcanvas}>
                  <PublicViewRoute
                    path={ROUTES.WORKING_CAPITAL}
                    redirectRoute={ROUTES.WORKING_CAPITAL_AV}
                    component={WorkingCapitalCalculator}
                    exact
                  />
                </PushDown>
              </Container>
            }
            exact
          />
          <Route path={ROUTES.ALL} element={<PortalRoutes />} />
        </Routes>
      </Suspense>
    </MsalProvider>
  );
};

export default Router;
