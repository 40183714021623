import { WithRouteProps, withRouter } from "components/common/utils/withRouter";
import PropTypes from "prop-types";
import React from "react";
import { MdClear } from "react-icons/md";
import Modal from "react-modal";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components/macro";

const customStyles = {
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "calc(100% - 4rem)",
    maxWidth: "96.1rem",
    minWidth: "320px",
    zIndex: "20",
    textAlign: "center",
    height: "calc(100% - 8rem)",
    minHeight: "50rem",
    maxHeight: "98rem",
    borderRadius: "12px",
  },

  overlay: {
    zIndex: "11",
    backgroundColor: "rgba(0, 0, 0, 0.61)",
  },
};

if (process.env.NODE_ENV !== "test") {
  Modal.setAppElement("#root");
}

const ExitButton = styled(Link)`
  position: absolute;
  top: 3.1rem;
  right: 3.1rem;
  border: none;
  outline: none;
  background: inherit;

  &:hover {
    cursor: pointer;
  }
`;

const LinkModal = withRouter<
  {
    children?: React.ReactNode;
    isOpen?: any;
    closeModal?: (e: any) => any;
    noScroll?: any;
  } & WithRouteProps
>(({ children, isOpen, closeModal, noScroll }) => {
  const location = useLocation();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      style={{
        ...customStyles,
        // @ts-expect-error
        content: {
          ...customStyles.content,
          overflow: noScroll ? "hidden" : "auto",
        },
      }}
      contentLabel=""
      ariaHideApp={process.env.NODE_ENV !== "test"}
    >
      <ExitButton to={location.pathname} onClick={closeModal}>
        <MdClear style={{ fontSize: "4rem", color: "#77889a" }} title="clear" />
      </ExitButton>
      {children}
    </Modal>
  );
});

LinkModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

LinkModal.defaultProps = {
  noScroll: false,
};

export { LinkModal };
