const APPLICANT_NAME_COLUMN = "Applicant";
const DATE_CREATED_COLUMN = "Date created";
const SEEKING_COLUMN = "Seeking";
const APPLICATION_TOTAL_COLUMN = "Financed / Limit";
const OWNER_COLUMN = "Owner";
const INTRODUCER_COLUMN = "Introducer";
const CATEGORY_COLUMN = "Category";
const STATUS_COLUMN = "Status";

const OVER100K = "over$100k";
const UNDER100K = "under$100k";

const allApplicationColumns = [
  APPLICANT_NAME_COLUMN,
  DATE_CREATED_COLUMN,
  SEEKING_COLUMN,
  APPLICATION_TOTAL_COLUMN,
  OWNER_COLUMN,
  INTRODUCER_COLUMN,
  CATEGORY_COLUMN,
  STATUS_COLUMN,
];

const applicationLoadingColumns = [
  APPLICANT_NAME_COLUMN,
  DATE_CREATED_COLUMN,
  SEEKING_COLUMN,
  APPLICATION_TOTAL_COLUMN,
  OWNER_COLUMN,
  CATEGORY_COLUMN,
  STATUS_COLUMN,
];

export {
  APPLICANT_NAME_COLUMN,
  DATE_CREATED_COLUMN,
  SEEKING_COLUMN,
  APPLICATION_TOTAL_COLUMN,
  OWNER_COLUMN,
  INTRODUCER_COLUMN,
  CATEGORY_COLUMN,
  STATUS_COLUMN,
  allApplicationColumns,
  applicationLoadingColumns,
  OVER100K,
  UNDER100K,
};
