import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { AuthBranding as Branding } from "components/common/branding";
import { msalInstance, requestConfig } from "config/msal-instance";
import { useAsyncEffect } from "hooks/useAsyncEffect";
import { useFunctionRef } from "hooks/useFuncRef";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "utils/constants/routes-constants";

// http://localhost:3000/signin?id_token_hint=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MzUyOTUyNDUsImVtYWlsIjoiam9leWFybmF1ZCtjaGVjazJAZ21haWwuY29tIiwiZmlyc3ROYW1lIjoiSm9zZXBoIiwibGFzdE5hbWUiOiJBcm5hdWQiLCJzaWduaW5PcHRpb24iOiJTaWduVXAiLCJpc0VtYWlsVmVyaWZpZWQiOiJUcnVlIiwicm9sZSI6ImJyb2tlciIsIm5iZiI6MTYzNTI5NTI0NSwiZXhwIjoxNjM1OTAwMDQ1LCJpc3MiOiJodHRwczovL3Njb3RwYWMtcGFydG5lcnMtZGV2LmF6dXJld2Vic2l0ZXMubmV0IiwiYXVkIjoic2NvdHBhY2Rldi5vbm1pY3Jvc29mdC5jb20ifQ.8jk0RLb-q0M_GUyChkMcSTsvTNdJiErhksbjjaQWwmg
/**
 *
 * @param location
 * @param msalContext
 * @returns {JSX.Element}
 * @constructor
 * @desc TokenSign in covers x flows
 * flow1 - user creates a company - user needs to get an updated token with the companyId x
 * flow2 - user is invited and needs to go to sign in with values prefilled x
 */
const TokenSignIn = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const id_token_hint = searchParams.get("id_token_hint");

  const { inProgress } = useMsal();

  const { login, error } = useMsalAuthentication(
    InteractionType.Silent,
    requestConfig
  );

  const doLogin = useFunctionRef(() =>
    login(InteractionType.Redirect, {
      ...requestConfig,
      extraQueryParameters: { id_token_hint },
      redirectStartPage: process.env.REACT_APP_REDIRECT_URI,
    })
  );

  useAsyncEffect(async () => {
    // the following code is for the refresh companyId flow
    // if theres an error go through regular login flow
    if (!id_token_hint || inProgress !== InteractionStatus.None) {
      return;
    }

    try {
      const res = await msalInstance.acquireTokenSilent({
        ...requestConfig,
        extraQueryParameters: { id_token_hint },
        forceRefresh: true,
      });

      // @ts-expect-error
      if (res.idTokenClaims.companyId) {
        navigate(ROUTES.HOME);
      } else {
        doLogin();
      }
    } catch {
      doLogin();
    }
  }, [id_token_hint, inProgress, error]);

  return <Branding />;
};

export default TokenSignIn;
