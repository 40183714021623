import {
  clearUserSuccess as clearUserSuccessAction,
  clearUserError,
} from "actions";
import { LoaderOverlay } from "components/common/loaders";
import Modal from "components/common/modal";
import { Formik } from "formik";
import { useFunctionRef } from "hooks/useFuncRef";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "redux-models/reduxStateTypes";
import styled from "styled-components/macro";
import InviteFormInputs from "./InviteFormInputs";
import InviteMany from "./InviteMany";
import InviteSuccess from "./InviteSuccess";

const Container = styled.div`
  padding: 3rem;
  width: 52rem;
  max-width: 100%;
  margin: 0 auto;

  @media (max-width: ${(props) => props.theme.breakpoints.smallScreen}) {
    width: 100%;
    padding: 3rem 0.5rem;
    min-width: 320px;
  }
`;

const init = {
  inviteFirstName: "",
  inviteLastName: "",
  inviteEmail: "",
  inviteRole: "",
  inviteCompany: "",
};

type InviteModalProps = {
  isOpen: boolean;
  close: () => any;
};

export const InviteModal = ({ isOpen, close }: InviteModalProps) => {
  return (
    <Formik
      initialValues={init}
      onSubmit={() => {
        // do nothing - submit handled elswhere
      }}
    >
      <InviteModelInner isOpen={isOpen} close={close} />
    </Formik>
  );
};

const InviteModelInner = ({ isOpen, close }: InviteModalProps) => {
  const dispatch = useDispatch();

  // Required for support with react test renderer
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  const { success, loading, userRole } = useSelector((state: ReduxState) => ({
    success: state.users.success,
    error: state.users.error,
    loading: state.users.loading,
    userRole: state.auth.account.idTokenClaims.role,
  }));

  const clearUserSuccess = useFunctionRef(() =>
    clearUserSuccessAction()(dispatch)
  );

  return (
    <>
      <Modal
        isOpen={modalOpen}
        closeModal={() => {
          clearUserError()(dispatch);
          close();
        }}
      >
        <Container>
          {success === "invite-broker" ? (
            <InviteSuccess clearUserSuccess={clearUserSuccess} close={close} />
          ) : (
            <>
              {success !== "bulk-invite" ? <InviteFormInputs /> : null}
              {userRole === "admin" ? (
                <InviteMany reset={clearUserSuccess} />
              ) : null}
            </>
          )}
        </Container>
      </Modal>
      {loading && isOpen && <LoaderOverlay />}
    </>
  );
};
