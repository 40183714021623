const COMPANY_NAME_COLUMN = "Company";
const LOCATION_COLUMN = "Location";
const CONTACT_COLUMN = "Contact";
const PRODUCTS_COLUMN = "Products";
const COMPANY_STATUS_COLUMN = "Status";

const allCompanyColumns = [
  COMPANY_NAME_COLUMN,
  LOCATION_COLUMN,
  CONTACT_COLUMN,
  PRODUCTS_COLUMN,
  COMPANY_STATUS_COLUMN,
];

const companyLoadingColumns = [
  COMPANY_NAME_COLUMN,
  LOCATION_COLUMN,
  PRODUCTS_COLUMN,
  COMPANY_STATUS_COLUMN,
];

export {
  COMPANY_NAME_COLUMN,
  LOCATION_COLUMN,
  CONTACT_COLUMN,
  PRODUCTS_COLUMN,
  COMPANY_STATUS_COLUMN,
  allCompanyColumns,
  companyLoadingColumns,
};
