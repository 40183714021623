import styled from "styled-components/macro";
import { keyframes } from "styled-components";
import { RiLoader4Fill } from "react-icons/ri";
import { MediumButton } from "components/common/form";

export const FormContainer = styled.div`
  background-color: ${(props) => props.theme.colors.colorWhite};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.colors.colorGraye3};
`;

export const ErrorContainer = styled.div`
  color: ${(props) => props.theme.colors.colorBlack};
  background-color: ${(props) => props.theme.colors.lightCoral};
  min-height: 60px;
  margin-top: 4px;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const ErrorLink = styled.a<{ bold?: any }>`
  width: 100%;
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.primary};
  margin-top: 4px;
  font-weight: ${(props) => (props?.bold ? "600" : "inherit")};
  padding: 0 5px 0 5px;
  cursor: pointer;
  text-decoration: underline;
`;

export const ErrorMessageContainer = styled.div``;

export const Error = styled.p<{ bold?: any }>`
  width: 100%;
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.colorBlack};
  margin-top: 4px;
  font-weight: ${(props) => (props?.bold ? "600" : "inherit")};
  padding: 5px 10px;
`;

export const FormFooterContainer = styled.div`
  padding: 20px 40px;

  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-end;
  background: ${(props) => props.theme.colors.colorWhite};

  button {
    margin-left: 10px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.mediumScreen}) {
    padding: 20px 80px;
  }
`;

export const FlexInfoGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  > * {
    div:first-child {
      margin-right: 1rem;
    }
    width: calc(50% - 2rem);
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledMediumButton = styled(MediumButton)`
  margin: 10px 20px 10px 0;
  width: 20rem;
`;

export const FormSettingsInputContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  @media (min-width: ${(props) => props.theme.breakpoints.mediumScreen}) {
    width: 50%;
  }
  padding: 40px 20px;
`;

export const UploadContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UploadTextContainer = styled.div`
  width: 93%;
  word-wrap: break-word;
`;

export const UploadImageContainer = styled.div`
  width: 7%;
  display: flex;
  justify-content: center;
  align-self: flex-start;
  margin-top: 10px;
`;

export const IconSuccessUpload = styled.img`
  width: 30px;
  height: 30px;
`;

export const UploadText = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  margin: 10px 0;
  font-size: 1.5rem;
  padding-right: 5px;
`;

export const UploadTextError = styled.p`
  color: ${({ theme }) => theme.colors.secondaryCoral};
  margin: 10px 0;
  font-size: 1.2rem;
  padding-right: 5px;
`;

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const LoadingIcon = styled(RiLoader4Fill)`
  top: calc(50% - 5rem);
  transform: translate(-50%, -50%);
  font-size: 2.6rem;
  color: ${(props) => props.theme.colors.primary};
  animation: ${rotate} 0.6s linear infinite;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const StyledList = styled.ul`
  list-style-type: none;
  padding: 10px 0;
`;

export const StyledListItem = styled.li`
  color: ${(props) => props.theme.colors.submitBlue};
  font-size: ${(props) => props.theme.fontSizes.subText};
`;

export const StyledErrorIcon = styled.img`
  width: 18px;
  z-index: 2;
  height: 18px;
  margin: 0 5px;
`;

export const DatePickerInfoGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > div {
    width: 100%;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.smallScreen}) {
    div:first-child {
      margin-right: 1rem;
    }
    & > div {
      width: calc(50% - 2rem);
    }
    flex-direction: row;

    flex-wrap: wrap;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.mediumScreen}) {
    div:first-child {
      margin-right: 0;
    }
    & > div {
      width: 100%;
    }

    flex-direction: row;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.mediumLargerScreen}) {
    div:first-child {
      margin-right: 1rem;
    }
    & > div {
      width: calc(50% - 2rem);
    }
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const QuoteFigureContainer = styled.div`
  margin: 20px 0;
`;

export const StyledUnorderedListGuarantor = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
`;

export const StyledListItemGuarantor = styled.li`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
  margin-right: 8px;
  color: ${(props) => props.theme.colors.submitBlue};
`;

export const CheckIconContainerPrimaryContact = styled.div`
  min-width: 20px;
  display: flex;
  align-items: center;
`;
