import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

export default styled(NavLink)`
  padding: 15px 15px 15px 40px;
  display: block;
  text-decoration: none;
  color: ${(props) => props.theme.colors.colorGrayDark};

  :hover {
    background-color: ${(props) => props.theme.colors.colorGrayf8};
    color: ${(props) => props.theme.colors.primary};
  }
`;
