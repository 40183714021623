import React from "react";

class ErrorBoundary extends React.Component<
  any,
  {
    hasError: boolean;
    error?: any;
    errorInfo?: any;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    this.setState({ error, errorInfo });
  }
}

export default ErrorBoundary;
