import {
  MM_GET_LEADS_DATA_REQUEST,
  MM_GET_LEADS_DATA_SUCCESS,
  MM_GET_LEADS_DATA_FAILURE,
  MM_GET_CAMPAIGNS_REQUEST,
  MM_GET_CAMPAIGNS_SUCCESS,
  MM_GET_CAMPAIGNS_FAILURE,
  MM_GET_EMAILS_REQUEST,
  MM_GET_EMAILS_SUCCESS,
  MM_GET_EMAILS_FAILURE,
  MM_GET_SOCIAL_SYNDICATION_REQUEST,
  MM_GET_SOCIAL_SYNDICATION_SUCCESS,
  MM_GET_SOCIAL_SYNDICATION_FAILURE,
  MM_GET_NEWSLETTERS_REQUEST,
  MM_GET_NEWSLETTERS_SUCCESS,
  MM_GET_NEWSLETTERS_FAILURE,
  MM_GET_OVERVIEW_REQUEST,
  MM_GET_OVERVIEW_SUCCESS,
  MM_GET_OVERVIEW_FAILURE,
} from "actions/types";

const initialState: any = {
  loading: [],
  error: {},
  leadsData: {},
  campaigns: [],
  socialChannels: [],
  emails: [],
  newsletters: [],
  resources: [],
};

const mindMatrix = (state = initialState, action: any) => {
  switch (action.type) {
    case MM_GET_LEADS_DATA_REQUEST:
      return { ...state, loading: [...state.loading, "leads"] };
    case MM_GET_LEADS_DATA_SUCCESS:
      return {
        ...state,
        loading: state.loading.filter((elem: any) => elem !== "leads"),
        leadsData: action.payload,
      };
    case MM_GET_LEADS_DATA_FAILURE:
      return {
        ...state,
        loading: state.loading.filter((elem: any) => elem !== "leads"),
        error: { ...action.payload, statusCode: action.statusCode },
      };
    case MM_GET_CAMPAIGNS_REQUEST:
      return {
        ...state,
        loading: [...state.loading, "campaigns"],
      };
    case MM_GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.payload.items,
        loading: state.loading.filter((elem: any) => elem !== "campaigns"),
      };
    case MM_GET_CAMPAIGNS_FAILURE:
      return {
        ...state,
        loading: state.loading.filter((elem: any) => elem !== "campaigns"),
      };
    case MM_GET_EMAILS_REQUEST:
      return {
        ...state,
        loading: [...state.loading, "emails"],
      };
    case MM_GET_EMAILS_SUCCESS:
      return {
        ...state,
        loading: state.loading.filter((elem: any) => elem !== "emails"),
        emails: action.payload.items,
      };
    case MM_GET_EMAILS_FAILURE:
      return {
        ...state,
        loading: state.loading.filter((elem: any) => elem !== "emails"),
        error: { ...action.payload, statusCode: action.statusCode },
      };
    case MM_GET_NEWSLETTERS_REQUEST:
      return {
        ...state,
        loading: [...state.loading, "newsletters"],
      };
    case MM_GET_NEWSLETTERS_SUCCESS:
      return {
        ...state,
        loading: state.loading.filter((elem: any) => elem !== "newsletters"),
        newsletters: action.payload.items,
      };
    case MM_GET_NEWSLETTERS_FAILURE:
      return {
        ...state,
        loading: state.loading.filter((elem: any) => elem !== "newsletters"),
        error: { ...action.payload, statusCode: action.statusCode },
      };
    case MM_GET_SOCIAL_SYNDICATION_REQUEST:
      return {
        ...state,
        loading: [...state.loading, "social"],
      };
    case MM_GET_SOCIAL_SYNDICATION_SUCCESS:
      return {
        ...state,
        loading: state.loading.filter((elem: any) => elem !== "social"),
        socialChannels: action.payload.items,
      };
    case MM_GET_SOCIAL_SYNDICATION_FAILURE:
      return {
        ...state,
        loading: state.loading.filter((elem: any) => elem !== "social"),
        error: { ...action.payload, statusCode: action.statusCode },
      };
    case MM_GET_OVERVIEW_REQUEST:
      return {
        ...state,
        loading: [...state.loading, "overview"],
      };
    case MM_GET_OVERVIEW_SUCCESS:
      return {
        ...state,
        loading: state.loading.filter((elem: any) => elem !== "overview"),
        resources: action.payload.items,
      };
    case MM_GET_OVERVIEW_FAILURE:
      return {
        ...state,
        loading: state.loading.filter((elem: any) => elem !== "overview"),
        error: { ...action.payload, statusCode: action.statusCode },
      };
    default:
      return state;
  }
};

export default mindMatrix;
