import React, { memo } from "react";
import styled from "styled-components/macro";
import Picker from "react-datepicker";
import useInputRef from "hooks/useInputRef";
import InputContainer from "./InputContainer";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";
import isEmpty from "helpers/isEmpty";
import {
  dateFormatsByUse,
  getDateDifferenceInYears,
  standardJSDateFromString,
} from "helpers/dateHelpers";

const StyledPicker = styled(Picker)`
  color: ${({ theme }) => theme.colors.submitBlue};
  background-color: ${({ theme }) => theme.colors.inputGray};
`;

const DatePicker = memo(
  ({
    value,
    name,
    change,
    error,
    text,
    disabled,
    required,
    minimumDate,
    maximumDate,
    handleBlur,
  }: {
    value: any;
    name: any;
    change: any;
    error: any;
    text: any;
    disabled?: any;
    required?: any;
    minimumDate?: any;
    maximumDate?: any;
    handleBlur?: any;
  }) => {
    const inputRef = useInputRef(error, undefined);
    const minDate =
      (minimumDate && standardJSDateFromString(minimumDate)) ||
      standardJSDateFromString("1900-01-01");
    const maxDate = standardJSDateFromString(maximumDate);
    const yearRange = getDateDifferenceInYears(minDate, maxDate);

    let selected;

    if (!isEmpty(value)) {
      selected = new Date(value);
    } else {
      selected = null;
    }

    return (
      <InputContainer
        error={error}
        text={text}
        name={name}
        inputRef={inputRef}
        required={required}
      >
        <StyledPicker
          onChange={(date: any) => {
            change(name, date !== null ? date.toISOString() : "");
          }}
          selected={selected}
          placeholderText={dateFormatsByUse.DATE_PICKER_DISPLAY}
          maxDate={maxDate}
          minDate={minDate}
          showYearDropdown
          yearDropdownItemNumber={yearRange} // defaults to 10 if this is left out.
          scrollableYearDropdown
          // @ts-expect-error
          calendarIcon="Calendar"
          onBlur={handleBlur}
          dateFormat={dateFormatsByUse.DATE_PICKER_FUNCTION}
          disabled={disabled}
          className={error && "error-border-datepicker"}
          id={name}
        />
      </InputContainer>
    );
  }
);

// @ts-expect-error
DatePicker.defaultProps = {
  bg: "inherit",
  disabled: false,
  required: false,
};

export { DatePicker };
