import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getUsers } from "actions";
import isEmpty from "lodash/isEmpty";
import { loadMoreOption } from "utils/constants/global-constant";
import { Select } from "components/common/inputs/index";
import { ReduxState } from "redux-models/reduxStateTypes";
import { BROKER_VALUE } from "utils/constants";

const mapStateToProps = (state: ReduxState) => ({
  userId: state.auth.account.localAccountId,
  users: state.users.items,
  totalPages: state.users.totalPages,
  loading: state.users.loading,
  role: state.auth.account.idTokenClaims.role,
  companyId: state.auth.account.idTokenClaims.companyId,
});

const UserSelectPagination = connect(mapStateToProps)(
  ({
    formikValue,
    loading,
    setFieldValue,
    handleBlur,
    users,
    totalPages,
    name,
    error,
    placeholder,
    role,
    companyId,
    disabled = false,
    roleFilter = [BROKER_VALUE],
  }: {
    formikValue?: any;
    loading?: any;
    setFieldValue?: any;
    handleBlur?: any;
    getUsers?: any;
    users?: any;
    totalPages?: any;
    name?: any;
    error?: any;
    placeholder?: any;
    role?: any;
    companyId?: any;
    disabled?: boolean;
    roleFilter?: string[];
  }) => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const lastPage = page === totalPages;

    const isCompanyRole = role === "company";

    useEffect(() => {
      getUsers({
        itemsPerPage: 10,
        page: page,
        isVerified: true,
        term: search,
        role: roleFilter,
        isFailed: false,
        isSuspended: false,
        cid: isCompanyRole ? companyId : "",
        includeCompany: true,
      })(dispatch);
    }, [search, companyId, isCompanyRole, page]);

    const getLabel = (option: {
      firstName: string;
      lastName: string;
      name?: string;
    }) => option.name ?? `${option?.firstName} ${option?.lastName}`;

    return (
      <Select
        text=""
        error={error}
        options={[
          ...users,
          ...(!lastPage && !isEmpty(users) ? loadMoreOption : []),
        ]}
        placeholder={placeholder}
        name={name}
        value={formikValue}
        change={setFieldValue}
        handleBlur={handleBlur}
        setSearch={setSearch}
        loading={loading}
        setPage={setPage}
        lastPage={lastPage}
        page={page}
        filterOption={false}
        labelField="name"
        valueField="id"
        isPageable
        isPreRefactor={false}
        getOptionLabel={getLabel}
        disabled={disabled}
      />
    );
  }
);

export { UserSelectPagination };
