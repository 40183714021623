import PropTypes from "prop-types";
import isEmpty from "helpers/isEmpty";
import { abnFormat } from "helpers/stringHelpers";
import {
  DropdownButton,
  DropdownError,
  DropdownItem,
  Dropdown,
  Name,
  Info,
  DotPoint,
  DropdownList,
} from "../../dropdowns/Dropdown.styles";

const OrgSearchDropdown = ({
  companies,
  focus,
  sugIndex,
  handleClick,
  error,
}: {
  companies: any;
  focus: any;
  sugIndex: any;
  handleClick: any;
  error: any;
}) => {
  const renderDropdown = () => {
    if (error) {
      return (
        <DropdownItem>
          <DropdownError>
            {error.statusCode} {error.message}
          </DropdownError>
        </DropdownItem>
      );
    } else if (!isEmpty(companies)) {
      return companies.map((result: any, index: any) => {
        return (
          <DropdownItem
            highlight={index === sugIndex}
            key={result.name + index}
          >
            <DropdownButton
              type="button"
              onMouseDown={() => handleClick(result)}
            >
              <Name>{result.name}</Name>
              <Info>
                {"ABN: " + abnFormat(result.abn)} <DotPoint />
                {result.address.state + ", " + result.address.postcode}
              </Info>
            </DropdownButton>
          </DropdownItem>
        );
      });
    }
  };

  return (
    <Dropdown data-testid="dropdown-container" focus={focus}>
      <DropdownList>{renderDropdown()}</DropdownList>
    </Dropdown>
  );
};

OrgSearchDropdown.propTypes = {
  companies: PropTypes.array,
  focus: PropTypes.bool.isRequired,
  sugIndex: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default OrgSearchDropdown;
