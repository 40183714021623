import { Dispatch } from "@reduxjs/toolkit";
import { ApiDispatch } from "actions/apiDispatch";
import isEmpty from "lodash/isEmpty";
import {
  ADD_ASSET_FAILURE,
  ADD_ASSET_REQUEST,
  ADD_ASSET_SUCCESS,
  CALL_API,
  CLEAR_AF_CONTRACT_SUCCESS,
  CREATE_CONTRACT_FAILURE,
  CREATE_CONTRACT_REQUEST,
  CREATE_CONTRACT_SUCCESS,
  GET_ASSET_CATEGORY_FAILURE,
  GET_ASSET_CATEGORY_REQUEST,
  GET_ASSET_CATEGORY_SUCCESS,
  GET_CONTRACT_FAILURE,
  GET_CONTRACT_REQUEST,
  GET_CONTRACT_SUCCESS,
  REMOVE_ASSET_FAILURE,
  REMOVE_ASSET_REQUEST,
  REMOVE_ASSET_SUCCESS,
  REMOVE_CONTRACT_FAILURE,
  REMOVE_CONTRACT_REQUEST,
  REMOVE_CONTRACT_SUCCESS,
  REMOVE_QUOTE_FAILURE,
  REMOVE_QUOTE_REQUEST,
  REMOVE_QUOTE_SUCCESS,
  UPDATE_ASSET_COST_QUOTE,
  UPDATE_ASSET_FAILURE,
  UPDATE_ASSET_REQUEST,
  UPDATE_ASSET_SUCCESS,
  UPDATE_FAVORITE_QUOTE_FAILURE,
  UPDATE_FAVORITE_QUOTE_REQUEST,
  UPDATE_FAVORITE_QUOTE_SUCCESS,
  VALIDATE_CONTRACT_FAILURE,
  VALIDATE_CONTRACT_REQUEST,
  VALIDATE_CONTRACT_SUCCESS,
} from "./types";
import { Asset } from "redux-models/reduxStateTypes";
import { actionResponse } from "helpers/data-helpers/afActionResponse";

export const getAssetCategory = (userId: string) => async (
  dispatch: ApiDispatch
) =>
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/assets/categories`,
      method: "GET",
      types: [
        GET_ASSET_CATEGORY_REQUEST,
        GET_ASSET_CATEGORY_SUCCESS,
        GET_ASSET_CATEGORY_FAILURE,
      ],
    },
  });

export const validateContract = (
  userId: string,
  applicationId: string
) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/afapplications/${applicationId}/valid`,
      method: "GET",
      types: [
        VALIDATE_CONTRACT_REQUEST,
        VALIDATE_CONTRACT_SUCCESS,
        VALIDATE_CONTRACT_FAILURE,
      ],
    },
  });
  return actionResponse(VALIDATE_CONTRACT_SUCCESS, res);
};

/**
 *
 * @param {object} asset
 * @param {string} userId
 * @param {string} applicationId
 * @param {boolean} isFullDoc
 * @desc adds an asset from the global state
 */
export const addAsset = ({
  asset,
  userId,
  applicationId,
  isFullDoc,
}: {
  asset: Asset;
  userId: string;
  applicationId: string;
  isFullDoc: boolean;
}) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/afapplications/${applicationId}/assets`,
      method: "POST",
      body: { ...asset, isFullDoc },
      types: [ADD_ASSET_REQUEST, ADD_ASSET_SUCCESS, ADD_ASSET_FAILURE],
    },
  });
  return actionResponse(ADD_ASSET_SUCCESS, res);
};

/**
 **
 *
 * @param {object} asset
 * @param {string} userId
 * @param {string} applicationId
 * @param {boolean} isFullDoc
 * @desc update asset from global state
 */
export const updateAsset = ({
  asset,
  userId,
  applicationId,
  isFullDoc,
}: {
  asset: any;
  userId: string;
  applicationId: string;
  isFullDoc: boolean;
}) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/assets/${asset.id}`,
      method: "PUT",
      body: { ...asset },
      types: [UPDATE_ASSET_REQUEST, UPDATE_ASSET_SUCCESS, UPDATE_ASSET_FAILURE],
    },
  });
  return actionResponse(UPDATE_ASSET_SUCCESS, res);
};

/**
 *
 * @param {string} assetCost
 * @desc removes a quote from the global state
 */
export const updateAssetCostQuote = (assetCost: any) => async (
  dispatch: Dispatch
) => {
  dispatch({ type: UPDATE_ASSET_COST_QUOTE, payload: assetCost });
};

/**
 *
 * @desc create a contract
 * @param {string} applicationId
 * @param {string} userId
 */
export const getOrCreateContract = (
  applicationId: string,
  userId: string
) => async (dispatch: ApiDispatch) => {
  let res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/afapplications/${applicationId}/contracts`,
      method: "GET",
      types: [GET_CONTRACT_REQUEST, GET_CONTRACT_SUCCESS, GET_CONTRACT_FAILURE],
    },
  });
  let successType = GET_CONTRACT_SUCCESS;
  if (isEmpty(res?.payload?.items) && res?.payload) {
    res = await dispatch({
      [CALL_API]: {
        endpoint: `/v1/users/${userId}/afapplications/${applicationId}/contracts`,
        method: "POST",
        types: [
          CREATE_CONTRACT_REQUEST,
          CREATE_CONTRACT_SUCCESS,
          CREATE_CONTRACT_FAILURE,
        ],
      },
    });
    successType = CREATE_CONTRACT_SUCCESS;
  }
  return actionResponse(successType, res);
};

/**
 * @deprecated
 * FIXME: remove
 * not possible anymore
 */
export const createExtraContract = (applicationId: any, userId: any) => async (
  dispatch: ApiDispatch
) => {
  dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/afapplications/${applicationId}/contracts`,
      method: "POST",
      types: [
        CREATE_CONTRACT_REQUEST,
        CREATE_CONTRACT_SUCCESS,
        CREATE_CONTRACT_FAILURE,
      ],
    },
  });
};

/**
 *
 * @param {string} userId
 * @param {string} assetId
 * @desc adds an quote from the global state
 */
export const removeAsset = ({
  userId,
  assetId,
}: {
  userId: string;
  assetId: string;
}) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/assets/${assetId}`,
      method: "DELETE",
      types: [REMOVE_ASSET_REQUEST, REMOVE_ASSET_SUCCESS, REMOVE_ASSET_FAILURE],
    },
  });
  return actionResponse(REMOVE_ASSET_SUCCESS, res);
};

/**
 *
 * @param {string} userId
 * @param {string} assetId
 * @desc adds an quote from the global state
 */
export const removeQuote = ({
  userId,
  quoteId,
}: {
  userId: string;
  quoteId: number;
}) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/quotes/af/${quoteId}`,
      method: "DELETE",
      types: [REMOVE_QUOTE_REQUEST, REMOVE_QUOTE_SUCCESS, REMOVE_QUOTE_FAILURE],
    },
  });
  return actionResponse(REMOVE_QUOTE_SUCCESS, res);
};

/**
 *
 * @param {string} userId
 * @param {string} quoteId
 * @param {string} contractId
 * @desc adds an quote from the global state
 */
export const updateFavoriteQuote = ({
  userId,
  quoteId,
  contractId,
}: {
  userId: string;
  quoteId: number;
  contractId: string;
}) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/quotes/af/${quoteId}/favorite`,
      method: "POST",
      body: { contractId },
      types: [
        UPDATE_FAVORITE_QUOTE_REQUEST,
        UPDATE_FAVORITE_QUOTE_SUCCESS,
        UPDATE_FAVORITE_QUOTE_FAILURE,
      ],
    },
  });
  return actionResponse(UPDATE_FAVORITE_QUOTE_SUCCESS, res);
};

/**
 * @deprecated
 * FIXME: remove
 * not possible anymore
 */
export const removeContract = ({
  userId,
  applicationId,
  contractId,
  lastContract,
}: {
  userId: string;
  applicationId: string;
  contractId: string;
  lastContract: boolean;
}) => async (dispatch: ApiDispatch) => {
  const res = await dispatch({
    [CALL_API]: {
      endpoint: `/v1/users/${userId}/afapplications/${applicationId}/contracts/${contractId}`,
      method: "DELETE",
      types: [
        REMOVE_CONTRACT_REQUEST,
        REMOVE_CONTRACT_SUCCESS,
        REMOVE_CONTRACT_FAILURE,
      ],
    },
  });
  if (res?.type === REMOVE_CONTRACT_SUCCESS && lastContract) {
    await dispatch({
      [CALL_API]: {
        endpoint: `/v1/users/${userId}/afapplications/${applicationId}/contracts`,
        method: "POST",
        types: [
          CREATE_CONTRACT_REQUEST,
          CREATE_CONTRACT_SUCCESS,
          CREATE_CONTRACT_FAILURE,
        ],
      },
    });
  }
};

export const clearAssetFinanceSuccess = () => async (dispatch: ApiDispatch) =>
  dispatch({
    type: CLEAR_AF_CONTRACT_SUCCESS,
  });
