export const clean = (object: Record<string, any>) => {
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === "object") {
      clean(v);
    }
    if (
      (v && typeof v === "object" && !Object.keys(v).length) ||
      (v && Array.isArray(v) && v.length === 0) ||
      v === null ||
      v === undefined ||
      v === ""
    ) {
      if (Array.isArray(object)) {
        // @ts-expect-error
        object.splice(k, 1);
      } else {
        if (!(object[k] instanceof Date) || isNaN(object[k])) {
          delete object[k];
        }
        // delete object[k];
      }
    }
  });
  return object;
};
