import { ErrorDescription } from "components/common/error";
import { Label } from "components/common/inputs/styled";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import FileUpload from "./FileUpload";
import { IUploadedFile, UploadedFile } from "./UploadedFile";

export interface IFileInputProps {
  files?: IUploadedFile[];
  label?: string;
  setFile?: React.ChangeEventHandler<HTMLInputElement>;
  accept?: string;
  name?: string;
  btnText?: React.ReactNode;
  error?: any;
  value?: string;
}

const FileInput = ({
  files,
  setFile,
  label,
  accept,
  name,
  btnText,
  error,
}: IFileInputProps) => {
  return (
    <div>
      {!isEmpty(label) && <Label>{label}</Label>}
      {!isEmpty(files) && files?.map((file) => <UploadedFile file={file} />)}
      <FileUpload
        name={name}
        accept={accept}
        change={setFile}
        btnText={btnText}
      />
      {error && <ErrorDescription {...error} />}
    </div>
  );
};

FileInput.propTypes = {
  files: PropTypes.array,
  setFile: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  accept: PropTypes.string,
  name: PropTypes.string.isRequired,
  btnText: PropTypes.string,
};

export { FileInput };
