import { DisplayUsernameType } from "hooks/types";
import { useUsername } from "hooks/useUsername";
import styled from "styled-components/macro";
import { dotsHorizontal } from "assets/icons/custom-icons";

export const Effect = styled.img`
  @keyframes fadeIn {
    from {
      opacity: 0.7;
    }
  }

  opacity: 0.5;
  animation: fadeIn 1s infinite alternate;
`;

const Username = ({
  displayUsernameType,
}: {
  displayUsernameType: DisplayUsernameType;
}) => {
  const username = useUsername(displayUsernameType);
  if (username === false) {
    return <Effect src={dotsHorizontal} />;
  } else {
    return <span>{username}</span>;
  }
};

export default Username;
