import React from "react";
import styled from "styled-components/macro";
import { Logo } from "./Logo";

const Branding = styled.section`
  background-color: ${(props) => props.theme.colors.primary};
  border-bottom: 12px solid #ffdf40;
  height: 29.3rem;
  min-width: ${(props) => props.theme.breakpoints.minimumWidth};
`;

function AuthBranding() {
  return (
    <Branding>
      <Logo />
    </Branding>
  );
}

export { AuthBranding };
