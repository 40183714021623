import styled, { css } from "styled-components/macro";

const Button = styled.button<{ comingSoon?: any }>`
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  height: 100%;
  background-color: inherit;

  &:hover {
    cursor: ${(props) => (props.comingSoon ? "auto" : "pointer")};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.largeScreen}) {
    width: 100%;
    justify-content: space-between;
    height: 6rem;

    &:not(:last-child) {
      border-bottom: 1px solid #e5e6ea;
    }
  }
`;

const Main = styled.span<{ comingSoon?: any; isOpen?: any }>`
  & a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.primary};
  }

  border: none;
  background-color: inherit;
  color: ${(props) => {
    if (props.comingSoon) {
      return props.theme.colors.colorLightGrayBlue;
    } else if (props.isOpen) {
      return props.theme.colors.primary;
    } else {
      return props.theme.colors.colorGrayDark;
    }
  }};
  font-size: ${(props) => props.theme.fontSizes.nav};
  // font-weight: 500;
  text-decoration: none;
  font-family: ${(props) => props.theme.fontStyles.subHeading};

  &:hover {
    ${(props) =>
      props.comingSoon
        ? css`
            color: ${(props) => props.theme.colors.colorLightGrayBlue};
          `
        : css<{ isOpen?: any }>`
            color: ${(props) =>
              props.isOpen
                ? props.theme.colors.primary
                : props.theme.colors.lightPurple};
          `}
  }
`;

export { Main, Button };
