import styled from "styled-components/macro";
import NavProfile from "../NavProfile";
import Media from "./Media";
import Username from "./Username";

type HeaderProps = {
  role: string;
  isDesktop: boolean;
};

const Container = styled.div<{ isDesktop: boolean }>`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  height: 156px;
  padding: 0 15px 0 15px;
  background-color: ${(props) => props.theme.colors.inputGray};
  color: ${(props) => props.theme.colors.primary};

  @media ${Media.large} {
    height: 115px;
  }

  ${(props) =>
    props.isDesktop &&
    `
    padding: 15px;
    height: 62px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    @media ${Media.large} {
      border-radius: 0;
    }
  `}
`;

const AvatarDetails = styled.div`
  line-height: 1.4;
  font-size: 16px;
  overflow: hidden;
  padding-left: 10px;

  & div {
    text-transform: capitalize;
  }

  .avatar-name {
    font-weight: 600;
    .first-name {
      padding-right: 5px;
    }
  }
`;

const Header = ({ role, isDesktop }: HeaderProps) => {
  return (
    <Container isDesktop={isDesktop} className="offcanvas-header">
      <div className="nav-avatar">
        <NavProfile />
      </div>
      <AvatarDetails className="sm-text">
        <div className="hide-text-overflow avatar-name">
          <Username displayUsernameType="name" />
        </div>
        <div className="hide-text-overflow avatar-role">{role}</div>
      </AvatarDetails>
    </Container>
  );
};

export default Header;
