import React from "react";
import styled, { keyframes } from "styled-components";
import { RiLoader4Fill } from "react-icons/ri";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoadingIcon = styled(RiLoader4Fill)`
  position: absolute;
  top: calc(50% - 5rem);
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  color: ${(props) => props.theme.colors.primary};
  animation: ${rotate} 0.6s linear infinite;
`;

function Loader() {
  return <LoadingIcon title="loading..." />;
}

export { Loader };
