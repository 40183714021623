import isEqual from "lodash/isEqual";

/**
 *
 * @param elem The new element
 * @param comparingElem The "old" element
 * @param ifEqualVal
 * @returns {undefined|*}
 * @desc has changes checks if the elem and comparing elem are the same and returns the ifEqual val if they are not the same
 */
export const hasChanged = (
  elem: any,
  comparingElem: any,
  ifEqualVal: any = undefined
) => (isEqual(elem, comparingElem) ? ifEqualVal : elem);
