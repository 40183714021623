import axios from "axios";
import { msalInstance, requestConfig } from "config/msal-instance";
import {
  BrowserAuthError,
  InteractionRequiredAuthError,
  InteractionType,
} from "@azure/msal-browser";

// to get the access token from session storage
const setAuthToken = () => {
  const reqInterceptor = axios.interceptors.request.use(async (config) => {
    if (process.env.NODE_ENV === "test") {
      return config;
    } else {
      const isIframe = window !== window.parent && !window.opener;
      if (!isIframe) {
        // @ts-expect-error
        if (!msalInstance.inProgress === InteractionType.None) {
          console.log(
            "msalInstance.inProgress is not None:",
            // @ts-expect-error
            msalInstance.inProgress
          );
        }
        const res = await msalInstance
          .acquireTokenSilent({
            ...requestConfig,
            // @ts-expect-error
            account: msalInstance.getActiveAccount(),
          })
          .catch((error) => {
            console.log("error", error);
            if (
              error.name === "InteractionRequiredAuthError" ||
              error instanceof InteractionRequiredAuthError
            ) {
              return msalInstance.acquireTokenRedirect({
                ...requestConfig,
                loginHint: msalInstance.getActiveAccount()?.username,
              });
            } else {
              // @ts-expect-error
              if (typeof error === BrowserAuthError) {
                console.log("BrowserAuthError in interceptor:", error.message);
              }

              if (msalInstance.getActiveAccount()) {
                try {
                  const result = async () =>
                    // @ts-expect-error
                    await msalInstance.logoutRedirect({
                      ...requestConfig,
                    });
                  console.log(
                    "result from msalInstance.logoutRedirect",
                    result
                  );
                } catch (error) {}
              } else {
                window.location.reload();
              }
            }
          });
        // @ts-expect-error
        config.headers.Authorization = `Bearer ${res.idToken}`;
      }
    }
    return config;
  });

  return { reqInterceptor };
};

export default setAuthToken;
