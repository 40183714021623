import styled from "styled-components/macro";
import { BsStopwatch } from "react-icons/bs";

const Container = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 12px;
  background-color: ${(props) => props.theme.colors.colorGrayDark};
  opacity: 0.9;
  color: ${(props) => props.theme.colors.colorWhite};
  width: 100%;
  height: 100%;
  z-index: ${(props) => props.theme.zIndex.important};
`;

const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
`;

const Icon = styled(BsStopwatch)`
  font-size: 8rem;
  margin-bottom: 2.5rem;

  @media (max-width: ${(props) => props.theme.breakpoints.largeScreen}) {
    font-size: 4rem;
  }
`;

const InfoContainer = styled.div``;

const Title = styled.p`
  font-size: 2.4rem;
  font-family: ${(props) => props.theme.fontStyles.headings};

  @media (max-width: ${(props) => props.theme.breakpoints.largeScreen}) {
    font-size: 1.8rem;
  }
`;

const Text = styled.p`
  font-size: 1.2rem;
  font-family: ${(props) => props.theme.fontStyles.headings};

  @media (max-width: ${(props) => props.theme.breakpoints.largeScreen}) {
    font-size: 1rem;
  }
`;

const Divider = styled.span`
  display: block;
  width: 1.6rem;
  height: 0.1rem;
  background-color: ${(props) => props.theme.colors.colorWhite};
  margin: 2.5rem auto 1.1rem;
`;

const Name = styled.p``;

export { Name, Divider, Text, Center, Title, InfoContainer, Icon, Container };
